import React, { useContext, useEffect, useState } from "react";
import type { Subscription } from "rxjs";
import type { HomeownerApplicationOutEventTypes } from "../../blocs/projectAttachmentBloc";
import BlocRegistry from "../../blocs/registry";
import moment from "moment";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import Pagination from "@mui/material/Pagination";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import styles from "./ManageNotesView.module.css";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddUpdateNote from "./AddUpdateNote";
import ViewNotesDetail from "./ViewNotesDetail";
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  NotesEventTypesEnum as NotesEvent, FetchNotesEvent, DeleteNoteEvent
} from "../../blocs/notesBloc";

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Title',
    width: 220
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    width: 480
  },
  {
    id: 'owner',
    numeric: false,
    disablePadding: false,
    label: 'Created by',
    width: 170
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    width: 200
  },
  {
    id: 'attachment',
    numeric: true,
    disablePadding: false,
    label: 'Attachment',
    width: 120
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all notes',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
          // align={headCell.numeric ? 'right' : 'left'}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell width={150}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function CustomPaginationActionsTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('description');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchedQuery, setSearched] = React.useState("");
  const [rowsAll, setRows] = React.useState([]);
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [open, setOpen] = React.useState(false);
  const [openViewDetails, setOpenViewDetails] = React.useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
  const [lastBlocEvent, setLastBlocEvent] = useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isView, setIsView] = React.useState(false);
  const [currentNote, setCurrentNote] = React.useState({});
  const [pager, setPager] = React.useState(1);
  const [getLatestNotes, seGetLatestNotes] = React.useState(false);

  const { notesBloc } = useContext(BlocRegistry);

  const handleDeleteClick = (row) => {
    setOpenDeleteConfirm(true);
    setCurrentNote(row);
  };

  const handleViewDetailsClick = (row) => {
    setCurrentNote(row);
    setIsView(true);
    setOpenViewDetails(true);
  };

  const handleDeleteConfirm = () => {
    notesBloc.sendEvent(new DeleteNoteEvent(currentNote?.id));
    setOpenDeleteConfirm(false);
    setCurrentNote({});
  };

  const handleDeleteClose = () => {
    setOpenDeleteConfirm(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (!lastBlocEvent) return;
    switch (lastBlocEvent.type) {
      case NotesEvent.FETCHED_ALL_NOTES: {
        setRows(lastBlocEvent?.notes?.data);
        setPager(Math.ceil(lastBlocEvent?.notes?.meta.totalCount / lastBlocEvent?.notes?.meta.limit))
        seGetLatestNotes(false);
        break;
      }
      case NotesEvent.CREATE_UPDATE_NOTE_SUCCESS: {
        setOpen(false);
        setPage(1);
        setRowsPerPage(10);
        setSearched("");
        seGetLatestNotes(true);
        break;
      }
      default: {
        //console.error("Unknown homeowner application out event type: " + lastBlocEvent?.type)
      }
    }
  }, [lastBlocEvent]);

  useEffect(() => {
    const subscription: Subscription = notesBloc.subscribeToNoteContexts({
      next(event: HomeownerApplicationOutEventTypes) {
        setLastBlocEvent(event);
      },
      error(err: Error) {
        throw err;
      }
    });
    return () => {
      subscription.unsubscribe();
    }
  }, [notesBloc]);

  useEffect(() => {
    (async () => {
      let params = {
        projectId: props.project?.id,
        offSet: ((page - 1) * rowsPerPage),
        rowsPerPage: rowsPerPage,
        q: searchedQuery
      }
      if (!open) {
        notesBloc.sendEvent(new FetchNotesEvent(params));
      }
    })();
  }, [page, searchedQuery, rowsPerPage, open, getLatestNotes]);

  const requestSearch = (searchedVal) => {
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    setSearched(searchedVal);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  const handleOpen = () => {
    setIsEdit(false);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setCurrentNote({});
  };

  const handleCloseViewModal = () => {
    setOpenViewDetails(false);
    setCurrentNote({});
  };

  const editNote = (row) => {
    setCurrentNote(row);
    setIsEdit(true);
    setOpen(true);
  };

  return (
    <Box sx={{ width: '95%' }}>
      <div className={styles.headerContainer}>
        <h4 className={styles.headertitle}>Notes</h4>
        <TextField label="Search" id="outlined-basic" variant="outlined"
          size="small"
          value={searchedQuery}
          className={styles.searchInput}
          onChange={(searchVal) => requestSearch(searchVal?.target?.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon, cursor: 'pointer' }}
                onClick={cancelSearch}
              >
                <ClearIcon />
              </InputAdornment>
            )
          }}
        />
        <Button
          className={styles.addNoteBtn}
          onClick={handleOpen}
        > <span className={styles.addNoteBtnTxt}> + Add Note </span></Button>
      </div>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'small'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rowsAll.length}
          />
          <TableBody>
            {rowsAll?.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding="checkbox">
                  </TableCell>
                  <TableCell
                    width={220}
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <a className={styles.noteTitle} onClick={(event) => handleViewDetailsClick(row)} >{row.title}</a>
                  </TableCell>
                  <TableCell align="left" width={480}>{row?.description}</TableCell>
                  <TableCell align="left" width={170}>{row?.owner}</TableCell>
                  <TableCell align="left" width={200}>{moment(row?.modifiedAt ? row?.modifiedAt : row?.createdA).format('MM/DD/YYYY, h:mm a')}</TableCell>
                  <TableCell align="center" width={150}>{row?.notesAttachments?.length}</TableCell>
                  <TableCell>
                    &nbsp;
                    {row?.isEditable &&
                      <>
                        <VisibilityIcon color="primary" fontSize="small" onClick={(event) => handleViewDetailsClick(row)} />&nbsp;&nbsp;&nbsp;
                        <DeleteIcon color="primary" fontSize="small" onClick={(event) => handleDeleteClick(row)} />&nbsp;&nbsp;&nbsp;
                        <EditIcon onClick={(event) => editNote(row)} color="primary" fontSize="small" />
                      </>
                    }

                    {/* <Button
                      //variant="contained"
                      // onClick={() => {
                      //   rowsAll.splice(index, 1);
                      //   setRows([...rowsAll]);
                      // }}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button> */}
                  </TableCell>
                </TableRow>
              );
            })}
            {/* {rowsAll?.length === 0 && (
              <TableRow
                style={{
                  height: (53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
            {open ? <AddUpdateNote isEdit={isEdit} currentNote={currentNote} handleClose={handleCloseModal} open={open} projectId={props.project?.id} /> : null}
            {openViewDetails ? <ViewNotesDetail src={currentNote?.notesAttachments[0]?.getUrl} isView={isView} currentNote={currentNote} handleClose={handleCloseViewModal} open={openViewDetails} projectId={props.project?.id} /> : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.pageFooter}>
        <div className={styles.perPageContainer}>
          <span>Rows per page:</span>&nbsp;&nbsp;
          <Select
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            value={rowsPerPage}
            onChange={(event) => handleChangeRowsPerPage(event)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size="small"
            className={styles.perPageSelect}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={30}>20</MenuItem>
          </Select>
        </div>
        <Pagination
          className={styles.pagerContainer}
          count={pager}
          color="primary"
          onChange={(event, value) => handleChangePage(value)}
          page={page}
        />
      </div>

      <Dialog
        open={openDeleteConfirm}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete this note permenantly ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
} 
