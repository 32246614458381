// @flow


import type {ProjectTemplate} from "../ProjectTemplate/ProjectTemplate";

export const ProjectGroupStatusTypeEnum = Object.freeze({
    OPEN:'OPEN',
    CLOSED:'CLOSED',
    REJECTED:'REJECTED'
})

export default class ProjectGroup{
    id: string;
    tenantId: string;
    name: string;
    inProcess: String;
    completed: String;
    total: String;
    budget: String;
    actual: String;
    status:string
    projectTemplate: ProjectTemplate;

    constructor(id: string, tenantId: string, name: string,  inProcess: String,
        completed: String,
        total: String,
        budget: String,
        actual: String,status:string, projectTemplate: ProjectTemplate) {
        this.id = id;
        this.tenantId = tenantId;
        this.name = name;
        this.inProcess = inProcess;
        this.completed = completed;
        this.total = total;
        this.budget = budget;
        this.actual = actual;
        this.status = status;
        this.projectTemplate = projectTemplate;
    }
}

export class CreateProjectGroupRequest {
    name: string;
    status:string;
    taskIdList:Array<Object>;


    constructor(name: string,status:string, taskIdList:Array<Object>) {
        this.name = name;
        this.status = status;
        if(taskIdList)
        this.taskIdList = taskIdList;
    }
}

export class UpdateProjectGroupRequest {
    id:string
    name: string;
    status:string;
    taskIdList:Array<Object>;
    closeProjects:Boolean

    constructor(id:string,name: string,status:string,taskIdList:Array<Object>,closeProjects:Boolean) {
        this.id = id; 
        this.name = name;
        if(status) 
        this.status = status;
        if(taskIdList)
        this.taskIdList = taskIdList;
        this.closeProjects = closeProjects;
    }
}
