import React, { useContext, useEffect, useState } from "react";
import BlocRegistry from "../../blocs/registry";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField, Button, Container, Stack } from '@mui/material';
import { Link } from "react-router-dom"
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { AppToaster } from "../../components/Toaster/Toaster";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./ManageNotesView.module.css";
import {
  Intent,
} from "@blueprintjs/core";
import Compressor from "compressorjs";
import { GlobalConstanst } from "../../config/Constant";
import {
  AttachmentTypeEnum,
} from "../../models/Attachment/Attachment";
import {
  AttachmentOutEventTypesEnum as AttachmentOutEvent, CreateAttachmentForProjectEvent,
  FetchAttachmentsForProjectEvent
} from "../../blocs/projectAttachmentBloc";

import {
  NotesEventTypesEnum as NotesEvent, CreateNoteEvent, UpdateNoteEvent, AddAttachmentsEvent
} from "../../blocs/notesBloc";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  outline: 'none'
};

export default function AddUpdateNote({ open, projectId, isEdit, currentNote, handleClose }) {
  const [notesTitle, setNotesTitle] = useState(isEdit ? currentNote?.title : '')
  const [description, setDescription] = useState(isEdit ? currentNote?.description : '')
  const [photoName, setPhotoName] = useState("");
  const [photo, setPhoto] = useState("");
  const [isUploaded, setUploaded] = useState(false);
  const [title, setTitle] = useState("");
  const [dataUri, setDataUri] = useState("");
  const [includeInEr, setIncludeInEr] = useState(true);
  const [fileName, SetFileName] = useState("");

  const { notesBloc } = useContext(BlocRegistry);
  const { attachmentBloc } = useContext(BlocRegistry);

  const handlePhotoUpload = async (e, dragged = false) => {
    let image = {};
    if (dragged) {
      image = e[0];
    } else {
      image = e.target.files[0];
    }
    //setPhotoOption(false);
    if (!image.name.match(/\.(jpg|jpeg|png|gif|webp|tiff|psd|svg)$/)) {
      AppToaster.show({
        message: "Please select valid image",
        intent: Intent.DANGER,
      });
      setUploaded(false);
      return false;
    } else {
      let imageBlob = new Blob();
      let imageCompressionRatio = getCompressionRatio(image.size);
      if (imageCompressionRatio) {
        imageBlob = await new Promise((resolve, reject) => {
          new Compressor(image, {
            quality: imageCompressionRatio,
            success: (compressedResult) => {
              resolve(compressedResult);
            },
          });
        });
      } else {
        imageBlob = image;
      }
      setPhotoName(image.name);
      setUploaded(true);
      const fileString = await new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (err) => {
          throw err;
        };
        reader.readAsDataURL(imageBlob);
      });

      setPhoto(fileString);
      setDataUri(fileString); //datauri

      if (isUploaded) {
        //dataUri = fileString;
        let fileName;
        if (isUploaded) {
          fileName =
            title === ""
              ? photoName
              : `C_${title}.${photoName.slice(
                ((photoName.lastIndexOf(".") - 1) >>> 0) + 2
              )}`;
        } else {
          fileName = `C_${title}.jpg`;
        }
        SetFileName(fileName);
        const request = {
          projectId: projectId,
          title: fileName,
          description: "description",
          fileType: fileString.substring(
            fileString.indexOf(":") + 1,
            fileString.indexOf(";")
          ),
          contentType: fileString.substring(
            fileString.indexOf(";") + 1,
            fileString.indexOf(",")
          ),
          payload: fileString,
          includeInEr: includeInEr,
        }
        onAddPhoto(request);
        //setDataUri("");
        //setPhotoOption(false);
        //setTitle("");
        //setDescription("");
        setPhotoName("");
        setUploaded(false);
      } else if (!isUploaded) {
        //dataUri = fileString;
        let fileName;
        if (isUploaded) {
          fileName =
            title === ""
              ? photoName
              : `C_${title}.${photoName.slice(
                ((photoName.lastIndexOf(".") - 1) >>> 0) + 2
              )}`;
        } else {
          fileName = `C_${title}.jpg`;
        }
        SetFileName(fileName);
        const request = {
          projectId: projectId,
          title: fileName,
          description: "description",
          fileType: fileString.substring(
            fileString.indexOf(":") + 1,
            fileString.indexOf(";")
          ),
          contentType: fileString.substring(
            fileString.indexOf(";") + 1,
            fileString.indexOf(",")
          ),
          payload: fileString,
          includeInEr: includeInEr,
        }
        onAddPhoto(request);
        //setDataUri("");
        // setPhotoOption(false);
        // setTitle("");
        // setDescription("");
        setPhotoName("");
        setUploaded(false);
      } else if (!title.trim()) {
        //setTitleError(true);
      }
    }
  };

  const onAddPhoto = (request) => {
    attachmentBloc.sendEvent(new CreateAttachmentForProjectEvent(AttachmentTypeEnum.file, request));
  };

  const getCompressionRatio = (imageSize) => {
    let ratio = 0.4;
    if (imageSize <= GlobalConstanst.FILE_SIZE_LOW) {
      return false;
    } else if (
      imageSize > GlobalConstanst.FILE_SIZE_LOW &&
      imageSize < GlobalConstanst.FILE_SIZE_MEDIUM
    ) {
      ratio = 0.8;
    } else if (
      imageSize >= GlobalConstanst.FILE_SIZE_MEDIUM &&
      imageSize < GlobalConstanst.FILE_SIZE_MAXIMUM
    ) {
      ratio = 0.7;
    }
    return ratio;
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (isEdit) {
      const noteDetail = {
        "title": notesTitle,
        "description": description,
      };
      notesBloc.sendEvent(new UpdateNoteEvent(noteDetail, currentNote?.id));
      const projDetail = {
        "id": currentNote?.id
      };
      const payload = {
        ...projDetail,
        "attachments": [{
          "title": fileName,
          "fileType": dataUri.substring(
            dataUri.indexOf(":") + 1,
            dataUri.indexOf(";")
          ),
          "contentType": dataUri.substring(
            dataUri.indexOf(";") + 1,
            dataUri.indexOf(",")
          )
        }]
      }
      notesBloc.sendEvent(new AddAttachmentsEvent(payload));
    } else {
      const noteDetail = {
        "title": notesTitle,
        "description": description,
        "projectId": projectId
      };
      const payload = {
        ...noteDetail,
        "attachments": [{
          "title": fileName,
          "fileType": dataUri.substring(
            dataUri.indexOf(":") + 1,
            dataUri.indexOf(";")
          ),
          "contentType": dataUri.substring(
            dataUri.indexOf(";") + 1,
            dataUri.indexOf(",")
          )
        }]
      }
      notesBloc.sendEvent(new CreateNoteEvent(payload));
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <React.Fragment>
            <h2>{isEdit ? 'Update Note' : 'Add Note'} </h2>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <form onSubmit={handleSubmit} action={<Link to="" />}>
              <Stack spacing={2}>
                <TextField
                  type="text"
                  variant='outlined'
                  color='primary'
                  label="Title"
                  onChange={e => setNotesTitle(e.target.value)}
                  value={notesTitle}
                  fullWidth
                  required
                  inputProps={{ maxLength: 255 }}
                />
                <TextField
                  type="text"
                  variant='outlined'
                  color='primary'
                  label="Description"
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={e => setDescription(e.target.value)}
                  value={description}
                  fullWidth
                  required
                  inputProps={{ maxLength: 500 }}
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  inputProps={{
                    multiple: true
                  }}
                  onChange={e => handlePhotoUpload(e)}
                />
                <Button className={styles.submitBtn} size="small" variant="outlined" color="primary" type="submit">{isEdit ? 'Update' : 'Create'}</Button>
              </Stack>
            </form>
          </React.Fragment>
        </Box>
      </Modal>
    </div>
  );
}