//@flow

import { AuthBloc } from "./authBloc";
import React from "react";
import { ProjectBloc } from "./projectBloc";
import { UserBloc } from "./userBloc";
import ProjectApi from "../api/ProjectApi/ProjectApi";
import ProjectService from "../service/ProjectService/ProjectService";
import UserService from "../service/UserService/UserService";
import UserApi from "../api/UserApi/UserApi";
import TaskGroupApi from "../api/TaskGroupApi/TaskGroupApi";
import TaskGroupService from "../service/TaskGroupService/TaskGroupService";
import TaskService from "../service/TaskService/TaskService";
import TaskApi from "../api/TaskApi/TaskApi";
import { TaskBloc } from "./taskBloc";
import AssignedTaskService from "../service/AssignedTaskService/AssignedTaskService";
import AssignedTaskApi from "../api/AssignedTaskApi/AssignedTaskApi";
import { AssignedTaskBloc } from "./assignedTaskBloc";
import { HomeownerApplicationBloc } from "./homeownerApplicationBloc";
import HomeownerApplicationApi from "../api/HomeownerApplicationApi/HomeownerApplicationApi";
import HomeownerApplicationService from "../service/HomeownerApplicationService/HomeownerApplicationService";
import AttachmentApi from "../api/AttachmentApi/AttachmentApi";
import AttachmentService from "../service/AttachmentService/AttachmentService";
import { AttachmentBloc } from "./projectAttachmentBloc";
import AttachmentStorageApi from "../api/AttachmentApi/AttachmentStorageApi";
import ProjectGroupService from "../service/ProjectGroupService/ProjectGroupService";
import ProjectGroupApi from "../api/ProjectGroupApi/ProjectGroupApi";
import VendorApi from "../api/VendorApi/VendorApi";
import VendorService from "../service/VendorService/VendorService";
import { VendorBloc } from "./vendorBloc";
import { InvoiceBloc } from "./projectInvoiceBloc";
import InvoiceApi from "../api/InvoiceApi/InvoiceApi";
import InvoiceService from "../service/InvoiceService/InvoiceService";
import { ExportErBloc } from "./exportErBloc";
import ExportErApi from "../api/ExportErApi/ExportErApi";
import ExportErService from "../service/ExportErService/ExportErService";
import { NotesBloc } from "./notesBloc";
import NotesService from "../service/NotesService/NotesService";
import NotesApi from "../api/NotesApi/NotesApi";
class BlocContext {
    authBloc: AuthBloc;
    projectBloc: ProjectBloc;
    userBloc: UserBloc;
    taskBloc: TaskBloc;
    assignedTaskBloc: AssignedTaskBloc;
    homeownerApplicationBloc: HomeownerApplicationBloc;
    attachmentBloc: AttachmentBloc;
    vendorBloc: VendorBloc;
    invoiceBloc: InvoiceBloc;
    exportErBloc: ExportErBloc;
    notesBloc: NotesBloc;

    constructor(authBloc: AuthBloc,
        projectBloc: ProjectBloc,
        userBloc: UserBloc,
        taskBloc: TaskBloc,
        assignedTaskBloc: AssignedTaskBloc,
        homeownerApplicationBloc: HomeownerApplicationBloc,
        attachmentBloc: AttachmentBloc,
        vendorBloc: VendorBloc,
        invoiceBloc: InvoiceBloc,
        exportErBloc: ExportErBloc,
        notesBloc: NotesBloc) {
        this.authBloc = authBloc;
        this.projectBloc = projectBloc;
        this.userBloc = userBloc;
        this.taskBloc = taskBloc;
        this.assignedTaskBloc = assignedTaskBloc;
        this.homeownerApplicationBloc = homeownerApplicationBloc;
        this.attachmentBloc = attachmentBloc;
        this.vendorBloc = vendorBloc;
        this.invoiceBloc = invoiceBloc;
        this.exportErBloc = exportErBloc;
        this.notesBloc = notesBloc;
    }
}

const authBloc = new AuthBloc();

const projectGroupApi = new ProjectGroupApi(authBloc);
const projectGroupService = new ProjectGroupService(projectGroupApi);

const projectApi = new ProjectApi(authBloc);
const projectService = new ProjectService(projectApi, projectGroupService);

const projectBloc = new ProjectBloc(projectService, projectGroupService, authBloc);

const userApi = new UserApi(authBloc);
const userService = new UserService(userApi);
const userBloc = new UserBloc(userService, authBloc);

const taskApi = new TaskApi(authBloc);
const taskGroupApi = new TaskGroupApi(authBloc);
const taskService = new TaskService(taskApi);
const taskGroupService = new TaskGroupService(taskGroupApi);
const taskBloc = new TaskBloc(taskService, taskGroupService, authBloc);

const assignedTaskApi = new AssignedTaskApi(authBloc);
const assignedTaskService = new AssignedTaskService(assignedTaskApi);
const assignedTaskBloc = new AssignedTaskBloc(assignedTaskService);

const homeownerApplicationApi = new HomeownerApplicationApi(authBloc);
const homeownerApplicationService = new HomeownerApplicationService(homeownerApplicationApi);
const homeownerApplicationBloc = new HomeownerApplicationBloc(homeownerApplicationService);

const attachmentApi = new AttachmentApi(authBloc);
const attachmentStorageApi = new AttachmentStorageApi();
const attachmentService = new AttachmentService(attachmentApi, attachmentStorageApi);
const attachmentBloc = new AttachmentBloc(attachmentService);
const vendorApi = new VendorApi(authBloc);
const vendorService = new VendorService(vendorApi);
const vendorBloc = new VendorBloc(vendorService, authBloc);

const invoiceApi = new InvoiceApi(authBloc);
const invoiceService = new InvoiceService(invoiceApi, attachmentStorageApi);
const invoiceBloc = new InvoiceBloc(invoiceService);

const exportErApi = new ExportErApi(authBloc);
const exportErService = new ExportErService(exportErApi);
const exportErBloc = new ExportErBloc(exportErService);

const notesApi = new NotesApi(authBloc);
const noteService = new NotesService(notesApi);
const notesBloc = new NotesBloc(noteService);

export default React.createContext < BlocContext > (new BlocContext(authBloc,
    projectBloc,
    userBloc,
    taskBloc,
    assignedTaskBloc,
    homeownerApplicationBloc,
    attachmentBloc,
    vendorBloc,
    invoiceBloc,
    exportErBloc,
    notesBloc
));
