//@flow

import styles from "./HomeownerApplicationForm.module.css";
import React, { useState,useEffect,useContext } from "react";
import { Formik, ErrorMessage, Field, Form, FastField } from "formik";
import { Button, Tag, TextArea, Overlay, FileInput } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { InputGroup, Divider, Card, Intent } from "@blueprintjs/core";
import HomeownerApplication from "../../models/HomeownerApplication/HomeownerApplication";
import TextSelect from "../TextSelect/TextSelect";
import HouseholdMembersList from "../HouseholdMembersList/HouseholdMembersList";
import DateSelect from "../DateSelect/DateSelect";
import SignatureSelect from "../SignatureInput/SignatureSelect";
import CheckboxSelect from "../CheckboxSelect/CheckboxSelect";
import ReactToPrint from "react-to-print";
import { AppToaster } from "../Toaster/Toaster";
import Project from "../../models/Project/Project";
import { AuthRoles } from "../../blocs/authBloc";
import mime from "mime-types";
import moment from 'moment'
import axios from "axios";
import FileSaver from "file-saver";
import * as Yup from "yup";
import {AuthContext, AuthEvents, AuthStates} from "../../blocs/authBloc";

import BlocRegistry from "../../blocs/registry";



const ref = React.createRef();

type Props = {
  application: ?HomeownerApplication,
  onHomeownerApplicationUpdate: (
    homeownerApplication: HomeownerApplication,
    logRefFile:any
  ) => any,
  onHomeownerApplicationUpdated: () => any,
  onHomeownerApplicationReadyForReview: () => any,
  onHomeownerApplicationApproved: () => any,
  onHomeownerApplicationRejected: () => any,
  project:?Project,
  brand:String,
  onHomeownerApplicationLogsAdded:() => any
};

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

export default function HomeownerApplicationForm(props: Props) {
  const {
    onHomeownerApplicationUpdate,
    application,
    onHomeownerApplicationApproved,
    onHomeownerApplicationRejected,
    onHomeownerApplicationReadyForReview,
    onHomeownerApplicationUpdated,
    project,
    currentUserRole,
    onHomeownerApplicationLogsAdded
  } = props;
  const [state, setState] = useState(formStates.INPUT);
  const [isGoBack, setIsGoBack] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [applicationValue, setApplicationValue] = useState();
  
  const [fileData, setFileData] = useState(null);
  const [mimeType, setMimeType] = useState("");
  const {authBloc}= useContext(BlocRegistry);
  
  const [isFileError, setIsFileError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null)
  const [getHomeowerLog, setHomeowerLog] = useState([])
  const [logFileDownload, setLogFileDownload] = useState(null)
  const [getHomeownerList, setGetHomeownerList] = useState(false)
  const [getAccountName, setAccountName] = useState("")


  const [value, setValue] = useState(
    application.applicationData.rejected_note || ""
  );

  const [totalIncome,setTotalIncome] = useState('');
  const [changeIncome,setChangeIncome] = useState(false);

  // const disableEdit = application.status !== "CREATED";
  const disableEdit = AuthRoles.COMPLIANCE_AUDITOR === currentUserRole;

  const signatureReady = application.status === "READY_FOR_SIGNATURE";
  const reviewReady = application.status === "READY_FOR_REVIEW";
  const completed =
    application.status === "APPROVED" || application.status === "REJECTED";

  const showSignatureSections = signatureReady || reviewReady || completed;
  const reload=()=>window.location.reload();
  
  const applicantName = `${project.home.homeowner.firstName} ${project.home.homeowner.lastName}`
  const currentAddress = `${project.home.address.streetAddress} ${project.home.address.streetAddressTwo != null ?project.home.address.streetAddressTwo:""}`
  const city = project.home.address.city
  const zip = project.home.address.zip
  const phoneNumber = project.home.homeowner.phoneNumber
  const address_type =  application.brand.address_type 
  
  const initialAppData = {
    applicant_name:application.applicationData.applicant_name || applicantName,
    current_address:application.applicationData.current_address || currentAddress,
    city:application.applicationData.city || city,
    zip:application.applicationData.zip || zip,
    phone_number:application.applicationData.phone_number || phoneNumber
  }

  const getApplicationValues = (applicationValues)=> {
    if(applicationValues){
      let total = 0;
      applicationValues.map((data, index) => {
          switch (data.payment_frequency) {
              case "Annually":
                  total = total + Number(data.monthly_income) * 1;
                 break; 
              
              case "Monthly":
                  total = total + Number(data.monthly_income) * 12;
                 break;
  
              case "Twice a Month":
                  total = total + Number(data.monthly_income) * 24;
                 break;    
                 
              case "Bi-Weekly":
                  total = total + Number(data.monthly_income) * 26;
                 break;
                 
              case "Weekly":
                  total = total + Number(data.monthly_income) * 52;
                 break;
              case "Hourly":
                  total = total + (Number(data.monthly_income) * data.hours_per_week) * 52;
                 break;            
              default:
                  return ''
                  
          }
      })
      return total;
  }
  }
  const authEventHandler = {
      next(authContext: AuthContext) {
          switch (authContext.State) {
              case AuthStates.UNINITIALIZED:
              case AuthStates.UNAUTHENTICATED: {
                  break;
              }
              case AuthStates.AUTHENTICATING: {
                  break;
              }
              case AuthStates.AUTHENTICATED: {
                  setAccountName(authContext.OAuthAccount.name)
                  break;
              }
              default: {
                  throw new Error("Unknown auth state: " + authContext.state);
              }
          }
      },
      error(err: Error) {
          throw err;
      }
  };
  useEffect(() => {
    const subscription: Subscription = authBloc.subscribeToAuthContext(authEventHandler);
    return () => {
        subscription.unsubscribe();
    }
}, [authBloc]);

 useEffect(() => {
  setHomeowerLog(application.homeownerLog)
 }, [application.homeownerLog])
 
 useEffect(() => {
   onHomeownerApplicationLogsAdded()
   return () => { setGetHomeownerList(false) 
  }
 }, [getHomeownerList])

const brand = application.brand
  const createInput = (
    inputName: string,
    inputLabel: string,
    options?: { fill?: boolean, type?: string, slow?: boolean }
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let field = (
      <FastField
        className={`bp3-large ${styles.input}`}
        as={InputGroup}
        name={inputName}
        type={type}
        fill={fill}
        disabled={state !== formStates.INPUT || disableEdit || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
      />
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createInputArea = (
    inputName: string,
    inputLabel: string,
    options?: { fill?: boolean, type?: string, slow?: boolean }
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let maxLength = 250;

    let field = (
      <>
        <FastField
          className={`bp3-large ${styles.input}`}
          as={TextArea}
          rows="10"
          cols="30"
          name={inputName}
          type={type}
          fill={fill}
          value={value}
          maxLength={maxLength}
          disabled={state !== formStates.INPUT || disableEdit || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
          onInput={e => setValue(e.target.value)}
        />
      </>
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
    <label htmlFor={inputName}>{`${inputLabel} (${maxLength - value.length} characters remaining)`}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  
  let {total_household_income} = application.applicationData

  const createAnnualHouseholdInput = (
    inputName: string,
    inputLabel: string,
    options?: { fill?: boolean, type?: string, slow?: boolean }
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let field = (
      changeIncome ?  <FastField
      className={`bp3-large ${styles.input}`}
      as={InputGroup}
      name={inputName}
      type={type}
      fill={fill}
      leftIcon="dollar"
      value={totalIncome >=0 ? totalIncome.toLocaleString() : total_household_income >=0 ? total_household_income:""}
      disabled={state !== formStates.INPUT || disableEdit || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
      onInput={e => {
        setChangeIncome(true);
        setTotalIncome(e.target.value);
      }}
    /> :
      <Field
        className={`bp3-large ${styles.input}`}
        as={InputGroup}
        name={inputName}
        type={type}
        fill={fill}
        leftIcon="dollar"
        value={totalIncome >=0 ? totalIncome.toLocaleString() : total_household_income >=0 ? total_household_income:""}
        disabled={state !== formStates.INPUT || disableEdit || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
        onInput={e => {
          setChangeIncome(true);
          setTotalIncome(e.target.value);
        }}
      />
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSignatureInput = (
    inputName: string,
    inputLabel: string,
    options?: { fill?: boolean, type?: string, slow?: boolean }
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let field = (
      <FastField
        className={`bp3-large`}
        as={InputGroup}
        name={inputName}
        type={type}
        fill={fill}
        // disabled={state !== formStates.INPUT || !signatureReady}
        disabled={state !== formStates.INPUT }
      />
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSelectInput = (inputName: string, inputLabel: string, select) => {
    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{select}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  if((application.status=="READY_FOR_REVIEW"||application.status=="APPROVED") && isApproved ==true){

    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
          reject(error);
        }
      })
    }
  
    return(
      
                    
        <Overlay
        isOpen={isApproved}
        canEscapeKeyClose={true}
        onClose={() => {
          setIsApproved(false);
          setIsFileError("");
          setGetHomeownerList(true);

        }}
      >
        <Flexbox flexGrow={1}
                  flexDirection={'column'} paddingLeft={'10px'} paddingRight={'10px'} width={'100%'} height={'100%'}
                    className={styles.fullscreenImageContainer}
                  >
          <div className={styles.closeButton}>
            <Button icon={"cross"} onClick={() => { setIsApproved(false)
                 setGetHomeownerList(true)  }
          }/>
          </div>
          <Flexbox justifyContent={'center'} alignContent={'center'} width={'100%'} style={{marginTop: 42}}>
                <h1 >
                Log Application Change
                </h1>
                
          </Flexbox>
          <Flexbox justifyContent={'center'} alignContent={'center'} width={'100%'} style={{marginTop: 42}}>
                <p>
                  Because signatures have already been collected for this application, you must log the changes you just made.<br/>
                   All logged changes will be listed at the bottom of the application.
                </p>  
          </Flexbox>
          <Flexbox justifyContent={'center'} alignContent={'center'} width={'100%'} >
          <Formik 
                   initialValues={{
                    updated:'',
                    approved_by:'',
                    timeStamp: moment().format("MM/DD/YYYY HH:mm:ss"),
                    changes_made_by:getAccountName,
                    attachment:"",
                    file_type:"",
                    content_type:"",
                    applicationData:application?.applicationData,
                    applicationValue:applicationValue,
                   }}
                   validationSchema={Yup.object({
                    updated: Yup.string().required("required"),
                    approved_by : Yup.string().required("required"),
                   })}
                   onSubmit={async values => {
                    values.attachment = selectedFile ? selectedFile.name : "";
                    values.file_type = mimeType ?  mimeType : "";
                    values.content_type = "base64";
                    let totalIncomes  = getApplicationValues(values?.applicationValue?.household_members);
                    let applicationValues = values?.applicationValue;
                    applicationValues.total_household_income = totalIncomes;
                    const update = {
                      projectId: application?.projectId,
                      status: application?.status,
                     applicationData:applicationValues,
                     createLog:true,
                      homeownerLog: {
                        updated: values.updated,
                        approved_by:values.approved_by,
                        timestamp: values.timeStamp,
                        changes_made_by:values.changes_made_by,
                        attachment:values.attachment,
                        file_type:values.file_type,
                        content_type:values.content_type,
                      }
                    };
                    const LogfileData = fileData ? fileData : null;
                    await onHomeownerApplicationUpdate(update, LogfileData);
                    setSelectedFile(null);
                  setIsApproved(false);
                  AppToaster.show({
                    message: "Application & Logs Updated ",
                    intent: Intent.PRIMARY
                  });
                   setGetHomeownerList(true);
                   }}
                >
                  <Form>
          <Flexbox justifyContent={'flex-start'}  flexDirection={'column'}   width={'100%'} style={{marginTop: 42}}>
                <Flexbox>Timestamp: { moment().format("MM/DD/YYYY HH:mm:ss")}</Flexbox> 
                <Flexbox>Changes Made By: {getAccountName}</Flexbox> 
          </Flexbox>
          <Flexbox justifyContent={'flex-start'}   flexDirection={'column'} width={'100%'} style={{marginTop: 35}}>
                {/* <Flexbox>What was changed/updated?</Flexbox> */}
                
                    {createInput("updated", "What was changed/updated?")}
                    {createSelectInput(
                    "approved_by",
                    "How did the homeowner approve the edits?",
                    <TextSelect
                      items={["Email", "Phone", "Written", "Verbal", "Other"]}
                      inputName={"approved_by"}
                    />
                  )}
                  <Flexbox width={'100%'} style={{marginTop: 42}}>
                    <FileInput
                      fill
                      large
                      intent={Intent.PRIMARY}
                        disabled={false}
                        inputProps={{ accept: "application/pdf" }}
                        text= {selectedFile ? selectedFile.name : "Add Attachment"}
                        onInputChange={async (event)=>{
                          let file = event.target.files[0];
                          setSelectedFile(file);
                            let base64Content = await convertBase64(file);
                          await setFileData(base64Content)
                            let mimeType =base64Content.split(",") 
                          await setMimeType(mimeType[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0])
                         }}
                      />
                     
                    </Flexbox>
                  <Flexbox  justifyContent={'center'} style={{marginTop: 42}}>
                    
                  <Button
                      type="submit"
                      onClick={() => {
                       setIsGoBack(false);
                     
                       
                      }}
                      large
                      rightIcon={'upload'}
                      intent={'primary'}
                    >
                      Save Changes
                    </Button>
                  </Flexbox>
              
          </Flexbox>      
          </Form>
                </Formik> 
          </Flexbox>

        </Flexbox>
      </Overlay>
      
    )
  }
  

  
  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      {completed && (
        <ReactToPrint
          trigger={() => (
            <Flexbox marginTop={"20px"} justifyContent={"center"}>
              <Button rightIcon={"print"}>Save/Print PDF</Button>
            </Flexbox>
          )}
          content={() => ref.current}
        />
      )}
      <Formik
          initialValues={Object.values(application.applicationData).length > 1 ? application.applicationData:initialAppData}
          enableReinitialize
        onSubmit={async values => {
          if (!application) return;
          setState(formStates.SUBMITTING);
          values.total_household_income = totalIncome;
          const update = {
            projectId: application.projectId,
            status: values.status || "CREATED",
            applicationData: values,
            createLog:false
          };
          await onHomeownerApplicationUpdate(update,null);
          await onHomeownerApplicationLogsAdded()
          setState(formStates.SUCCESS);
          if (values.status === "CREATED") onHomeownerApplicationUpdated();
          if (values.status === "READY_FOR_REVIEW")
            onHomeownerApplicationReadyForReview(isGoBack);
          if (values.status === "APPROVED")
            onHomeownerApplicationApproved(isGoBack);
          if (values.status === "REJECTED")
            onHomeownerApplicationRejected(isGoBack);
          setState(formStates.INPUT);
        }}
      >
        {Formik => {
          return (
            <Form ref={ref}>
              {completed && (
                <Flexbox paddingTop={"50px"} justifyContent={"center"}>
                  <Tag>{application.status}</Tag>
                </Flexbox>
              )}
              <Flexbox
                alignItems={"center"}
                flexDirection={"column"}
                className={styles.inputSection}
              >
                <h3>Preserve a Home Application</h3>
                <Card elevation={1} className={styles.card}>
                  <Flexbox flex={1} alignItems={"left"} width={"100%"}>
                    <h3>Applicant Information</h3>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {createInput("applicant_name", "Applicant Name")}
                  {createInput("co_applicant_name", "Co-Applicant")}
                  {createInput("current_address", "Current Address")}
                  {createInput("phone_number", "Phone #", { type: "phone" })}
                  {createInput("city", "City")}
                  {createInput("zip", "Zip", { fill: false })}
                  {createInput("years_at_residence", "Years at Residence", {
                    fill: false
                  })}
                   {createInput("tadSqft", "TAD Sqft", {
                    fill: false
                  })}
                  {createSelectInput(
                    "applicant_gender",
                    "Applicant Gender",
                    <TextSelect
                      items={["Male", "Female"]}
                      inputName={"applicant_gender"}
                      disabled={disableEdit}
                    />
                  )}
                  {createSelectInput(
                    "female_head_of_household",
                    "Female Head of Household",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"female_head_of_household"}
                      disabled={disableEdit}
                    />
                  )}
                  {createSelectInput(
                    "applicant_race",
                    "Race",
                    <TextSelect
                      items={[
                        "White",
                        "Black/African American",
                        "Black/African American & White",
                        "American Indian/Alaskan Native",
                        "American Indian/Alaskan Native & White",
                        "Native Hawaiian/Other Pacific Islander",
                        "American Indian/Alaskan Native & Black/African American",
                        "Asian",
                        "Asian & White",
                        "Balance/Other"
                      ]}
                      inputName={"applicant_race"}
                      disabled={disableEdit}
                    />
                  )}
                  {createSelectInput(
                    "applicant_ethnicity",
                    "Ethnicity",
                    <TextSelect
                      items={["Hispanic", "Non-Hispanic"]}
                      inputName={"applicant_ethnicity"}
                      disabled={disableEdit}
                    />
                  )}
                  {createSelectInput(
                    "applicant_disabled",
                    "Disabled",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"applicant_disabled"}
                      disabled={disableEdit}
                    />
                  )}
                  {Formik.values.applicant_disabled === "Yes" &&
                    createInput("applicant_disabled_explanation", "Explain")}
                  {createSelectInput(
                    "applicant_veteran",
                    "Veteran",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"applicant_veteran"}
                      disabled={disableEdit}
                    />
                  )}
                  {Formik.values.applicant_veteran === "Yes" &&
                    createInput("applicant_veteran_explanation", "Branch")}

                  <HouseholdMembersList
                    inputName={"household_members"}
                    disabled={disableEdit}
                    onAnnualIncomeChanged={(income) =>{
                      setTotalIncome(income);
                    }}
                    onDeteleIncome={() => setChangeIncome(false)}
                  />
                  
                  {/* {totalIncome ? (
                  <h3>Total Annual Household Income: {totalIncome}</h3>
                  ):null} */}

                  {createInput(
                    "total_household_members",
                    "Total Number of Family Members",
                    { fill: false }
                  )}
                  {createAnnualHouseholdInput(
                    "total_household_income",
                    "Total Gross Annual Household Income",
                    { fill: false }
                  )}

                  {showSignatureSections && (
                    <div>
                      <Divider className={styles.fullWidthInput} />
                      <SignatureSelect
                        // disabled={reviewReady || completed}
                        inputName={"applicant_info_signature"}
                        dateInputName={"applicant_info_signature_date"}
                      />
                      <Divider className={styles.fullWidthInput} />
                    </div>
                  )}

                  <h6>
                    WARNING: TITLE 18, SECTION 1001 OF THE U.S. CODE STATES THAT
                    A PERSON IS GUILTY OF A FELONY FOR KNOWINGLY AND WILLINGLY
                    MAKING FALSE OR FRAUDULENT STATEMENTS TO ANY DEPARTMENT OF
                    THE UNITED STATES GOVERNMENT
                  </h6>
                </Card>

                <Card elevation={1} className={styles.card}>
                  <Flexbox
                    flex={1}
                    alignItems={"left"}
                    width={"100%"}
                    flexDirection={"column"}
                  >
                    <h3>
                      Documenting Client Status as "Severely Disabled Adult"
                    </h3>
                    <h5>CHECK ALL THAT APPLY</h5>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <h5>
                    Has used a wheelchair, a walker, cane, crutches, or other
                    special aid for six month or longer
                  </h5>
                  {createSelectInput(
                    "client_status_has_walking_aid",
                    "",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"client_status_has_walking_aid"}
                      disabled={disableEdit}
                    />
                  )}

                  <h5>
                    Has been diagnosed with one of the following conditions:
                    Alzheimer's disease, mental retardation or other
                    developmental disability, senility or dementia, cerebral
                    palsy, or autism; or has another mental or emotional
                    condition that seriosly interferes with everyday tasks
                  </h5>
                  {createSelectInput(
                    "client_status_has_mental_condition",
                    "",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"client_status_has_mental_condition"}
                      disabled={disableEdit}
                    />
                  )}

                  <h5>
                    Is unable to perform one or more of the following
                    "Functional Activities": seeing, hearing, speaking and
                    having one's speech understood, lifting or carrying, using
                    stairs, grasping small objects, or walking.
                  </h5>
                  {createSelectInput(
                    "client_status_functional_activities_disabled",
                    "",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"client_status_functional_activities_disabled"}
                      disabled={disableEdit}
                    />
                  )}

                  <h5>
                    Is unable to perform or needs assistance to perform one or
                    more of the following Activities of Daily Living (ADL):
                    getting around inside the home, getting in or out of bed or
                    a chair, bathing, dressing, eating, and toileting
                  </h5>
                  {createSelectInput(
                    "client_status_activities_daily_living_disabled",
                    "",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={
                        "client_status_activities_daily_living_disabled"
                      }
                      disabled={disableEdit}
                    />
                  )}

                  <h5>
                    Is unable to perform or needs assistance to perform one or
                    more of the following Instrumental Activities of Daily
                    Living (IADL): going outside the home, keeping track of
                    money and bills, preparing meals, doing light housework,
                    taking prescription medications in the right amount and at
                    the right time, and using the telephone.{" "}
                  </h5>
                  {createSelectInput(
                    "client_status_instrumental_activities_daily_living_disabled",
                    "",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={
                        "client_status_instrumental_activities_daily_living_disabled"
                      }
                      disabled={disableEdit}
                    />
                  )}

                  <h5>
                    Has a condition that prevents them from working at a job or
                    doing housework.{" "}
                  </h5>
                  {createSelectInput(
                    "client_status_cannot_work",
                    "",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"client_status_cannot_work"}
                      disabled={disableEdit}
                    />
                  )}

                  {showSignatureSections && (
                    <div>
                      <Divider className={styles.fullWidthInput} />

                      {/* <h6>
                        The undersigned Agency representative conducted a visual
                        assessment of the client identified on this form and
                        certifies that the client meets at least one of the
                        conditions indicated above, which determines that he/she
                        meets the definition of "severly disabled" according to
                        the U.S. Census Bureau.
                      </h6> */}

                      {createSignatureInput(
                        "agency_disability_certified_by",
                        "Certified by:",
                        { fill: false }
                      )}

                      {createSignatureInput(
                        "agency_disability_certified_by_title",
                        "Title",
                        { fill: false }
                      )}

                      <SignatureSelect
                        // disabled={reviewReady || completed}
                        inputName={"agency_disability_signature"}
                        dateInputName={"agency_disability_signature_date"}
                      />
                      <Divider className={styles.fullWidthInput} />
                    </div>
                  )}
                </Card>

                <Card elevation={1} className={styles.card}>
                  <Flexbox flex={1} alignItems={"left"} width={"100%"}>
                    <h3>
                      CERTIFICATION REGRADING COMPLIANCE WITH LEAD-SAFE HOUSING
                      RULE MINOR REHABILITATION PROGRAMS UNDER $5000
                    </h3>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {createInput("home_year_built", "Year Built", {
                    fill: false
                  })}
                  {createInput("program", "Program", { fill: false })}
                  {createSelectInput(
                    "painted_surfaces_disturbance",
                    "Painted Surface",
                    <TextSelect
                      items={["WILL be disturbed", "WILL NOT be disturbed"]}
                      inputName={"painted_surfaces_disturbance"}
                      disabled={disableEdit}
                    />
                  )}
                  {Formik.values.painted_surfaces_disturbance ===
                    "WILL NOT be disturbed" && (
                    <h5>If painted surfaces will NOT be disturbed:</h5>
                  )}
                  {Formik.values.painted_surfaces_disturbance ===
                    "WILL NOT be disturbed" &&
                    createInput(
                      "painted_surfaces_disturbance_explanation",
                      "Explain"
                    )}

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <h5>For painted surfaces that will be disturbed:</h5>
                  {createSelectInput(
                    "home_interior_lead_size",
                    "Work area?",
                    <TextSelect
                      items={[
                        "is INTERIOR and is Not Larger than 2 square feet",
                        "is INTERIOR and is Larger than 2 square feet",
                        "is EXTERIOR and is Not Larger than 20 square feet",
                        "is EXTERIOR and is Larger than 20 square feet "
                      ]}
                      inputName={"home_interior_smaller_than"}
                      disabled={disableEdit}
                    />
                  )}

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {createSelectInput(
                    "home_occupied_elderly_or_disabled",
                    "Housing unit is occupied by Elderly Person and/or Disabled Person:",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"home_occupied_elderly_or_disabled"}
                      disabled={disableEdit}
                    />
                  )}
                  {createSelectInput(
                    "home_occupied_children",
                    "Children Under 6 Years of Age Reside at/Regularly Visit this Housing Unit",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"home_occupied_children"}
                      disabled={disableEdit}
                    />
                  )}
                  {createSelectInput(
                    "pamphlet_distributed",
                    "Lead Based Paint Information Pamphlet Provided to Homeowner/Resident:",
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"pamphlet_distributed"}
                      disabled={disableEdit}
                    />
                  )}
                  {Formik.values.pamphlet_distributed === "Yes" &&
                    createSelectInput(
                      "pamphlet_distributed_date",
                      "Date Pamphlet Provided",
                      <DateSelect
                        inputName={"pamphlet_distributed_date"}
                        disabled={disableEdit}
                      />
                    )}

                  <h6>
                    <i>
                      In housing built before 1978, for Work Areas Exceeding the
                      above 2 Square Foot/20 Square Foot De-Minimis Levels, and
                      where children under 6 are expected to Reside/Regularly
                      Visit the housing unit, Lead-Certified Contractors must be
                      used, Lead Safe Work Practices must be performed and Dust
                      WIpes/Tests must be performed following completion of the
                      rehabilitation work.
                    </i>
                  </h6>

                  <h6>
                    <i>
                      Housing units construction AFTER 1978, and Pre-1978
                      Housing Units designated for Elderly or Disabled Persons
                      where children are not expected to reside/visit regularly,
                      are Exempt from the Lead Safe Housing Rule in accordance
                      with 24CFR 35.110, Definition of Target Housing
                    </i>
                  </h6>

                  {showSignatureSections && (
                    <div>
                      <Divider className={styles.fullWidthInput} />

                      <h6>
                        I certify that the information provided above is true
                        and correct
                      </h6>

                      {createSignatureInput(
                        "agency_lead_certified_by_printed_name_and_title",
                        "Printed Name and Title",
                        { fill: false }
                      )}

                      <SignatureSelect
                        // disabled={reviewReady || completed}
                        inputName={"agency_lead_signature"}
                        dateInputName={"agency_lead_signature_date"}
                      />
                      <Divider className={styles.fullWidthInput} />
                    </div>
                  )}
                </Card>

                <Card elevation={1} className={styles.card}>
                  <Flexbox flex={1} alignItems={"left"} width={"100%"}>
                    <h3>Conflict of Interest Disclosure</h3>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {createSelectInput(
                    "city_employee_current",
                    `Are you employed by the ${address_type} of ${brand.city}?`,
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"city_employee_current"}
                      disabled={disableEdit}
                    />
                  )}

                  {Formik.values.city_employee_current === "Yes" &&
                    createInput(
                      "city_employee_department_and_division",
                      "If yes, by which Department and Division:"
                    )}

                  {createSelectInput(
                    "city_employee_past",
                    `Were you employed by the ${address_type} of ${brand.city} within the most recent 12-month period?`,
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"city_employee_past"}
                      disabled={disableEdit}
                    />
                  )}

                  {Formik.values.city_employee_past === "Yes" &&
                    createInput(
                      "city_employee_past_department_and_division",
                      "If yes, by which Department and Division:"
                    )}

                  {createSelectInput(
                    "city_employee_family_member_current",
                    `Are any members of your immediate family currently employed by the ${address_type} of ${brand.city}?  ("Immediate Family" includes (whether by blood or adoption):  the spouse, parent (including stepparent), child (including stepchild), brother, sister, (including a stepbrother or stepsister), grandparent, grandchild, and in-laws)`,
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"city_employee_family_member_current"}
                      disabled={disableEdit}
                    />
                  )}

                  {Formik.values.city_employee_family_member_current ===
                    "Yes" &&
                    createInput(
                      "city_employee_family_member_department_and_division",
                      "If yes, please provide relative's name, Department, and Division:"
                    )}

                  {createSelectInput(
                    "city_employee_family_member_past",
                    `If No, were any members of your immediate family employed by the ${address_type} of ${brand.city} within the most recent 12-month period?`,
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"city_employee_family_member_past"}
                      disabled={disableEdit}
                    />
                  )}

                  {Formik.values.city_employee_family_member_past === "Yes" &&
                    createInput(
                      "city_employee_family_member_past_department_and_division",
                      "If yes, please provide relative's name, Department, and Division:"
                    )}

                  {createSelectInput(
                    "city_official",
                    `Are you an elected or appointed official, or agent or consultant, of the ${address_type} of ${brand.city}?`,
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"city_official"}
                      disabled={disableEdit}
                    />
                  )}

                  {Formik.values.city_official === "Yes" &&
                    createInput(
                      "city_official_department_and_division",
                      "If yes, which Department and Division:"
                    )}

                  {createSelectInput(
                    "city_official_family",
                    `Are any immediate family members an electred or appointed official, or agent or consultant of the ${address_type} of ${brand.city}?`,
                    <TextSelect
                      items = {["Yes","No"]}
                      inputName={"city_official_family"}
                      disabled={disableEdit}
                    />
                  )}

                  {Formik.values.city_official_family === "Yes" &&
                    createInput(
                      "city_official_family_department_and_division",
                      "If yes, please provide relative's name, Department, and Division:"
                    )}

                  {showSignatureSections && (
                    <div>
                      <h6>
                        Certification: I understand and agree that the {address_type} may/will
                        contact the {address_type} of {brand.city} department
                        including all supervisors in order to determine whether
                        any of these persons' employment or official functions
                        are or were related to the {address_type}'s use of federal grant
                        funds and whether federal funds can be provided. I
                        certify that the information I am providing is true and
                        could be subject to verification at any time by a third
                        party. I also acknowledge that the provision of false
                        information could leave me subject to the penalities of
                        Federal, State and local law.
                      </h6>

                      <h6>
                        WARNING: TITLE 18, SECTION 1001 OF THE U.S. CODE STATES
                        THAT A PERSON IS GUILTY OF A FELONY FOR KNOWINGLY AND
                        WILLINGLY MAKING FALSE OR FRAUDULENT STATEMENTS TO ANY
                        DEPARTMENT OF THE UNITED STATES GOVERNMENT
                      </h6>

                      {/* <h6>
                        For City Staff Only: Once this form is completed and if
                        "Yes" is marked on any of the above questions, please
                        return it to your Compliance Specialist as soon as
                        possible for processing. If you have any questions,
                        please contact Barbara Asbury, Grants Manager,
                        Compliance Division, at 817.392.7331, or Charletta
                        Moaning, Sr. Contract Compliance Specialist, at
                        817.392.7333 or at charletta.moaning@fortworthgov.org
                      </h6> */}

                      <Divider className={styles.fullWidthInput} />

                      <h6>
                        I certify that the information provided above is true
                        and correct
                      </h6>

                      <SignatureSelect
                        // disabled={reviewReady || completed}
                        inputName={"applicant_conflict_interest_signature"}
                        dateInputName={"agency_conflict_interest_date"}
                      />
                      <Divider className={styles.fullWidthInput} />
                    </div>
                  )}
                </Card>

                {showSignatureSections && (
                  <Card elevation={1} className={styles.card}>
                    <h3>HOMEOWNER’S AGREEMENT</h3>

                    <h6>
                      I certify that the information on this application is true
                      and accurate and that I own the property. I have no
                      present intention to move or offer my home for sale for at{" "}
                      <u>least three years</u>. I confirm that any physically
                      able persons residing in my home or visiting on the
                      project day will work alongside the Preserve A Home
                      volunteers. I confirm that, except for the conditions
                      listed on this application, my home is a safe place for
                      volunteers.
                    </h6>

                    <h6>
                      I understand that the people who may work on my house are
                      unpaid volunteers; that few, if any of them, are skilled
                      in the building trades; and that Preserve A Home MAKES NO
                      WARRANTIES, EXPRESSED OR IMPLIED, REGARDING ANY MATERIALS
                      USED OR WORK DONE BY ANYONE AT MY HOUSE. I herby agree
                      that I, my assignees, their heirs, distributes, guardians,
                      and legal representatives{" "}
                      <u>
                        will not make a claim against, sue or attach the
                        property of {brand.organization_name} 
                      </u>{" "}
                      or any affiliated organizations or the suppliers of any
                      tools or equipment that I use in these activities, for
                      injury or damage resulting from negligence or other acts,
                      howsoever caused by any employee, agent, contractor of, or
                      participant in {brand.organization_name} activities. I
                      hereby release {brand.organization_name} and any of its
                      affiliated organizations from all actions, claims or
                      demands that I, my assigness, heirs, guardians, and legal
                      representatives now have or may hereafter have for injury
                      or damages resulting from my participation in any {brand.organization_name} activities.
                    </h6>

                    <h6>
                      I understand that {brand.organization_name} screens all
                      potential staff (whether paid or unpaid), board members,
                      and applicant families on the sex offender registry. By
                      completing this application, I submit to such an inquiry.
                    </h6>

                    <Divider className={styles.fullWidthInput} />

                    <h6>Applicant Signature</h6>
                    <SignatureSelect
                      // disabled={reviewReady || completed}
                      inputName={"agency_master_signature"}
                      dateInputName={"agency_master_signature_date"}
                    />

                    <h6>Agency Signature</h6>
                    <SignatureSelect
                      // disabled={reviewReady || completed}
                      inputName={"applicant_master_signature"}
                      dateInputName={"applicant_master_signature_date"}
                    />
                    <Divider className={styles.fullWidthInput} />

                    {application.status === "READY_FOR_REVIEW" ||
                    application.status === "REJECTED"
                      ? createInputArea(
                          "rejected_note",
                          "Rejected Note"
                          // { fill: false }
                        )
                      : null}
                  </Card>
                )}
                  
                <Flexbox flex={1} marginTop={"15px"}>
                  {true && (
                    <Button
                      type="button"
                      onClick={() => {

                        if (application && !application.status) {
                          Formik.setFieldValue("status", "CREATED");
                        }
                        if(application?.status=="READY_FOR_SIGNATURE" || application?.status=="CREATED" ){
                          Formik.handleSubmit();
                          setIsGoBack(false);
  
                          AppToaster.show({
                            message: "Application Updated",
                            intent: Intent.PRIMARY
                          });
                          
                        }
                        if(completed || reviewReady ){
                          setApplicationValue(Formik.values)
                          setIsApproved(true)
                        }
                      
                      }}
                      intent={Intent.PRIMARY}
                      className={styles.saveChangesButton}
                      large
                      rightIcon={state === formStates.INPUT ? "upload" : null}
                      disabled={state !== formStates.INPUT || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
                      loading={state === formStates.SUBMITTING}
                      icon={state === formStates.SUCCESS ? "saved" : null}
                    >
                      {state === formStates.INPUT ? "Save Changes" : null}
                    </Button>
                  )}
                  {application.status === "CREATED" && (
                    <Button
                      type="submit"
                      onClick={() => {
                        Formik.setFieldValue("status", "READY_FOR_SIGNATURE");
                        Formik.handleSubmit();
                        window.scrollTo(0, 0);
                      }}
                      large
                      rightIcon={state === formStates.INPUT ? "lock" : null}
                      disabled={state !== formStates.INPUT || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
                      loading={state === formStates.SUBMITTING}
                      icon={state === formStates.SUCCESS ? "saved" : null}
                    >
                      {state === formStates.INPUT
                        ? "Ready For Signature"
                        : null}
                    </Button>
                  )}

                  {/* {application.status === "READY_FOR_SIGNATURE" && (
                    <Button
                      type="submit"
                      onClick={() => {
                        Formik.setFieldValue("status", "CREATED");
                        Formik.handleSubmit();
                      }}
                      large
                      rightIcon={state === formStates.INPUT ? "edit" : null}
                      disabled={state !== formStates.INPUT}
                      loading={state === formStates.SUBMITTING}
                      icon={state === formStates.SUCCESS ? "saved" : null}
                    >
                      {state === formStates.INPUT ? "Edit" : null}
                    </Button>
                  )} */}

                  {application.status === "READY_FOR_SIGNATURE" && (
                    <Button
                      type="submit"
                      onClick={() => {
                        Formik.setFieldValue("status", "READY_FOR_REVIEW");
                        Formik.handleSubmit();
                      }}
                      large
                      rightIcon={state === formStates.INPUT ? "endorsed" : null}
                      disabled={state !== formStates.INPUT || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
                      loading={state === formStates.SUBMITTING}
                      icon={state === formStates.SUCCESS ? "saved" : null}
                    >
                      {state === formStates.INPUT ? "Finish" : null}
                    </Button>
                  )}

                  {application.status === "READY_FOR_REVIEW" && (
                    <Button
                      type="submit"
                      onClick={() => {
                        Formik.setFieldValue("status", "REJECTED");
                        Formik.handleSubmit();
                      }}
                      large
                      rightIcon={state === formStates.INPUT ? "cross" : null}
                      disabled={state !== formStates.INPUT || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
                      loading={state === formStates.SUBMITTING}
                      icon={state === formStates.SUCCESS ? "saved" : null}
                    >
                      {state === formStates.INPUT ? "REJECT" : null}
                    </Button>
                  )}
                  {application.status === "READY_FOR_REVIEW" && (
                    <Button
                      type="submit"
                      onClick={() => {
                        Formik.setFieldValue("status", "APPROVED");
                        Formik.handleSubmit();
                      }}
                      large
                      rightIcon={state === formStates.INPUT ? "endorsed" : null}
                      disabled={state !== formStates.INPUT || AuthRoles.COMPLIANCE_AUDITOR === currentUserRole}
                      loading={state === formStates.SUBMITTING}
                      icon={state === formStates.SUCCESS ? "saved" : null}
                    >
                      {state === formStates.INPUT ? "APPROVE" : null}
                    </Button>
                  )}
                </Flexbox>
              </Flexbox>
            </Form>
          );
        }}
      </Formik>
        {
          ((reviewReady || completed) && getHomeowerLog?.length>0) &&
          <Flexbox flexGrow={1}
                  flexDirection={'column'} paddingLeft={'10px'} paddingRight={'10px'} width={'100%'} height={'100%'}
                    className={styles.fullscreenImageContainer}
                  >
          <Divider className={styles.fullWidthInput} />
                    
          <Flexbox justifyContent={'flex-start'} alignContent={'center'} width={'100%'} style={{marginTop: 22, marginBottom:22}}>
             The following changes were made to this application after signatures were received.  
          </Flexbox>
          {
            getHomeowerLog.map(logElement => (
              <Flexbox justifyContent={'flex-start'}  flexDirection={'column'} alignContent={'center'} width={'100%'} style={{padding:15,border:"1px solid black" }}>
              <Flexbox>Timestamp: { moment(logElement.timestamp).format("MM/DD/YYYY HH:mm:ss")}</Flexbox>
              <Flexbox>Changes Made By: {logElement.changes_made_by}</Flexbox>
              <Flexbox>Updated: {logElement.updated}</Flexbox>
              <Flexbox>Approved By: {logElement.approved_by}</Flexbox>
              <Flexbox>Attachment: 
                            <a
                                 href={logFileDownload}
                                // download={logElement.attachment}
                                target="_blank"
                                onClick={async () => {
                                  if (!logElement.attachment) return;
              
                                  const encodedString = (
                                    await axios.get(logElement.getFileUrl)
                                  ).data;
                                  const base = encodedString.split(",")[1];
                                  const type = encodedString.split(";")[0].split("/")[1];

                                  const fileType = logElement.file_type;
                                  const extension = mime.extension(fileType);
              
                                  const byteString = atob(base);
              
                                  const ab = new ArrayBuffer(byteString.length);
                                  const ia = new Uint8Array(ab);
              
                                  for (let i = 0; i < byteString.length; i++) {
                                    ia[i] = byteString.charCodeAt(i);
                                  }
              
                                  let filename;
              
                                  let downloadExtension = logElement.attachment.slice(
                                    ((logElement.attachment.lastIndexOf(".") - 1) >>> 0) +
                                      2
                                  );
              
                                  let projectName = `_${project.home.address.streetAddress}.${downloadExtension}`;
              
                                  let downloadName = logElement.attachment
                                    .replace("C_", " ")
                                    .trim()
                                    .replace(`.${downloadExtension}`, projectName);
              
                                  if (logElement.attachment.includes(`C_`)) {
                                    filename = logElement.attachment
                                      ? downloadName
                                      : `${logElement.id}.${extension}`;
                                  } else {
                                    filename =logElement.attachment
                                      ? logElement.attachment
                                      : `${logElement.id}.${extension}`;
                                  }
              
                                  const file = new File([ab], filename, { type: type });
                                  FileSaver.saveAs(file);
                                }}
                              >
                                {logElement.attachment}
                              </a>
             
              </Flexbox>
              </Flexbox>
            ))
          }
          </Flexbox>

        }
    </div>
  );
}
