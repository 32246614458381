// @flow

import React, { useState, useEffect,useContext,useCallback } from "react";
import { Table, SelectionModes, TableLoadingOption, Column } from "@blueprintjs/table";
import Task, { PriceTypeEnum } from "../../models/Task/Task";
import { TextSortableColumn } from "../../components/Table/TextSortableColumn/TextSortableColumn";
import { Utils } from "@blueprintjs/table/lib/esnext/common/utils";
import TaskGroup from "../../models/TaskGroup/TaskGroup";
import { AuthRoles } from "../../blocs/authBloc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import history from "../../history";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

import {number} from "yup";

import {
  Divider,
  Button,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
  H5,
  Classes,
  InputGroup,
  Icon,
  Alert
} from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import Pagination from "../Pagination/Pagination";
import { GetPaginatedData, handleTaskSearch } from "../../config/Utils";
import Axios from "axios";
import MultiSelectTaskGroup from "../MultiSelectTaskGroup/MultiSelectTaskGroup";
import { apiAxios } from "../../config/AxiosConfig";
import BlocRegistry from "../../blocs/registry";
import { AppToaster } from "../Toaster/Toaster";

type Props = { tasks: Array<Task>, 
  taskGroups: Array<TaskGroup>,
  onGetTaskList: () => any
};

export default function TaskTable(props: Props) {
  const { tasks, taskGroups } = props;
  const [isPopverOpen, setPopoverOpen] = useState(false);
  const [page_size, setPageSize] = useState(50);
  const [currentPage, setPage] = useState(1);
  const { authBloc } = useContext(BlocRegistry);

  const [searchedTask, setSearchedTask] = useState(null);
  const [searching, setSearching] = useState(false);
  const originalTasks = props.tasks;

  const firstTaskList = GetPaginatedData(originalTasks, 1);
  
  const [taskList, setTaskList] = useState(originalTasks);
  // const [showApplyChangesPopup, setShowApplyChangesPopup] = useState(false);
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);

  const [incDecDdl, setIncDecDdl] = useState("+");
  const [percentage, setPercentage] = useState("");
  const [amount, setAmount] = useState("");
  const [taskListName, setTaskListName] = useState("");
  const [revisionDate, setRevisionDate] = useState("");
  const [errortasklist, setErrorTaskList] = useState("");
  const [errorRevisionDate, setErrorRevisionDate] = useState("");
  const [taskGroupDdl, setTaskGroupDdl] = useState(null);
  const [projectGroupDdl, setProjectGroupDdl] = useState(null);
  // const [getTaskListName, setGetTaskListName] = useState();
  const [getTaskListId, setGetTaskListId] = useState(null);
  const [taskGroupsSelect, setTaskGroupsSelect] = useState(null);
  const [taskGroupIds, SetTaskGroupIds] = useState(null);
  const [newTaskGroupIds, SetNewTaskGroupIds] = useState([]);
  const [loading, setLoading ] = useState(false);
  const [loadingApplyBtn, setLoadingApplyBtn ] = useState(false);
  const [errorAmount, setErrorAmount ] = useState(true);
  const [errorPercentage, setErrorPercentage ] = useState(true);
  const [selectNewId, setSelectNewId ] = useState(null);


  
  const tasksGroupsIdToNameMap = taskGroups.reduce((map, group) => {
    map.set(group.id, group.title);
    return map;
  }, new Map());

  
  const [columns, setColumns] = useState([
    new TextSortableColumn("Controls", 0, "action"),
    new TextSortableColumn("Title", 1, "title"),
    new TextSortableColumn("Description", 2, "description", undefined, {
      truncate: false,
      wrapText: true
    }),
    new TextSortableColumn("Unit", 3, "unit"),
    new TextSortableColumn(
      "Price",
      4,
      "price",
      createComparetorForStringsAndNumbers(taskList)
    ),
    new TextSortableColumn("Group", 5, "taskGroupId")
  ]);

  const [sortedIndexMap, setSortedIndexMap] = useState([]);

  const setCurrentTasks = async (initialPage = false) => {
    const filteredTasks = GetPaginatedData(
      //originalTasks,
      searching ? searchedTask : originalTasks,
      // searching ? 1 : initialPage ? 1 : currentPage,
      initialPage ? 1 : currentPage,
      page_size 
    );
   await setTaskList(filteredTasks);
  };
  useEffect(() => {
    let data = GetPaginatedData(props.tasks, 1,page_size);
    setTaskList(data);
    return () => {
      setLoading(false)
      setLoadingApplyBtn(false)
      setAmount(null)
      setTaskGroupsSelect(null)
      setPercentage(null)
      SetTaskGroupIds(null)
      setShowDuplicatePopup(false);
      props.setNextbtnPopup(false)
      // subscription.unsubscribe();
    };
  }, [props.tasks]);
  const paginatedData =async()=>{
    let data = GetPaginatedData(originalTasks, currentPage || 1,page_size);
    await setTaskList(data);
  }
  useEffect(() => {
    setCurrentTasks();
    return () => {
      // subscription.unsubscribe();
    };
  }, [page_size]);

  useEffect(() => {
    setCurrentTasks();
    return () => {
      // subscription.unsubscribe();
    };
  }, [currentPage]);
  useEffect(() => {
    setCurrentTasks(true);
    return () => {
      // subscription.unsubscribe();
    };
  }, [searchedTask]);

  const handleTaskGroupFilter = value => {
    if (value === "select") {
      return originalTasks;
    }

    let searchResult = originalTasks.filter(task => {
      
      let groupTitle = task.taskGroup.title;
      
      let res = groupTitle.includes(value);
      
      return res;
    });
    return searchResult;
  };

  const onEditTask = task => {
    history.push(`/app/update-task/${task.id}`);
  };

  const titleCellRenderer = (rowIndex: number) => {
    return taskList[rowIndex].title;
  };

  const descriptionCellRenderer = (rowIndex: number) => {
    return taskList[rowIndex].description;
  };

  const unitCellRenderer = (rowIndex: number) => {
    return taskList[rowIndex].unit;
  };

  const priceCellRenderer = (rowIndex: number) => {
    let priceUnit;

    switch (taskList[rowIndex].priceType) {
      case "USD": {
        priceUnit = "$";
        break;
      }
      default: {
        priceUnit = "";
      }
    }

    return `${priceUnit}${taskList[rowIndex].price}`;
  };

  const taskGroupCellRenderer = (rowIndex: number) => {
    return tasksGroupsIdToNameMap.get(taskList[rowIndex].taskGroupId);
  };

  const actionRenderer = (rowIndex: number) => {
    return (
      <Menu className={Classes.ELEVATION_2}>
        <MenuItem
          text="Edit"
          icon={"edit"}
          disabled={tasks[rowIndex].is_master}
          intent={Intent.PRIMARY}
          onClick={() => onEditTask(taskList[rowIndex])}
        />
        <MenuItem
          onClick={() => props.onTaskRemoveFromList(taskList[rowIndex].id)}
          text="Delete"
          disabled={tasks[rowIndex].is_master}
          icon={"trash"}
          intent={Intent.DANGER}
        />
      </Menu>
    );
  };

  const cellRenderers = {
    action: actionRenderer,
    title: titleCellRenderer,
    description: descriptionCellRenderer,
    unit: unitCellRenderer,
    price: priceCellRenderer,
    taskGroupId: taskGroupCellRenderer
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    const sortedRowIndex = sortedIndexMap[rowIndex];
    if (sortedRowIndex != null) {
      rowIndex = sortedRowIndex;
    }
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };

  const sortColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(taskList.length, (i: number) => i);
    sortedIndexMap.sort((a: number, b: number) => {
      return comparator(
        taskList[a][columns[columnIndex].modelField],
        taskList[b][columns[columnIndex].modelField],
        a,
        b
      );
    });
    setSortedIndexMap(sortedIndexMap);
  };

  const sortGroupColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(taskList.length, (i: number) => i);
    sortedIndexMap.sort((a: number, b: number) => {
      return comparator(
        tasksGroupsIdToNameMap.get(
          taskList[a][columns[columnIndex].modelField]
        ),
        tasksGroupsIdToNameMap.get(
          taskList[b][columns[columnIndex].modelField]
        ),
        a,
        b
      );
    });
    setSortedIndexMap(sortedIndexMap);
  };

  const renderedColumns = columns.map(column => {
    const field = column.modelField;
    switch (field) {
      case "taskGroupId": {
        return column.getColumn(getCellData, sortGroupColumn);
      }
      default: {
        return column.getColumn(getCellData, sortColumn);
      }
    }
  });

  let popoverContent = (
    <div key="text">
      <H5>Confirm deletion</H5>
      <p>
        Are you sure you want to delete these items? You won't be able to
        recover them.
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 15
        }}
      >
        <Button
          onClick={() => setPopoverOpen(false)}
          className={Classes.POPOVER_DISMISS}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => setPopoverOpen(false)}
          intent={Intent.DANGER}
          className={Classes.POPOVER_DISMISS}
        >
          Delete
        </Button>
      </div>
    </div>
  );
  const buildHeaders = () => {
    const accessToken = authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
   };

  

  

 
const onNextClick =async()=>{
  if (taskListName && revisionDate) {
    const tenantId = authBloc.getCurrentTenant();
    setLoading(true)
    const response = await apiAxios.post(`/tenants/${tenantId}/duplicate`,
    {
      listName:taskListName,
      revisionDate,
      baseTaskListId :getTaskListId || props.latestTaskListId
    }, 
    buildHeaders());

    if(response.status===201){
      setLoading(false)
      await setSelectNewId(response.data.data[1].newTaskListId)
      await SetNewTaskGroupIds(response.data.data[0])
      props.setNextbtnPopup(true)
    

  }
 if(response.status >= 400){
    setLoading(false)
    AppToaster.show({
      message:"New Task List cannot be edited",
      intent: Intent.DANGER,
    });

  }
}else if(!taskListName && !revisionDate) {
  setErrorRevisionDate("Required");
  setErrorTaskList("Required")
} else if(!taskListName) {
  setErrorTaskList("Required")
}
else if(!revisionDate) {
  setErrorRevisionDate("Required");
}

}

const onApplyChangesClick =async()=>{

  const type= amount?"amount":percentage?"percent":""
          let priceOffset = amount || percentage
          const tenantId = authBloc.getCurrentTenant();
          let taskGroupsId=[];

          if(taskGroupIds){
            taskGroupsId=taskGroupIds
          }else if(priceOffset){
            if(taskGroupsSelect){
              taskGroupsId.push(taskGroupsSelect.value)
            }else{
            newTaskGroupIds.map(t=>{
              taskGroupsId.push(t.id)
            }) 
          }
           // taskGroupsId=taskGroupsId
          }
          setLoadingApplyBtn(true)
          const response = await apiAxios.patch(`/tenants/${tenantId}/update`,
          {
           
            type:type,
            priceOffset: priceOffset? incDecDdl+priceOffset :"", 
            taskGroupIds:taskGroupsId,
            taskListId:selectNewId
          }, 
          buildHeaders());
          if(response.status===200){
            setLoading(false)
            setAmount(null)
            setTaskGroupsSelect(null)
            setPercentage(null)
            SetTaskGroupIds(null)
            setShowDuplicatePopup(false);
            props.setNextbtnPopup(false)
            AppToaster.show({
              message:"New Task List is successfully created.",
              intent: Intent.PRIMARY,
            });
            props.refeshTaskList(true)
          }
          else{
            setLoadingApplyBtn(false)
            AppToaster.show({
              message:"New Task List cannot be edited",
              intent: Intent.DANGER,
            });

          }
}
  let priceListTable = () => {
    const Option = props => {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            <label>{props.label}</label>
          </components.Option>
        </div>
      );
    };
    
    const MultiValue = props => (
      <components.MultiValue {...props}>
        <span>{props.data.label}</span>
      </components.MultiValue>
    );
    
    const animatedComponents = makeAnimated();
    
    const handleChange = (selected) =>{
      setTaskGroupsSelect(selected)
      SetTaskGroupIds(selected.map(s=>s.value))
    } 
    const options =[]
    newTaskGroupIds.map(t=>{
      options.push({value:t.id,label:t.title})
    }) 
    
    return (
      <Flexbox flexDirection={"column"}>
        <Flexbox
          justifyContent={"center"}
          alignContent={"center"}
          width={"100%"}
          style={{ paddingLeft: "50px", padding: "0px" }}
        >
             <Flexbox flexDirection={"column"}  paddingRight={"10px"}>
          <Flexbox>
            <b>
              <label style={{ fontSize: "14px" }} htmlFor={"search"}>
                {"Task List Name"}
              </label>
            </b>
          </Flexbox>

          <Flexbox>
            <InputGroup
            name="inputTasklistName"
              style={{ width: "350px" }}
              //  placeholder="Amount(US$)"
              type="text"
              disabled={ props.showNextbtnPopup}
              onChange={e => {
                setTaskListName(e.target.value);
                setErrorTaskList("")
              }}
            />
          </Flexbox>
          <Flexbox  >
      <Flexbox style={{ color: "red", fontSize: "14px" }}>{errortasklist}</Flexbox>
         
        </Flexbox>
          </Flexbox>
          <Flexbox flexDirection={"column"}  paddingRight={"10px"}>
          <Flexbox >
            <b>
              <label style={{ fontSize: "14px" }} htmlFor={"search"}>
                {"Revision Date"}
              </label>
            </b>
          </Flexbox>
          <InputGroup
          name="inputRevisionDate"
            type="date"
            disabled={ props.showNextbtnPopup}
            onChange={e => {
              setRevisionDate(moment(e.target.value).format("YYYY-MM-DD"));
              setErrorRevisionDate("")
            }}
          />
           <Flexbox >
      <Flexbox style={{ color: "red", fontSize: "14px" }}>{errorRevisionDate}</Flexbox>
         
        </Flexbox>
          </Flexbox>
          <Flexbox height={"100%"} paddingTop={"30px"} paddingLeft={"10px"}>
            <Button
              text={"Next"}
              rightIcon={"arrow-right"}
              loading={loading}
              disabled={ props.showNextbtnPopup}
              onClick={() => {
                onNextClick()
              }}
            />
          </Flexbox>
        </Flexbox>
      {
        props.showNextbtnPopup &&
       <Flexbox
      paddingTop={"20px"} justifyContent={"center"} width={"100%"} style={{color:"red",fontSize:"14px"}}
      >Click Apply Changes button to complete the Task List Duplication Process, else System will discard the Task List automatically. </Flexbox>}

      { 
       props.showNextbtnPopup &&
        <Flexbox paddingTop={"20px"} justifyContent={"center"} width={"100%"}>
          {/* <Flexbox
            style={{
              fontSize: "18px",
              paddingRight: "10px",
              paddingTop: "35px",

              alignContent: "center"
            }}
          >
            All records
          </Flexbox> */}
          <Flexbox flexDirection={"column"}  paddingRight={"10px"}>
          <Flexbox>
              <b>
                <label style={{ fontSize: "14px" }} htmlFor={"search"}>
                  {"Task Groups"}
                </label>
              </b>
            </Flexbox>
            <MultiSelectTaskGroup
              options={options}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={handleChange}
              allowSelectAll={true}
              value={taskGroupsSelect}
            />
        
           </Flexbox>
          <Flexbox>
            <Flexbox paddingTop={"27px"} paddingRight={"10px"}>
              <div class="bp3-select .modifier">
                <select
                  onChange={e => {
                    setIncDecDdl(e.target.value);
                  }}
                >
                  <option value={"+"} selected>
                    Increase by
                  </option>
                  <option value={"-"}>Decrease by</option>
                </select>
              </div>
            </Flexbox>
          </Flexbox>
          <Flexbox flexWrap={"wrap"} flexDirection={"column"} paddingRight="10px">
            <Flexbox>
              <b>
                <label style={{ fontSize: "14px" }} htmlFor={"search"}>
                  {"Percentage"}
                </label>
              </b>
            </Flexbox>
            <Flexbox>
              <InputGroup
                //  placeholder="Percentage"
                type="number"
                min={0}
                value={percentage}
                onChange={async (e) => {
               let value= e.target.value
                  await setPercentage(value);
                 await setAmount("")
                  await setErrorAmount(true)
                  setErrorPercentage(
                  number()
                 
                  .positive()
                  .isValidSync(value)
                 )
                }}
              />
            </Flexbox>
            <Flexbox flexWrap={"wrap"} style={{fontSize:11,color:"red"}}>{errorPercentage ? "":"Percentage must be greater than  or equal to 1"}</Flexbox>
          </Flexbox>
          <Flexbox flexDirection={"column"}>
            <Flexbox>
              <b>
                <label style={{ fontSize: "14px" }} htmlFor={"search"}>
                  {"Amount (US$)"}
                </label>
              </b>
            </Flexbox>
            <Flexbox>
              <InputGroup
                //  placeholder="Amount(US$)"
                type="number"
                min={0}
                value={amount}
                onChange={async (e ) => {
                  let value= e.target.value
                 await setAmount(value);
                 await setPercentage("")
                 await setErrorPercentage(true)
                 setErrorAmount(
                  number()
                 
                  .positive()
                  .isValidSync(value)
                 )
                }}

              />
            </Flexbox>
            <Flexbox style={{fontSize:11,color:"red"}}>{errorAmount ? "":"Amount must be greater than or equal to 1"}</Flexbox>

          </Flexbox>
          <Flexbox height={"100%"} paddingTop={"30px"} paddingLeft={"10px"}>
            <Button
              text={"Apply Changes"}
              icon={"tick"}
              loading={!taskGroups}
              onClick={() => {
                onApplyChangesClick()
              }}
              // minimal
            />
          </Flexbox>
        </Flexbox>
      }
      </Flexbox>
    );
  };
  const getLoadingOptions=()=> {
    const loadingOptions: TableLoadingOption[] = [];
    if (props.loader) {
        loadingOptions.push(TableLoadingOption.CELLS);
        loadingOptions.push(TableLoadingOption.ROW_HEADERS);
    }
    
    return loadingOptions;
  }
  return (
    <>
      <Popover
        interactionKind={PopoverInteractionKind.CLICK}
        popoverClassName="bp3-popover-content-sizing"
        position={Position.RIGHT}
        content={popoverContent}
        isOpen={isPopverOpen}
      >
        <></>
      </Popover>

      <Flexbox flex={1} flexDirection={"row"} paddingBottom={"20px"}>
        <Flexbox paddingRight={"20px"}>
          {tasks && (
            <Flexbox flex={1} flexDirection={"column"}>
              <Flexbox flex={1}>
                <b>
                  <label style={{ fontSize: "14px" }} htmlFor={"group"}>
                    {"Task List Name & Revision Date"}
                  </label>
                </b>
              </Flexbox>
              <Flexbox flex={1}>
                <div class="bp3-select .modifier">
                  <select
                    style={{ width: "300px" }}
                    disabled={props.showNextbtnPopup}
                    onChange={(e)=>{
                    
                      setGetTaskListId(e.target.value)
                      props.onListChange(e.target.value)
                      document.getElementById("search").value=""
                      document.getElementById("taskGroupSearch").value="select"
                    }}
                  >
                   

                        {props.getTaskListName.map((n, index) => {
                        return (
                          <option selected={props.latestTaskListId==n.id} value={n.id}>
                            {n.list_name} {moment(n.revision_date).format("MM/DD/YYYY")}
                          </option>
                        )})}


                        
                  </select>
                </div>
              </Flexbox>
            </Flexbox>
          )}
        </Flexbox>
        <Flexbox flexDirection={"column"} height={"100%"} paddingRight={"20px"}>
          <Flexbox flex={1}>
            <br />
          </Flexbox>
          <Flexbox flex={1}>
            {props.currentUserRole == AuthRoles.ADMIN ?
            <Button
              text={"Duplicate"}
              icon={"duplicate"}
              disabled={props.showNextbtnPopup || props.getTaskListName?.length >= 2}
              loading={!taskGroups}
              onClick={() => setShowDuplicatePopup(!showDuplicatePopup)}
              // minimal
              //  intent={Intent.SUCCESS}
            /> : null}
          </Flexbox>
        </Flexbox>
        {!showDuplicatePopup && (
          <Flexbox>
            <Flexbox flexDirection={"column"}>
              <Flexbox flex={1}>
                <b>
                  <label style={{ fontSize: "14px" }} htmlFor={"search"}>
                    {"Search"}
                  </label>
                </b>
              </Flexbox>
              <Flexbox flex={1}>
                <InputGroup
                id="search"
                  placeholder="Search..."
                  leftIcon={<Icon icon="search" intent={Intent.PRIMARY} />}
                  onChange={e => {
                    setSearching(e.target.value === "" ? false : true);
                    const serachedTask1 = handleTaskSearch(
                      e,
                      // firstProjectsList,
                      originalTasks
                    );
                    setSearchedTask(serachedTask1);
                    // setCurrentTasks(true);
                  }}
                />
              </Flexbox>
            </Flexbox>
            <Flexbox paddingLeft={"20px"}>
              {taskGroups && (
                <Flexbox flex={1} flexDirection={"column"}>
                  <Flexbox flex={1}>
                    <b>
                      <label style={{ fontSize: "14px" }} htmlFor={"group"}>
                        {"Task Groups"}
                      </label>
                    </b>
                  </Flexbox>
                  <Flexbox flex={1}>
                    <div class="bp3-select .modifier">
                      <select
                      id="taskGroupSearch"
                        onChange={e => {
                          setSearching(true);
                          const filteredTask = handleTaskGroupFilter(
                            e.target.value
                          );
                        
                          setSearchedTask(filteredTask);
                        }}
                      >
                        <option value="select" selected>
                          -Select-
                        </option>

                        {taskGroups.map((group, index) => {
                          return (
                            <option value={group.title}>{group.title}</option>
                          );
                        })}
                      </select>
                    </div>
                  </Flexbox>
                </Flexbox>
              )}
            </Flexbox>
          </Flexbox>
        )}
      </Flexbox>
    
      {/* style={{height: "75vh", width: "98vw"}} */}
      {!showDuplicatePopup && (
        <>
          <div>
            {taskList.length != 0 ? (
              props.loader ?
              <Table
                    numRows={10}
                    loadingOptions={[
                      TableLoadingOption.CELLS,
                      TableLoadingOption.ROW_HEADERS,
                    
                    ]}
                  >
                    <Column name="Title" />
                    <Column name="Description" />
                    <Column name="Unit" />
                    <Column name="Price" />
                  </Table>
              :
              <Table
                key={taskList.length}
                loadingOptions={getLoadingOptions()}
                wrapContentsByDefault={true}
                defaultRowHeight={100}
                columnWidths={[, 230, 450, 90, 90, 100]}
             //  selectionModes={SelectionModes.COLUMNS_AND_CELLS}
                numRows={taskList.length}
              >
                { renderedColumns}
              </Table>
            ) : (
              <div className={`bp3-ui-text bp3-text-disabled`}>
                Task Not Found
              </div>
            )}

            <Flexbox justifyContent={"center"} style={{ paddingTop: "20px" }}>
              {/* <Flexbox flexDirection={"column"} paddingRight={"50px"}>
                {(searching ? searchedTask.length : originalTasks.length) >
                  page_size && (
                  <Flexbox flex={1}>
                    <div class="bp3-select .modifier">
                      <select
                      selected={page_size}
                        onChange={async e => {
                          await setPageSize(e.target.value);
                        }}
                      >
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                  </Flexbox>
                )}
              </Flexbox> */}
              <Flexbox paddingTop={"5px"}>
                <Pagination
                  initialPage={1}
                  size={page_size}
                  onSizeChange={(size) => {
                    setPageSize(size);
                  }}
                  isSearching={searching}
                  total={searching ? searchedTask.length :originalTasks.length}
                  // total={originalTasks.length}
                  onPageChange={page => {
                    setPage(page);
                  }}
                />
              </Flexbox>
            </Flexbox>
          </div>
        </>
      )}
      {showDuplicatePopup && priceListTable()}
      {/* {
        
        showApplyChangesPopup && ApplyChangesPopup
        
      } */}
    </>
  );
}

const createComparetorForStringsAndNumbers = tasks => {
  return (a, b, indexA, indexB) => {
    const entityA = tasks[indexA];
    const entityB = tasks[indexB];

    const aPrice =
      entityA.priceType === PriceTypeEnum.PER_BID
        ? entityA.price
        : parseFloat(entityA.price);
    const bPrice =
      entityB.priceType === PriceTypeEnum.PER_BID
        ? entityB.price
        : parseFloat(entityB.price);

    if (typeof aPrice === "string" && typeof bPrice === "string")
      return aPrice.localeCompare(bPrice);
    if (aPrice < bPrice) return -1;
    if (aPrice > bPrice) return 1;
    else if (aPrice === bPrice) return 0;
    else return -1;
  };
};
