// @flow

import React, { useState } from "react";
import { Table, SelectionModes, Column } from "@blueprintjs/table";
import ProjectGroup, { UpdateProjectGroupRequest, ProjectGroupStatusTypeEnum } from "../../models/ProjectGroup/ProjectGroup";
import { TextSortableColumn } from "../../components/Table/TextSortableColumn/TextSortableColumn";
import { Utils } from "@blueprintjs/table/lib/esnext/common/utils";
import {
  Button,
  Menu,
  MenuItem,
  Intent,
  Classes,
  Popover,
  InputGroup,
  Position
} from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import history from "../../history";
import DeleteConfirmationPopup from "../ConfirmationAlert/ConfirmationAlert";
import { AppToaster } from "../Toaster/Toaster";
import { Fragment } from "react";


type Props = {
  projectGroups: Array<ProjectGroup>,
  onProjectGroupDetailsButtonClick: (projectGroupId: string) => any,
  onEditProjectGroupButtonClick: () => any,
  onDeleteProjectGroupButtonClick: (projectGroupId: string) => any,
  onExportProjectGroupButtonClick: (projectGroupId: string) => any,
  onEditProjectGroupMenuClick: (projectGroupId: string) => any,
  onCloseProjectGroupMenuClick: (projectGroupId: string) => any,
  isComplainceAuditor: ?Boolean,
  isMarketingRole: ?Boolean
};

export default function ProjectGroupGroupsTable(props: Props) {
  const { projectGroups, onEditProjectGroupMenuClick, onCloseProjectGroupMenuClick, isComplainceAuditor, isMarketingRole } = props;

  const [columns, setColumns] = useState([
    new TextSortableColumn("Controls", 0, "controls", () => {
      return 0;
    }),
    new TextSortableColumn("Name", 1, "name"),
    new TextSortableColumn("In Process", 2, "inProcess"),
    new TextSortableColumn("Completed", 3, "completed"),
    new TextSortableColumn("Total", 4, "total"),
    new TextSortableColumn("Budget", 5, "budget"),
    new TextSortableColumn("Actual", 6, "actual"),
    new TextSortableColumn("Status", 7, "status")
  ]);

  const [sortedIndexMap, setSortedIndexMap] = useState([]);
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);


  const onEditProjectGroup = async (projectGroupId: string) => {

    onEditProjectGroupMenuClick(projectGroupId);

    setTimeout(() => {
      history.push(`/app/update-project-group/${projectGroupId}`);
    }, 300)
  }

  const onCloseProjectGroup = async (projectGroupData: any) => {

    if (projectGroupData) {
      const editProjectObj = {
        id: projectGroupData.id,
        name: projectGroupData.name.trim(),
        status: ProjectGroupStatusTypeEnum.CLOSED,
        closeProjects: true
      }
      props.onEditProjectGroupButtonClick(editProjectObj);
    }
    else {
      AppToaster.show({
        message: "Try Again!",
        intent: Intent.DANGER
      });
    }


  }


  const nameRenderer = (rowIndex: number) => {
    return projectGroups[rowIndex].name;
  };

  const inProcessRenderer = (rowIndex: number) => {
    return projectGroups[rowIndex].inProcess;
  }

  const completedRenderer = (rowIndex: number) => {
    return projectGroups[rowIndex].completed;
  }

  const totalRenderer = (rowIndex: number) => {
    return projectGroups[rowIndex].total;
  }

  const budgetRenderer = (rowIndex: number) => {
    return `$ ${projectGroups[rowIndex].budget}`;
  }

  const actualRenderer = (rowIndex: number) => {
    return `$ ${projectGroups[rowIndex].actual}`;
  }

  const statusRenderer = (rowIndex: number) => {
    return projectGroups[rowIndex].status;
  }

  const controlsRenderer = (rowIndex: number) => {
    return (
      <>
        <Menu className={Classes.ELEVATION_2}>
          <MenuItem
            text="Export Report"
            icon={"import"}
            intent={Intent.SUCCESS}
            onClick={() => props.onExportProjectGroupButtonClick(projectGroups[rowIndex].id, projectGroups[rowIndex].name)}
          />
          {!isComplainceAuditor && !isMarketingRole &&
            <Fragment>
              <MenuItem
                text="Edit"
                icon={"edit"}
                intent={Intent.PRIMARY}
                onClick={() => {
                  // setCurrentGroup(projectGroups[rowIndex].id);
                  // setCreateGroupPopoverOpen(true);
                  // setNewGroupName(projectGroups[rowIndex].name)
                  onEditProjectGroup(projectGroups[rowIndex].id);
                }}
              />

              <MenuItem
                text="Delete"
                icon={"trash"}
                intent={Intent.DANGER}
                onClick={() =>
                  props.onDeleteProjectGroupButtonClick(
                    projectGroups[rowIndex].id
                  )
                }
              />

              <MenuItem
                text="Close Group"
                icon={"delete"}
                intent={Intent.WARNING}
                onClick={() => {
                  setCurrentGroup(projectGroups[rowIndex]);
                  setConfirmationPopup(true);
                }}
              />
            </Fragment>
          }
        </Menu>
      </>
    );
  };

  const cellRenderers = {
    name: nameRenderer,
    controls: controlsRenderer,
    inProcess: inProcessRenderer,
    completed: completedRenderer,
    total: totalRenderer,
    budget: budgetRenderer,
    actual: actualRenderer,
    status: statusRenderer
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    const sortedRowIndex = sortedIndexMap[rowIndex];
    if (sortedRowIndex != null) {
      rowIndex = sortedRowIndex;
    }
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };

  const sortColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(projectGroups.length, (i: number) => i);
    sortedIndexMap.sort((a: number, b: number) => {
      let fieldA;
      let fieldB;

      fieldA = projectGroups[a][columns[columnIndex].modelField];
      fieldB = projectGroups[b][columns[columnIndex].modelField];

      return comparator(fieldA, fieldB, a, b);
    });
    setSortedIndexMap(sortedIndexMap);
  };

  const renderedColumns = columns.map(column => {
    const field = column.modelField;
    return column.getColumn(getCellData, sortColumn);
  });

  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="No"
      confirmButtonText="Yes"
      icon="delete"
      type={Intent.WARNING}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={async () => {
        // await onDeleteProjectGroupButtonClick(projectGroupId);
        onCloseProjectGroup(currentGroup);
        setConfirmationPopup(false);
      }}
      message={
        <div>
          <h3>Total Projects: {currentGroup && currentGroup.total}</h3>
          <h5>Are you sure you want to Close this Project Group?</h5>
        </div>
      }
    />
  );


  return (
    <>
      <Table
        defaultRowHeight={134}
        selectionModes={SelectionModes.COLUMNS_AND_CELLS}
        numRows={projectGroups.length}
      >
        {renderedColumns}
      </Table>
      {confirmPopup}
    </>
  );
}
