// @flow

import type Attachment, {AttachmentTypes, CreateAttachmentRequest} from "../../models/Attachment/Attachment";
import type AttachmentApi from "../../api/AttachmentApi/AttachmentApi";
import AttachmentStorageApi from "../../api/AttachmentApi/AttachmentStorageApi";
import {AttachmentTypeEnum, GetAttachmentResponse, PostAttachmentResponse} from "../../models/Attachment/Attachment";
import { AppToaster } from "../../components/Toaster/Toaster";
import { Intent } from "@blueprintjs/core";

class AttachmentService {

    attachmentApi: AttachmentApi;
    attachmentStorageApi: AttachmentStorageApi;

    constructor(attachmentApi: AttachmentApi,attachmentStorageApi: AttachmentStorageApi) {
        this.attachmentApi = attachmentApi;
        this.attachmentStorageApi = attachmentStorageApi;
    }

    async getAttachmentsForProject(projectId: string, attachmentType: AttachmentTypes): Promise<Array<GetAttachmentResponse>> {
        return await this.attachmentApi.getAttachmentsForProject(projectId, attachmentType);
    }

    async createAttachment(attachmentType: AttachmentTypeEnum, createAttachmentRequest: CreateAttachmentRequest): Promise<PostAttachmentResponse> {
        const attachment = await this.attachmentApi.createAttachment(attachmentType, createAttachmentRequest);
        if(createAttachmentRequest.fileType !== "link"){
        let response = await this.attachmentStorageApi.putAttachmentPayload(attachment.putUrl, createAttachmentRequest, attachmentType);
                if(!response || response.status !== 200){
                    AppToaster.show({
                        message: "Upload Failed. Please Try Again!",
                        intent: Intent.DANGER,
                    });
                    await this.attachmentApi.deleteAttachment(attachment.projectId, attachment.attachmentType, attachment.id);
                }
        }
        
        return attachment;
    }

    async deleteAttachment(projectId: string, attachmentId: string, attachmentType: AttachmentTypes): Promise<void> {
        await this.attachmentApi.deleteAttachment(projectId, attachmentType, attachmentId);
    }
}

export default AttachmentService;
