import React, { useContext, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField, Button, Container, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./ManageNotesView.module.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  outline: 'none'
};

export default function ViewNotesDetail({ open, projectId, isView, currentNote, handleClose, src }) {
  const [image, setImage] = useState(null);
  //const src = currentNote?.notesAttachments[0]?.getUrl;

  useEffect(() => {
    if (src) {
      const newImage = new Image();
      newImage.onload = () => {
        setImage(newImage);
      };
      newImage.onerror = () => {
        newImage.src = "/errorMessage.png";
      };
      newImage.src = src;
    }
  }, [src]);

  // useEffect(() => {
  //   const getImageData = async () => {
  //     if (src) {
  //       const encodedString = (await axios.get(src)).data;
  //       //setWncodedFileData(encodedString);
  //      // if (props.fileType == "image/jpeg") {
  //         const newImage = new Image();

  //         newImage.onload = () => {
  //           setImage(newImage);
  //         };
  //         newImage.onerror = () => {
  //           newImage.src = "/errorMessage.png";
  //         };
  //         newImage.src = encodedString;
  //       //}
  //     }
  //   };
  //   getImageData();

  // }, [src]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <React.Fragment>
            <h2>Notes Details:</h2>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Stack spacing={2}>
              <h3>Title :</h3>
              <p>{currentNote?.title}</p>
              <h3>Description :</h3>
              <p>{currentNote?.description}</p>
              <h3>Attachments :</h3>
              <img src={image?.src} />
              {/* <LinkPreview url={currentNote?.notesAttachments[0]?.getUrl} width="300px" /> */}
              <Button className={styles.submitBtn} onClick={handleClose} size="small" variant="outlined" color="secondary" type="button">Back</Button>
            </Stack>
          </React.Fragment>
        </Box>
      </Modal>
    </div>
  );
}