//@flow

import styles from './ProjectPhotosGrid.module.css';
import React, { useState, useEffect } from "react";
import Flexbox from "flexbox-react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Button, Card, InputGroup, Intent, Overlay, Popover, Spinner, Tag, TextArea, FileInput, H5, H6, Checkbox, ButtonGroup } from "@blueprintjs/core";
import { AttachmentTypeEnum, CreateAttachmentRequest, GetAttachmentResponse } from "../../models/Attachment/Attachment";
import FullscreenCamera from "../FullscreenCamera/FullscreenCamera";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from 'file-saver';
import { Base64 } from 'js-base64';
import axios from "axios";
import mime from "mime-types";
import { DeleteAttachmentForProjectEvent } from "../../blocs/projectAttachmentBloc";
import PhotoOptionOverlay from '../PhotoOptionOverlay/PhotoOptionOverlay';
import { PhotoPreview } from '../PhotoPreview/PhotoPreview';
import { AppToaster } from '../Toaster/Toaster';
import Project from '../../models/Project/Project';
import Compressor from 'compressorjs';
import { GlobalConstanst } from '../../config/Constant';
import Dropzone from 'react-dropzone'
import { AuthRoles } from '../../blocs/authBloc';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
    project: ?Project,
    projectId: string,
    photos: ?Array<GetAttachmentResponse>,
    onPhotoTileClick: () => void,
    onAddPhoto: (request: CreateAttachmentRequest) => void,
    onDeletePhoto: (request: DeleteAttachmentForProjectEvent) => void,
    onSetProjectCoverPhoto: (request: GetAttachmentResponse) => any,
}

const generateLayout = (buttons: Array<any>) => {
    return {
        lg: buttons.map((button, index) => {
            return {
                x: index % 5,
                y: Math.floor(index / 5),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        md: buttons.map((button, index) => {
            return {
                x: index % 4,
                y: Math.floor(index / 4),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        sm: buttons.map((button, index) => {
            return {
                x: index % 2,
                y: Math.floor(index / 2),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        xs: buttons.map((button, index) => {
            return {
                x: index % 1,
                y: Math.floor(index / 1),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        xss: buttons.map((button, index) => {
            return {
                x: 0,
                y: index,
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        })
    };
};

export default function ProjectPhotosGridBoxes(props: Props) {

    const { photos, onAddPhoto, onPhotoTileClick, onDeletePhoto, onSetProjectCoverPhoto,project } = props;

    const [cameraOpen, setCameraOpen] = useState(false);
    const [dataUri, setDataUri] = useState('');

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [photoOption, setPhotoOption] = useState(false);
    const [isPreview, setPreview] = useState(false);
    const [photo, setPhoto] = useState('');
    const [photoName,setPhotoName] = useState('');
    const [isUploaded,setUploaded] = useState(false);
    const [titleError,setTitleError] = useState(false);
    const [includeInEr,setIncludeInEr] = useState(true);
    const [erTitle,setErTitle] = useState('');

    const handleAcceptImage = (dataUri) => {
        setCameraOpen(false);
        setPreview(false)
        setDataUri(dataUri);

    };

    const handleRejectImage = () => {
        setPreview(false);
        setPhotoOption(true)
    }

    const getCompressionRatio = (imageSize) => {
        let ratio = 0.4;
        if (imageSize <= GlobalConstanst.FILE_SIZE_LOW) {
          return false;
        } else if (
          imageSize > GlobalConstanst.FILE_SIZE_LOW &&
          imageSize < GlobalConstanst.FILE_SIZE_MEDIUM
        ) {
          ratio = 0.8;
        } else if (
          imageSize >= GlobalConstanst.FILE_SIZE_MEDIUM &&
          imageSize < GlobalConstanst.FILE_SIZE_MAXIMUM
        ) {
          ratio = 0.7;
        }
        return ratio;
      };

    const handlePhotoUpload = async (e, dragged = false) => {
        let image = {};
        if(dragged){
            image = e[0];
        } else {
            image = e.target.files[0];
        }
        setPhotoOption(false);
        if (!image.name.match(/\.(jpg|jpeg|png|gif|webp|tiff|psd|svg)$/)) {
            AppToaster.show({
                message: "Please select valid image",
                intent: Intent.DANGER,
              });
              setUploaded(false);
            return false;
          }

          else{
            let imageBlob = new Blob;
            let imageCompressionRatio = getCompressionRatio(image.size);
          
            if(imageCompressionRatio){
                imageBlob =  await new Promise((resolve, reject) => {
                    new Compressor(image, {
                        quality: imageCompressionRatio,
                        success: (compressedResult) => {
                            resolve(compressedResult);
                        },
                      });
                  });
            } else {
                imageBlob = image
            }
            setPhotoName(image.name);
            setUploaded(true);
            const fileString = await new Promise(resolve => {
                const reader = new FileReader();
    
                reader.onload = () => {
                    resolve(reader.result);
                };
    
                reader.onerror = err => {
                    throw err;
                };
                reader.readAsDataURL(imageBlob);
            });

       

            setPhoto(fileString);
            setPreview(true);
          }

     
    }


    const createAddImageTile = (index: number,
        onAddPhoto: (request: CreateAttachmentRequest) => void) => {

        return (
          <Flexbox
            key={index}
            height={"100%"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Flexbox
              key={index}
              height={"100%"}
              width={"100%"}
              margin={"0 auto"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Dropzone
                onDrop={(acceptedFiles) =>handlePhotoUpload(acceptedFiles, true)}
              >
                {({ getRootProps, getInputProps }) => (
                  <Card
                    {...getRootProps()}
                    className={styles.addAttachmentTile}
                    interactive
                    onClick={() => {
                      // setCameraOpen(true)
                      setPhotoOption(true);
                    }}
                  >
                    <input {...getInputProps()}/>
                    <Flexbox
                      height={"100%"}
                      widht={"100%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      className={styles.thumbnailContainer}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        color="#ffffff"
                        transform="grow-60"
                      />
                    </Flexbox>
                  </Card>
                )}
              </Dropzone>
            </Flexbox>
            <FullscreenCamera
              onClose={() => {
                setCameraOpen(false);
              }}
              open={cameraOpen}
              onAcceptImage={handleAcceptImage}
            />

            <PhotoOptionOverlay
              isPhotoOption={photoOption}
              onClose={setPhotoOption}
              handlePhotoUpload={handlePhotoUpload}
              setCameraOpen={setCameraOpen}
            />

            <Overlay
              isOpen={isPreview}
              onClose={() => setPreview(false)}
              onClose={handleRejectImage}
              canOutsideClickClose={false}
              canEscapeKeyClose={true}
            >
              <Flexbox
                width={"100vw"}
                height={"100vh"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <div className={styles.closeButton}>
                  <Button icon={"cross"} onClick={handleRejectImage} />
                </div>
                <PhotoPreview
                  dataUri={photo}
                  onAcceptImage={handleAcceptImage}
                  onRejectImage={handleRejectImage}
                />
              </Flexbox>
            </Overlay>

            <Overlay
              isOpen={dataUri}
              onClose={() => {
                setDataUri("");
              }}
            >
              <Flexbox
                height={"100%"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                className={styles.uploadImagePreviewContainer}
              >
                <Flexbox alignItems={"center"} justifyContent={"center"}>
                  <img className={styles.uploadImagePreview} src={dataUri} />
                </Flexbox>
                <Flexbox
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  marginTop={"1%"}
                >
                  {isUploaded ? (
                    <Tag intent={Intent.WARNING}>
                      Note : Leave Title blank to have same name (Don't add
                      address in title)
                    </Tag>
                  ) : (
                    <Tag intent={Intent.WARNING}>
                      Don't add address in title
                    </Tag>
                  )}
                </Flexbox>

                <Flexbox padding={"10px"}>
                  <Checkbox
                    labelElement={<strong>Include in ER</strong>}
                    checked={includeInEr}
                    onChange={() => {
                      setIncludeInEr(!includeInEr);
                    }}
                  />
                </Flexbox>

                {includeInEr && (
                  <>
                    <Tag intent={Intent.SUCCESS}>
                      Optional: If no title is selected, included in Activity
                      Area Pictures
                    </Tag>
                    <Flexbox padding={"10px"}>
                      <Flexbox
                        flex={1}
                        flexDirection={"column"}
                        width={"100%"}
                        marginTop={"10px"}
                      >
                        <Flexbox flex={1}>
                          <label htmlFor={"erSelect"}>ER Title</label>
                        </Flexbox>
                        <Flexbox flex={1}>
                          <div class="bp3-select .modifier">
                            <select
                              value={erTitle}
                              onChange={(event) => {
                                setErTitle(event.target.value);
                                setTitle(event.target.value);
                              }}
                            >
                              <option selected>Select</option>
                              <option value="Front of House">
                                Front of House
                              </option>
                              <option value="Side B (Left) of House">
                                Side B (Left) of House
                              </option>
                              <option value="Side C (Back) of House">
                                Side C (Back) of House
                              </option>
                              <option value="Side D (Right) of House">
                                Side D (Right) of House
                              </option>
                              <option value="House Across Street">
                                House Across Street
                              </option>
                              <option value="Street View of Homes #1">
                                Street View of Homes #1
                              </option>
                              <option value="Street View of Homes #2">
                                Street View of Homes #2
                              </option>
                            </select>
                          </div>
                        </Flexbox>
                      </Flexbox>
                    </Flexbox>
                  </>
                )}

                <Flexbox padding={"10px"}>
                  <Flexbox
                    flex={1}
                    flexDirection={"column"}
                    width={"100%"}
                    marginTop={"10px"}
                  >
                    <Flexbox flex={1}>
                      <label htmlFor={"title"}>Title</label>
                    </Flexbox>
                    <Flexbox flex={1}>
                      <InputGroup
                        value={title}
                        placeholder={"Title"}
                        onChange={(event) => {
                          setTitle(event.target.value);
                          setTitleError(false);
                        }}
                      />
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
                {titleError && !isUploaded ? (
                  <Flexbox
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    marginTop={"1%"}
                  >
                    <Tag intent={Intent.DANGER}>Title Required!</Tag>
                  </Flexbox>
                ) : null}
                <Flexbox padding={"10px"}>
                  <Flexbox
                    flex={1}
                    flexDirection={"column"}
                    width={"100%"}
                    marginTop={"10px"}
                  >
                    <Flexbox flex={1}>
                      <label htmlFor={"description"}>Description</label>
                    </Flexbox>
                    <Flexbox flex={1}>
                      <TextArea
                        value={description}
                        placeholder={"Description"}
                        onChange={(event) => {
                          setDescription(event.target.value);
                        }}
                      />
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
                <Flexbox padding={"10px"}>
                  <Button
                    text={"Upload"}
                    rightIcon={"upload"}
                    large
                    onClick={() => {
                      if (isUploaded) {
                        let fileName;
                        if (isUploaded) {
                          fileName =
                            title === ""
                              ? photoName
                              : `C_${title}.${photoName.slice(
                                  ((photoName.lastIndexOf(".") - 1) >>> 0) + 2
                                )}`;
                        } else {
                          fileName = `C_${title}.jpg`;
                        }

                        const request = new CreateAttachmentRequest(
                          props.projectId,
                          fileName,
                          description,
                          dataUri.substring(
                            dataUri.indexOf(":") + 1,
                            dataUri.indexOf(";")
                          ),
                          dataUri.substring(
                            dataUri.indexOf(";") + 1,
                            dataUri.indexOf(",")
                          ),
                          dataUri,
                          includeInEr
                        );
                        onAddPhoto(request);
                        setDataUri("");
                        setPhotoOption(false);
                        setTitle("");
                        setDescription("");
                        setPhotoName("");
                        setUploaded(false);
                      } else if (!isUploaded && title.trim()) {
                        let fileName;
                        if (isUploaded) {
                          fileName =
                            title === ""
                              ? photoName
                              : `C_${title}.${photoName.slice(
                                  ((photoName.lastIndexOf(".") - 1) >>> 0) + 2
                                )}`;
                        } else {
                          fileName = `C_${title}.jpg`;
                        }

                        const request = new CreateAttachmentRequest(
                          props.projectId,
                          fileName,
                          description,
                          dataUri.substring(
                            dataUri.indexOf(":") + 1,
                            dataUri.indexOf(";")
                          ),
                          dataUri.substring(
                            dataUri.indexOf(";") + 1,
                            dataUri.indexOf(",")
                          ),
                          dataUri,
                          includeInEr
                        );
                        onAddPhoto(request);
                        setDataUri("");
                        setPhotoOption(false);
                        setTitle("");
                        setDescription("");
                        setPhotoName("");
                        setUploaded(false);
                      } else if (!title.trim()) {
                        setTitleError(true);
                      }
                    }}
                  />
                </Flexbox>
              </Flexbox>
            </Overlay>
          </Flexbox>
        );
    };

    let photoTiles = [createAddImageTile(0, onAddPhoto)];
    let list = <Spinner />;

    if (photos) {
        photoTiles = photoTiles.concat(photos.map((photoAttachment, index) => {
            return (
                <Flexbox key={index + 1} height={'100%'} width={'100%'}>
                    <ImageTile index={index + 1} attachmentResponse={photoAttachment} src={photoAttachment.getUrl}
                        onSetProjectCoverPhoto={onSetProjectCoverPhoto}
                        onDeletePhoto={onDeletePhoto}
                        onClick={onPhotoTileClick}
                        project={project}
                        currentUserRole={props.currentUserRole}
                        />
                </Flexbox>)
        }));

        const layouts = generateLayout(photoTiles);
        list = (
            <ResponsiveGridLayout layouts={layouts}
                rowHeight={300}
                margin={[10, 10]}
                breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
                cols={{ lg: 5, md: 4, sm: 2, xs: 1, xxs: 1 }}>
                {photoTiles}
            </ResponsiveGridLayout>);

    }

    return (
        <Flexbox flexGrow={1} flexDirection={'column'} paddingLeft={'10px'} paddingRight={'10px'} width={'100%'}>
            <Flexbox justifyContent={'center'} paddingTop={'10px'} paddingLeft={'10px'} width={'100%'}>
                <h3 className={`bp3-heading ${styles.myAttachmentsTitle}`}>
                    Photos
                </h3>
            </Flexbox>
            <Flexbox justifyContent={'center'} alignContent={'center'} width={'100%'}>
            <ButtonGroup>
                <Button
                    onClick={() => {
                    props.setGridView(false);
                    }}
                    icon="list"
                >
                    Show List View
                </Button>
            </ButtonGroup>
            </Flexbox>
            <Flexbox justifyContent={'center'} alignContent={'center'} width={'100%'}>
                <div className={`bp3-text-large ${styles.listContainer}`}>
                    {list}
                </div>
            </Flexbox>
        </Flexbox>

    )
}


type ImageTileProps = {
    project:?Project,
    attachmentResponse: GetAttachmentResponse,
    src: string,
    index: number,
    onDeletePhoto: (request: DeleteAttachmentForProjectEvent) => any,
    onSetProjectCoverPhoto: (request: GetAttachmentResponse) => any,
}

function ImageTile(props: ImageTileProps) {

    const { src, index, attachmentResponse,project } = props;
    const [image, setImage] = useState(null);
    const [error, setError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    useEffect(() => {
      if (src) {
        const newImage = new Image();
        newImage.onload = () => {
          setImage(newImage);
        };
        newImage.onerror = () => {
            newImage.src = "/errorMessage.png";
        };
        newImage.src = src;
      }
    }, [src]);


    const close = () => {
        setIsOpen(false);
    };

    const open = () => {
        setIsOpen(true);
    };

  

    return (
        <Flexbox key={index} height={'100%'} width={'100%'} justifyContent={'center'} flexDirection={'column'} className={styles.noOverflow}>
            <Flexbox margin={'0 auto'} className={styles.noOverflow}>
                {image ? <Card className={styles.attachmentTile} interactive elevation={0} onClick={() => {
                    if (image) open()
                }}>
                    <Flexbox alignItems={'center'} justifyContent={'center'} flexDirection={'column'} width={'100%'}>
                        <img src={image.src} />
                        {attachmentResponse.title && <Tag className={styles.titleTag}>{attachmentResponse.title.replace("C_"," ").trim()}</Tag>}
                    </Flexbox>
                </Card> : <Spinner />}
                <Overlay  isOpen={isOpen && image} onClose={close} canOutsideClickClose={true}>
                    <Flexbox height={'100vh'} width={'100vw'} alignItems={'center'} justifyContent={'center'}
                        flexDirection={'column'} className={styles.fullscreenImageContainer}>
                        <div className={styles.closeButton}>
                            <Button icon={'cross'} onClick={close} />
                        </div>
                        {/* <Flexbox alignItems={'center'} justifyContent={'center'} width={'100%'} height={'130%'} flex={3}> */}
                            {/* {image ? <iframe className={styles.imagePreview}  src={image.src} /> : <Spinner />} */}

                            {image ? 

                            <Flexbox alignItems={'center'} justifyContent={'center'} flexDirection={'column'} height={"-webkit-fill-available"} marginTop={"10%"} width={'100%'}>
                            <img src={image.src} />
                            
                        </Flexbox>
                      

                  

                            
                            : <Spinner />}





                        {/* </Flexbox> */}
                        <Flexbox flexDirection={'column'} flex={1}>
                            <Flexbox padding={'10px'} flexDirection={'column'}>
                                {attachmentResponse.title && <><H6>Title:</H6> <Card>

                                    <div>{attachmentResponse.title.replace("C_"," ").trim()}</div>
                                </Card></>}
                            </Flexbox>
                            <Flexbox padding={'10px'} flexDirection={'column'}>
                                {attachmentResponse.description && <><H6>Description:</H6><Card>
                                    <div>{attachmentResponse.description}</div>
                                </Card></>}
                            </Flexbox>
                        </Flexbox>
                        <Flexbox>
                          { 
                             AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole && 
                             <Flexbox>
                          
                            <Flexbox padding={'10px'}>
                                <Popover isOpen={isDeleteOpen} onClose={() => {
                                    setIsDeleteOpen(false)
                                }}>
                                    <Button icon={'delete'} text={"Delete"} onClick={() => {
                                        setIsDeleteOpen(true)
                                    }} />
                                    <Flexbox justifyContent={'center'}>
                                        <Flexbox margin={'5px'}>
                                            <Button icon={'cross'} text={"Cancel"} minimal
                                                onClick={() => {
                                                    setIsDeleteOpen(false)
                                                }} />
                                        </Flexbox>
                                        <Flexbox margin={'5px'}>
                                            <Button icon={'confirm'} text={'Confirm'} intent={Intent.WARNING}
                                                onClick={() => {
                                                    if (!image) return;
                                                    props.onDeletePhoto(new DeleteAttachmentForProjectEvent(
                                                        attachmentResponse.projectId,
                                                        AttachmentTypeEnum.image,
                                                        attachmentResponse.id));
                                                    setIsDeleteOpen(false);
                                                    close();
                                                }} />
                                        </Flexbox>
                                    </Flexbox>
                                </Popover>
                            </Flexbox>
                            <Flexbox padding={'10px'}>
                                <Button icon={'media'} text={'Set as project cover photo'} onClick={() => {
                                    props.onSetProjectCoverPhoto(attachmentResponse);
                                    close()
                                }} />
                            </Flexbox>
</Flexbox>
                            }
                            
                            <Flexbox padding={'10px'}>
                                <Button icon={'download'} text={'Download'} onClick={async () => {
                                    if (!image) return;

                                    const arrayBuffer = (await axios.get(attachmentResponse.getUrl,
                                        {
                                            headers:{
                                              "Accept-Encoding": "gzip, deflate, br",
                                              "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
                                              "Connection": "keep-alive",
                                              "Host": "s3.ap-south-1.amazonaws.com",
                                              "If-Modified-Since": "Wed, 28 Oct 2020 10:53:07 GMT",
                                              "If-None-Match": "dbbb68b7217793496adb6be98ed459bb",
                                              "Origin": "https://uat-app.rehab-tracker.com",
                                              "Sec-Fetch-Dest": "empty",
                                              "Sec-Fetch-Mode": "cors",
                                              "Sec-Fetch-Site": "cross-site",
                                             
                                            },
                                            responseType: 'arraybuffer'
                                        }
                                       
                                      )).data;
                                  

                                    function _imageEncode (arrayBuffer) {
                                        let u8 = new Uint8Array(arrayBuffer)
                                      
                                        let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),function(p,c){return p+String.fromCharCode(c)},''))
                                        let mimetype="image/jpeg"
                                        return "data:"+mimetype+";base64,"+b64encoded
                                    }

                                    let encodedString = _imageEncode(arrayBuffer);

                                    const base = encodedString.split(',')[1];

                                    const type = encodedString.split(';')[0].split('/')[1];
                                    const fileType = attachmentResponse.fileType;
                                    const extension = mime.extension(fileType);

                                    const byteString = atob(base);

                                    const ab = new ArrayBuffer(byteString.length);
                                    const ia = new Uint8Array(ab);

                                    for (let i = 0; i < byteString.length; i++) {
                                        ia[i] = byteString.charCodeAt(i);
                                    }

                                    let filename;

                                    let downloadExtension = attachmentResponse.title.slice((attachmentResponse.title.lastIndexOf(".") - 1 >>> 0) + 2)
                                    
                                    let projectName =`_${project.home.address.streetAddress}.${extension}`

                                    let downloadName = attachmentResponse.title.replace("C_"," ").trim().replace(`.${downloadExtension}`,projectName)
                                    

                                    if(attachmentResponse.title.includes(`C_`)){
                                        filename = 
                                        (attachmentResponse.title ? downloadName :
                                           `${attachmentResponse.id}.${extension}`);
                                       } else{

                                            if(downloadExtension){
                                                filename =  (attachmentResponse.title
                                                    ? attachmentResponse.title
                                                    : `${attachmentResponse.id}.${extension}`);
                                            }
                                            else{
                                                filename =  (attachmentResponse.title
                                                    ? `${attachmentResponse.title}.${extension}`
                                                    : `${attachmentResponse.id}.${extension}`);
                                            }

                                          
                                       }


                                    const file = new File([ab], filename, {type: type});

                                    FileSaver.saveAs(file);

                                 

                                    // const url = image.src.replace(/^data:image\/[^;]+/,
                                    //     'data:application/octet-stream');
                                    // window.open(url);
                                }} />
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                </Overlay>
            </Flexbox>
        </Flexbox>
    )
};


/*
*  {image ? <a href={image.src}
                                        download={`${attachmentResponse.id}.${attachmentResponse.fileType.substring(attachmentResponse.fileType.indexOf('/'), attachmentResponse.fileType.length)}`}>Download</a> : null}*/