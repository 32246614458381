//@flow
import * as XLSX from "@sheet/core";
import "./BidSheetExporter.css";
import React, { useEffect, useState } from "react";
import Project from "../../models/Project/Project";
import AssignedTask from "../../models/AssignedTask/AssignedTask";
import TaskGroup from "../../models/TaskGroup/TaskGroup";
import { Callout, Card, Intent, ProgressBar } from "@blueprintjs/core";
import { PriceTypeEnum, QuantityTypeEnum } from "../../models/Task/Task";

type Props = {
  project: ?Project,
  assignedTasks: ?Array<AssignedTask>,
  taskGroups: ?Array<TaskGroup>,
  onExportComplete: () => any
};

export default function BidSheetExporter(props: Props) {
  const { project, taskGroups, assignedTasks, onExportComplete } = props;

  const [steps, setSteps] = useState(0);
  const totalSteps = 3;

  useEffect(() => {
    if (
      !project ||
      !assignedTasks ||
      !taskGroups ||
      (assignedTasks && assignedTasks.length === 0)
    )
      return;
    const currentAssignedTasks = Array.from(assignedTasks);
    const taskGroupIdToAssignedTasksMap = taskGroups.reduce((map, group) => {
      const tasksInGroup = [];
      for (const task of currentAssignedTasks) {
        if (task.taskGroupId === group.id) {
          tasksInGroup.push(task);
        }
      }

      map.set(group, tasksInGroup);
      return map;
    }, new Map());

    setSteps(1);

    const worksheet = buildBidSheetForProject(
      project,
      taskGroupIdToAssignedTasksMap
    );
    setSteps(2);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, "BidSheet");
    setSteps(3);
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
      cellStyles: true
    };
    const filenamePrefix = project.home.address.streetAddress.replace(" ", "_");
    XLSX.writeFile(book, `${filenamePrefix}_PAH_WORK_.xlsx`, wopts);
    onExportComplete();
  }, [project, taskGroups, assignedTasks]);

  return (
    <Card elevation={4}>
      <Callout title={"Exporting"}>
        <ProgressBar value={steps / totalSteps} />
      </Callout>
    </Card>
  );
}

function buildBidSheetForProject(
  project: Project,
  taskGroupsToAssignedTasksMap: Map<TaskGroup, Array<AssignedTask>>
) {
  let runningTotal = 0.0;

  const dataArray = Array.from(taskGroupsToAssignedTasksMap.keys()).reduce(
    (taskGroupArray, taskGroup) => {
      const assignedTasks = taskGroupsToAssignedTasksMap.get(taskGroup);
      if (!assignedTasks) return taskGroupArray;
      taskGroupArray.push([[taskGroup.title + ":", "", "", ""]]);
      const assignedTasksData = assignedTasks.reduce((array, assignedTask) => {
        const price = parseTaskPrice(assignedTask);
        runningTotal = addPriceToTotalIfNumber(price, runningTotal);

        array.push([
          [assignedTask.title, assignedTask.unit, assignedTask.quantity, price],
          [assignedTask.description, "", "", ""]
        ]);
        return array;
      }, []);
      taskGroupArray.push([].concat.apply([], assignedTasksData));
      taskGroupArray.push([["", "", "", ""]]);
      return taskGroupArray;
    },
    []
  );

  const flattenedDataArray = [].concat.apply([], dataArray);

  flattenedDataArray.push(["TOTAL PRICE:", "", "", ""]);

  let homeownerName = `${project.home.homeowner.firstName} ${project.home.homeowner.lastName}`;
  let homeownerAddress = `${project.home.address.streetAddress} ${project.home.address.streetAddressTwo} ${project.home.address.city} ${project.home.address.state} ${project.home.address.country} ${project.home.address.zip}`;
  let homeownerPhone = `${project.home.homeowner.phoneNumber}`;

  const sheetData = {
    cols: [
      { name: "A", key: 0 },
      { name: "B", key: 1 },
      { name: "C", key: 2 },
      { name: "D", key: 3 }
    ],
    data: [
      ["", "", "", ""],
      [`Homeowner Name: ${homeownerName}`, "", "", ""],
      [`Address:  ${homeownerAddress}`, "", "", ""],
      [`Phone Number: ${homeownerPhone}`, "", "", ""],
      [`Square Footage:`, "", "", ""],

      ["", "", "", ""],
      ["", "", "", ""],

      ["General Requirements", "", "", ""],
      ["CONSTRUCTION DEFINITIONS:", "", "", ""],

      [
        "“Install” means to purchase, set up, test, and warrant a new component. “Replace” means to remove and dispose of original material, purchase new material, deliver, install, test, and warrant. “Repair” means to return a building component to like new condition through replacement, adjustment, and recoating of parts. “Reinstall” means to remove, clean, store and install a component.",
        "",
        "",
        ""
      ],

      ["NEW MATERIALS REQUIRED", "", "", ""],
      [
        "All materials used in connection with this work write-up are to be new, of first quality, and without defects – unless otherwise stated or pre-approved by the Owner or  Habitat staff.",
        "",
        "",
        ""
      ],
      ["PERIODICALLY REMOVE DEBRIS", "", "", ""],
      [
        "The contractor shall clean construction debris from the dwelling and site  to a dumpster or legal landfill at least once each week, and leave the property in broom clean condition.",
        "",
        "",
        ""
      ],
      ["WORKER TRAINING – INTERIM CONTROLS", "", "", ""],

      [
        "All persons carrying out activities flagged as “interim controls” or as requiring “lead safe work practices” must either be supervised by an EPA abatement supervisor or provide proof of completion of HUD-approved worker training course in lead safe work practices prior to start of work.",
        "",
        "",
        ""
      ],

      ["", "", "", ""],
      ["", "", "", ""],

      ["SPEC", "UNIT", "QTY", "PRICE"]
    ].concat(flattenedDataArray)
  };
  // return sheetData;
  try {
    return calculateWidths(
      addBordersToPriceCells(XLSX.utils.aoa_to_sheet(sheetData.data))
    );
  } catch (error) {
    console.error(error);
  }
}

function parseTaskPrice(assignedTask): number | string {
  const title = assignedTask.title;

  switch (assignedTask.priceType) {
    case PriceTypeEnum.PER_BID: {
      return "per bid";
    }
    case PriceTypeEnum.USD: {
      const numberPrice = parseFloat(assignedTask.price);
      if (Number.isNaN(numberPrice)) {
        throw Error("Price marked USD is not a valid number for task " + title);
      } else {
        let quantity = assignedTask.quantity;
        let quantityType = assignedTask.quantityType;
        if (quantityType !== QuantityTypeEnum.FLOAT)
          throw Error(
            "Quantity type is not supported for task: " + quantityType
          );
        if (typeof quantity !== "number") quantity = parseFloat(quantity);
        if (Number.isNaN(quantity)) {
          throw Error("Quantity is not a valid number for task: " + title);
        }
        return " ";
      }
    }
    default: {
      console.warn(
        "Unknown price type. Bid sheet may not be accurate: " +
        assignedTask.priceType
      );
      return 0;
    }
  }
}

function addPriceToTotalIfNumber(price: string | number, runningTotal) {
  if (price) {
    price = price.toString().substring(1);
  }

  if (price !== "er bid") price = parseInt(price);

  if (typeof price === "number") return runningTotal + price;
  else return runningTotal;
}

function calculateWidths(worksheet) {
  // const range = XLSX.utils.decode_range(worksheet["!ref"]);
  // if (!worksheet["!cols"]) worksheet["!cols"] = new Array(range.e.c);
  // for (let index = 0; index < worksheet["!cols"].length; index++) {
  //   worksheet["!cols"][index] = { auto: 1 };
  // }

  var wscols = [{ width: 50 }, { width: 10 }, { width: 10 }, { width: 10 }];
  worksheet["!cols"] = wscols;

  return worksheet;
}

function addBordersToPriceCells(worksheet) {
  const range = XLSX.utils.decode_range(worksheet["!ref"]);

  const titleColumn = 0;
  const unitColumn = 1;
  const qtyColumn = 2;
  const priceColumn = 3;

  const headerRow = 0;

  let nextSectionRow = 1;

  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_address = { c: C, r: R };
      const cell_ref = XLSX.utils.encode_cell(cell_address);

      let rgb, bold, border, color, alignment, underline, fontsize;

      if (cell_address.c === unitColumn) {


        if (cell_address.r >= 18 ) {
          rgb = { rgb: "D9E3BC" };
        }

      

        alignment = {
          horizontal: "center",
          vertical: "center",
          wrapText: true
        };
      }

      if (cell_address.c === qtyColumn) {
        alignment = {
          horizontal: "center",
          vertical: "center",
          wrapText: true
        };
      }

      if (cell_address.c === priceColumn) {

        if (cell_address.r >= 18 ) {
          rgb = { rgb: "B8CCE4" };
        }

      
        alignment = {
          horizontal: "center",
          vertical: "center",
          wrapText: true
        };
      }

      if (cell_address.c === titleColumn && cell_address.r !== headerRow) {
        let nextCellAddress = { c: cell_address.c + 1, r: cell_address.r };
        const next_cell_ref = XLSX.utils.encode_cell(nextCellAddress);
        if (worksheet[next_cell_ref].v !== "") {
          rgb = { rgb: "d8d8d8" };
          alignment = {
            horizontal: "left",
            wrapText: true
          };
        }
      }

      if (cell_address.r !== headerRow) {
        if (nextSectionRow === cell_address.r) {
          bold = true;
          alignment = {
            horizontal: "left",
            wrapText: true
          };
        } else {
          bold = false;
          alignment = {
            horizontal: "left",
            wrapText: true
          };
        }

        if (worksheet[cell_ref].v === "" && cell_address.c === titleColumn) {
          nextSectionRow = cell_address.r + 1;
         
        }
      } else {
        bold = true;
      }

      if (cell_address.r === range.e.r) {
        rgb = { rgb: "E5B8B7" };
        if (cell_address.c === range.e.c) {
          border = "thick";
        }
      }

      if (cell_address.r <= 5) {
        bold = true;
        underline = true;
        alignment = {
          horizontal: "left",
          wrapText: true
        };
      }

      if (cell_address.r === 7) {
        bold = true;
        underline = true;
        fontsize = 14;
      }

      if (cell_address.r === 8 && cell_address.c === titleColumn) {
        rgb = { rgb: "F4F6F6" };
      }

      if (cell_address.r === 10 && cell_address.c === titleColumn) {
        rgb = { rgb: "F4F6F6" };
      }

      if (cell_address.r === 12 && cell_address.c === titleColumn) {
        rgb = { rgb: "F4F6F6" };
      }

      if (cell_address.r === 14 && cell_address.c === titleColumn) {
        rgb = { rgb: "F4F6F6" };
      }

      if (cell_address.r === 19 && cell_address.c === titleColumn) {
        bold = true;
      }

      worksheet[cell_ref].s = buildCellStyles(
        bold,
        rgb,
        border,
        color,
        alignment,
        underline,
        fontsize
      );
    }
  }

 

  return worksheet;
}

const buildCellStyles = (
  bold,
  rgb,
  border,
  color,
  alignment,
  underline,
  fontsize
) => {
  if (!border) border = "thin";

  let style = {
    top: { style: border },
    bottom: { style: border },
    left: { style: border },
    right: { style: border }
  };
  if (rgb) style.fgColor = rgb;
  if (bold) style.bold = bold;
  if (color) style.color = color;
  if (alignment) style.alignment = alignment;
  if (underline) style.underline = underline;
  if (fontsize) style.sz = fontsize;

  return style;
};
