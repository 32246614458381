//@flow

import styles from "./BrandDetailsView.module.css";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, Field, Form, useField } from "formik";
import { Button, Intent, FileInput } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { InputGroup, Divider, Card } from "@blueprintjs/core";
import FocusError from "../FocusError/FocusError";
import TextSelect from "../TextSelect/TextSelect";
import { AuthRoles } from "../../blocs/authBloc";

type Props = {
  onNewProjectCreated: () => void,
  brand: ?any,
  buttonTitle: ?string,
  currentUserRole: ?Boolean
};
type CountryProps = {
  countryInputName: string,
  stateInputName: string,
  disabled?: boolean
};


const zipRegex = /^(?!0{3})[0-9]{5,5}$/;
const phoneRegExp = /^[1-9]\d{2}-\d{3}-\d{4}$/;

function CountryStateDropdownInput(props: CountryProps) {
  // this will return field props for an <input />
  const [countryField, countryMeta] = useField(props.countryInputName);
  const [stateField, stateMeta] = useField(props.stateInputName);

  const countryError =
    countryMeta.error && countryMeta.touched ? (
      <div className={styles.errorText}>{countryMeta.error}</div>
    ) : (
      <div />
    );
  const stateError =
    stateMeta.error && stateMeta.touched ? (
      <div className={styles.errorText}>{stateMeta.error}</div>
    ) : (
      <div />
    );

  return (
    <Flexbox flex={1} flexDirection={"column"}>
      <Flexbox flex={1} flexDirection={"column"}>
        <CountryDropdown
          disabled={true} //no other country supported as of now
          classes={styles.fullWidthInput}
          value={countryField.value}
          onChange={(newValue: string) => {
            const event = {
              target: {
                name: countryField.name,
                value: newValue
              }
            };
            countryField.onChange(event);
          }}
        />
        {countryError}
      </Flexbox>
      <Flexbox flex={1} flexDirection={"column"} marginTop={"10px"}>
        <RegionDropdown
          disabled={props.disabled}
          classes={styles.fullWidthInput}
          country={countryField.value}
          value={stateField.value}
          onChange={(newValue: string) => {
            const event = {
              target: {
                name: stateField.name,
                value: newValue
              }
            };
            stateField.onChange(event);
          }}
        />
        {stateError}
      </Flexbox>
    </Flexbox>
  );
}

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

export default function BrandDetailsView(props: Props) {
  const [state, setState] = useState(formStates.INPUT);
  const [logo, setLogo] = useState({});
  const [selectedFile, setSelectedFile] = useState(null)
  const [validFileType, setValidFileType] = useState(false)
  const { brand, buttonTitle, loadingBrandInfo } = props;

  const { organization_name, firstname, lastname, phonenumber, email, address1, address2, city, zipcode, country, organization_phone, website, county, address_type } = brand && brand
    ? brand
    : "";
  const createInput = (
    inputName: string,
    inputLabel: string,
    type?: string,
    placeHolderName?: string
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName} style={{ paddingBottom: 5, paddingTop: 10 }}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1} >
          <Field
            className={`bp3-large`}
            as={InputGroup}
            name={inputName}
            placeholder={placeHolderName}
            type="text"
            fill
            disabled={state !== formStates.INPUT}
          />
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };
  const createSelectInput = (inputName: string, inputLabel: string, select) => {
    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{select}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (file.type === "image/png") {
        setValidFileType(false)
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
          reject(error);
        }
      } else {
        setValidFileType(true)
      }
    })
  }

  const setLogoInformation = (file, base64) => {
    let logo = {
      payload: base64,
      fileType: file.type
    }
    setLogo(logo);
  }

  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      <Formik
        initialValues={{
          firstname,
          lastname,
          organization_phone,
          email,
          phonenumber,
          address1,
          address2,
          website,
          address_type,
          city,
          county,
          zipcode,
          organization_name,
          region: brand && brand ? brand.region : "",
          country: "United States",
        }}
        enableReinitialize
        validationSchema={Yup.object({
          organization_phone: Yup.string()
            .matches(phoneRegExp, "Invalid phone number")
            .required("Required"),
          firstname: Yup.string().required("Required"),
          lastname: Yup.string().required("Required"),
          email: Yup.string().nullable().email("Invalid email address").required("Required"),
          phonenumber: Yup.string()
            .matches(phoneRegExp, "Invalid phone number")
            .required("Required"),
          address1: Yup.string().required("Required"),
          address2: Yup.string().nullable(),
          organization_name: Yup.string().required("Required"),
          city: Yup.string().required("Required"),
          region: Yup.string().required("Required"),
          zipcode: Yup.string()
            .max(10)
            .matches(zipRegex, { message: "Invalid Zip code" })
            .required("Required"),
          county: Yup.string().required("Required"),
          address_type: Yup.string().required("Required"),
          country: Yup.string().required("Required"),
        })}
        onSubmit={async (values) => {
          props.onCreateOrUpdateBrand(values, brand && brand.email ? true : false, logo);
          setSelectedFile(null);
        }}
      >
        <Form>
          <Card elevation={1} className={styles.card}>
            <Flexbox
              flex={1}
              alignItems={"flex-start"}
              width={"100%"}
              className={styles.inputSection}
            >
              <h3>Affiliate Information</h3>
            </Flexbox>
            <Flexbox flex={1} width={"100%"}>
              <Divider className={styles.fullWidthInput} />
            </Flexbox>
            {createInput("organization_name", "Affiliate Name")}
            {createInput("address1", "Street Address")}
            {createInput("address2", "Street Address Line 2")}
            <Flexbox flexDirection={'row'}>
              <Flexbox style={{ marginTop: 25 }}>
                {createSelectInput(
                  "address_type",

                  <TextSelect
                    items={["City", "Village", "Township"]}
                    inputName={"address_type"}
                  />
                )}
              </Flexbox>
              <Flexbox width={"100%"}>
                {createInput("city", "City/Village/Township")}
              </Flexbox>
            </Flexbox>

            {createInput("county", "County")}
            {createInput("zipcode", "Zip Code", "number")}
            {createInput("organization_phone", "Affiliate Phone")}
            {createInput("website", "Website")}
            <div class="sc-bdVaJa kZPOHL" style={{ paddingBottom: 5, paddingTop: 10, }}>
              <label for="profile">Logo</label>
            </div>
            <FileInput
              fill
              large
              intent={Intent.PRIMARY}
              disabled={false}
              inputProps={{ accept: "image/*" }}
              text={selectedFile ? selectedFile.name : "Upload Photo..."}
              onInputChange={async (event) => {
                let file = event.target.files[0];
                setSelectedFile(file);
                let data = await convertBase64(file);
                setLogoInformation(file, data);
              }}
            />
            <Flexbox style={{ color: "red" }}>{validFileType ? "Only PNG format is allowed." : ""}</Flexbox>
            <Flexbox
              flex={1}
              width={"100%"}
              paddingTop={"10px"}
              paddingBottom={"10px"}
              marginTop={"15px"}
            >
              <CountryStateDropdownInput
                countryInputName={"country"}
                stateInputName={"region"}
                disabled={state !== formStates.INPUT}
              />
            </Flexbox>
          </Card>
          <Flexbox
            alignItems={"center"}
            flexDirection={"column"}
            className={styles.inputSection}
          >
            <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Tenant Contact Information</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>

              {createInput("firstname", "First Name")}
              {createInput("lastname", "Last Name")}
              {createInput(
                "phonenumber",
                "Phone Number",
                "phone",
                "222-222-2222"
              )}
              {createInput("email", "Email", "email")}
            </Card>
            {props.currentUserRole !== AuthRoles.MARKETING_MANAGER &&
              <Flexbox flex={1} marginTop={"15px"}>
                <Button
                  type="submit"
                  className={"bp3-large"}
                  rightIcon={state === formStates.INPUT ? "arrow-right" : null}
                  disabled={state !== formStates.INPUT || validFileType}
                  loading={loadingBrandInfo}
                  icon={state === formStates.SUCCESS ? "saved" : null}
                >
                  {brand && brand ? "Update" : "Create"}
                </Button>
              </Flexbox>
            }
          </Flexbox>
          <FocusError />
        </Form>
      </Formik>
    </div>
  );
}
