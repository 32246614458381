// @flow

import React, { useState } from "react";
import { Formik, ErrorMessage, Field, Form, useField, FastField } from "formik";
import * as Yup from "yup";
import styles from "./CreateInvoiceRequestBuilder.module.css";
import {
  InputGroup,
  Card,
  Spinner,
  Button,
  Divider,
  TextArea,
  FileInput,
  Intent,
  Tag,
} from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import Vendor from "../../models/Vendor/Vendor";
import VendorSuggest from "../VendorSuggest/VendorSuggest";
import DateTimeInput from "../DateTimeInput/DateTimeInput";
import {
  CreateInvoiceRequest,
  UpdateInvoiceRequest,
  InvoiceAttachment,
  CreateInvoiceAttachment,
} from "../../models/Invoice/Invoice";
import Project from "../../models/Project/Project";

type Props = {
  project: ?Project,
  vendors: Array<Vendor>,
  onAddVendorButtonClick: () => any,
  onCreateInvoice: (request: CreateInvoiceRequest) => void,
  invoiceData: ?any,
  onUpdateInvoice: (updateInvoiceRequest: UpdateInvoiceRequest) => void,
};

function CreateInvoiceRequestBuilder(props: Props) {
  const {
    project,
    vendors,
    onAddVendorButtonClick,
    onCreateInvoice,
    invoiceData,
    onUpdateInvoice,
  } = props;

  const [value, setValue] = useState(
    invoiceData ? (invoiceData.notes ? invoiceData.notes : "") : ""
  );

  const [file, setFile] = useState(null);
  const [base64File, setBase64File] = useState(null);
  const [displayUploadedFile, SetUploadedFile] = useState(
    invoiceData
      ? invoiceData.attachment
        ? invoiceData.attachment.title
        : ""
      : ""
  );
  const [updateAttachment, setUpdateAttachment] = useState(false);
  const [deleteAttachment, setDeleteAttachment] = useState(false);

  if (!vendors) return <Spinner />;

  const vendorIdsToVendorsMap: Map<string, Vendor> = vendors.reduce(
    (map, vendor) => {
      map.set(vendor.id, vendor);
      return map;
    },
    new Map()
  );

  let filteredVendors = vendorIdsToVendorsMap;

  const createInput = (
    inputName: string,
    inputLabel: string,
    options?: { fill?: boolean, type?: string, slow?: boolean }
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let field = (
      <FastField
        className={`bp3-large`}
        as={InputGroup}
        name={inputName}
        type={type}
        leftIcon="dollar"
        // fill={fill}
        // disabled={state !== formStates.INPUT || disableEdit}
      />
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };
  const createInputArea = (
    inputName: string,
    inputLabel: string,
    options?: { fill?: boolean, type?: string, slow?: boolean }
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let maxLength = 255;

    let field = (
      <>
        <FastField
          className={`bp3-large ${styles.input}`}
          as={TextArea}
          rows="5"
          // cols="30"
          name={inputName}
          type={type}
          fill={fill}
          value={value}
          maxLength={maxLength}
          // disabled={state !== formStates.INPUT || disableEdit}
          onInput={(e) => setValue(e.target.value)}
        />
      </>
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{`${inputLabel} (${
            maxLength - value.length
          } characters remaining)`}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSelectInput = (inputName: string, inputLabel: string, select) => {
    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{select}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const handlePhotoUpload = async (e) => {
  

    setFile(e.target.files[0]);

    const fileString = await new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (err) => {
        throw err;
      };

      reader.readAsDataURL(e.target.files[0]);
    });

  

    setBase64File(fileString);
  };

  let initialValues;



  if (invoiceData) {
    initialValues = {
      vendorId: invoiceData.vendorId,
      amount: invoiceData.amount,
      invoiceDate: invoiceData.invoiceDate,
      dueDate: invoiceData.dueDate,
      paidDate: invoiceData.paidDate,
      submittedDate: invoiceData.submittedDate,
      reimbursementSubmittedDate: invoiceData.reimbursementSubmittedDate,
      reimbursementReceivedDate: invoiceData.reimbursementReceivedDate,
      notes: invoiceData.notes,
    };
  } else {
    initialValues = {
      vendorId: "",
      amount: "",
      invoiceDate: "",
      dueDate: "",
      paidDate: "",
      submittedDate: "",
      reimbursementSubmittedDate: "",
      reimbursementReceivedDate: "",
      notes: "",
    };
  }

  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({
          amount: Yup.string().max(128).required("Required"),
          vendorId: Yup.string().max(128).required("Required"),
          invoiceDate: Yup.string().max(128).required("Required"),
          submittedDate: Yup.date().required("Required")
        })}
        onSubmit={async (values) => {
        

          let createInvoiceRequest;
          let updateInvoiceRequest;

          if (invoiceData) {
            if (base64File) {
              let attachment = new CreateInvoiceAttachment(
                project.id,
                file ? file.name : "",
                "",
                base64File.substring(
                  base64File.indexOf(":") + 1,
                  base64File.indexOf(";")
                ),
                base64File.substring(
                  base64File.indexOf(";") + 1,
                  base64File.indexOf(",")
                ),
                base64File
              );

              updateInvoiceRequest = new UpdateInvoiceRequest(
                invoiceData.id,
                project.id,
                values.vendorId,
                values.amount,
                values.invoiceDate,
                values.dueDate,
                values.submittedDate,
                values.paidDate,
                values.reimbursementSubmittedDate,
                values.reimbursementReceivedDate,
                values.notes,
                attachment,
                true,
                deleteAttachment,
                invoiceData.attachmentId
              );
            } else {
              updateInvoiceRequest = new UpdateInvoiceRequest(
                invoiceData.id,
                project.id,
                values.vendorId,
                values.amount,
                values.invoiceDate,
                values.dueDate,
                values.submittedDate,
                values.paidDate,
                values.reimbursementSubmittedDate,
                values.reimbursementReceivedDate,
                values.notes,
                null,
                updateAttachment,
                deleteAttachment,
                invoiceData.attachmentId
              );
            }

            await onUpdateInvoice(updateInvoiceRequest);
          } else {
            if (base64File) {
              let attachment = new CreateInvoiceAttachment(
                project.id,
                file ? file.name : "",
                "",
                base64File.substring(
                  base64File.indexOf(":") + 1,
                  base64File.indexOf(";")
                ),
                base64File.substring(
                  base64File.indexOf(";") + 1,
                  base64File.indexOf(",")
                ),
                base64File
              );

              createInvoiceRequest = new CreateInvoiceRequest(
                project.id,
                values.vendorId,
                values.amount,
                values.invoiceDate,
                values.dueDate,
                values.submittedDate,
                values.paidDate,
                values.reimbursementSubmittedDate,
                values.reimbursementReceivedDate,
                values.notes,
                attachment
              );
            } else {
              createInvoiceRequest = new CreateInvoiceRequest(
                project.id,
                values.vendorId,
                values.amount,
                values.invoiceDate,
                values.dueDate,
                values.submittedDate,
                values.paidDate,
                values.reimbursementSubmittedDate,
                values.reimbursementReceivedDate,
                values.notes
              );
            }

            await onCreateInvoice(createInvoiceRequest);
           
          }
        }}
      >
        <Form>
          <Flexbox
            alignItems={"center"}
            flexDirection={"column"}
            className={styles.inputSection}
          >
            {/* <Card elevation={3}  className={styles.card}> */}
            {/* <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Add Invoice</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox> */}
            <br />

            {createSelectInput(
              "vendorId",
              "Select Vendor",
              <>
                <VendorSuggest
                  vendors={Array.from(filteredVendors.values())}
                  inputName={"vendorId"}
                  selectedItem={invoiceData && invoiceData.vendorId}
                />
                <Button icon={"plus"} onClick={onAddVendorButtonClick} />
              </>
            )}

            {createInput("amount", "Amount", { type: "number" })}

            {createSelectInput(
              "invoiceDate",
              "Invoice Date",
              <DateTimeInput
                inputName={"invoiceDate"}
                selectedDate={invoiceData ? invoiceData.invoiceDate : null}
              />
            )}

            {createSelectInput(
              "dueDate",
              "Due Date",
              <DateTimeInput
                inputName={"dueDate"}
                selectedDate={invoiceData ? invoiceData.dueDate : null}
              />
            )}

            {createSelectInput(
              "submittedDate",
              "Submitted Date",
              <DateTimeInput
                inputName={"submittedDate"}
                selectedDate={invoiceData ? invoiceData.submittedDate : null}
              />
            )}

            {createSelectInput(
              "paidDate",
              "Paid Date",
              <DateTimeInput
                inputName={"paidDate"}
                selectedDate={invoiceData ? invoiceData.paidDate : null}
              />
            )}

            {createSelectInput(
              "reimbursementSubmittedDate",
              "Reimbursement Submitted Date",
              <DateTimeInput
                inputName={"reimbursementSubmittedDate"}
                selectedDate={
                  invoiceData ? invoiceData.reimbursementSubmittedDate : null
                }
              />
            )}

            {createSelectInput(
              "reimbursementReceivedDate",
              "Reimbursement Received Date",
              <DateTimeInput
                inputName={"reimbursementReceivedDate"}
                selectedDate={
                  invoiceData ? invoiceData.reimbursementReceivedDate : null
                }
              />
            )}

            {createInputArea("notes", "Notes")}

            {createSelectInput(
              "upload_invoice",
              "Upload Invoice",
              <FileInput
                fill
                large
                intent={Intent.PRIMARY}
                disabled={false}
                inputProps={{ accept: "*" }}
                onInputChange={handlePhotoUpload}
                disabled={displayUploadedFile ? true : false}
              />
            )}

            {file && (
              <Flexbox
                flex={1}
                flexDirection={"column"}
                width={"100%"}
                padding={"10px"}
                marginTop={"10px"}
              >
                <Flexbox flex={1}>
                  <Tag
                    removable={true}
                    interactive
                    intent={Intent.SUCCESS}
                    onRemove={() => {
                      setFile(null);
                      setBase64File(null);
                    }}
                  >
                    {file.name}
                  </Tag>
                </Flexbox>
              </Flexbox>
            )}

            {displayUploadedFile && (
              <Tag icon="info-sign" intent={Intent.WARNING}>
                Note : Remove existing file to upload new
              </Tag>
            )}

            {displayUploadedFile && (
              <Flexbox
                flex={1}
                flexDirection={"column"}
                width={"100%"}
                padding={"10px"}
                marginTop={"10px"}
              >
                <Flexbox flex={1}>
                  <Tag
                    removable={true}
                    interactive
                    intent={Intent.SUCCESS}
                    onRemove={() => {
                      setFile(null);
                      setBase64File(null);
                      SetUploadedFile(null);
                      setDeleteAttachment(true);
                    }}
                  >
                    {invoiceData.attachment ? displayUploadedFile : ""}
                  </Tag>
                </Flexbox>
              </Flexbox>
            )}

            <Flexbox
              flex={1}
              alignItems={"center"}
              justifyContent={"center"}
              marginTop={"15px"}
            >
              <Button type="submit" className={"bp3-large"}>
                {invoiceData ? "UPDATE" : " CREATE"}
              </Button>
            </Flexbox>
            {/* </Card> */}
          </Flexbox>
        </Form>
      </Formik>
    </div>
  );
}

export default CreateInvoiceRequestBuilder;
