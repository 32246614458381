//@flow

import styles from "./ExportErForm.module.css";
import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage, Field, Form, FastField } from "formik";
import { Button, Tag, TextArea, Spinner } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { InputGroup, Divider, Card, Intent } from "@blueprintjs/core";
import HomeownerApplication from "../../models/HomeownerApplication/HomeownerApplication";
import TextSelect from "../TextSelect/TextSelect";
import HouseholdMembersList from "../HouseholdMembersList/HouseholdMembersList";
import DateSelect from "../DateSelect/DateSelect";
import SignatureSelect from "../SignatureInput/SignatureSelect";
import CheckboxSelect from "../CheckboxSelect/CheckboxSelect";
import ReactToPrint from "react-to-print";
import { AppToaster } from "../Toaster/Toaster";
import Project from "../../models/Project/Project";
import { Responsive, WidthProvider } from "react-grid-layout";
import ExportEr from "../../models/ExportEr/ExportEr";
import { AuthRoles } from "../../blocs/authBloc";
import { CROWN } from "@blueprintjs/icons/lib/esm/generated/iconNames";
import { DIALOG_STEP_VIEWED } from "@blueprintjs/core/lib/esm/common/classes";

const ref = React.createRef();
const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
  application: ?HomeownerApplication,
  onErUpdate: (er: ExportEr) => any,
  project: ?Project,
  navigateToFiles: (projectId: ?string) => any,
  onErSubmitted: () => any,
  onErReleased: () => any
};

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export default function ExportErForm(props: Props) {
  const {
    application,
    onErUpdate,
    project,
    navigateToFiles,
    onErSubmitted,
    onErReleased
  } = props;

  const [state, setState] = useState(formStates.INPUT);
  const { organisedPhotos, errData, others } = application;

  const [value, setValue] = useState(null);
  const [conditionValue, setConditionValue] = useState(null);
  // const disableEdit = application.status !== "CREATED";
  const disableEdit = AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole;

  const createInput = (
    inputName: string,
    inputLabel: string,
    options?: {
      fill?: boolean,
      type?: string,
      slow?: boolean,
      disable?: boolean,
    }
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let disable = false;
    if (options && options.disable) disable = options.disable;

    let field = (
      <FastField
        className={`bp3-large ${styles.input}`}
        as={InputGroup}
        name={inputName}
        type={type}
        fill={fill}
        disabled={state !== formStates.INPUT || disable || disableEdit}
      />
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createInputArea = (
    inputName: string,
    inputLabel: string,

    options?: { fill?: boolean, type?: string, slow?: boolean, print?: boolean },
    defaultValue?: string = ""
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let printStyle = "";
    let printPreview = "";
    if (options && options.print === true) {
      printStyle = styles.hideInPrint;
      printPreview = styles.showInPrint;
    }

    let field = (
      <>
        <FastField
          className={`bp3-large ${styles.input} ${printStyle}`}
          as={TextArea}
          rows="40"
          cols="30"
          name={inputName}
          type={type}
          fill={fill}
          disabled={state !== formStates.INPUT || disableEdit}
          onInput={(e) => setValue(e.target.value)}
        />
      </>
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        {/* <Flexbox flex={1}>
          <label htmlFor={inputName}>{`${inputLabel} (${
            maxLength - value.length
          } characters remaining)`}</label>
        </Flexbox> */}
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox flex={1} className={printPreview}>
          <pre
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              width: "1000px",
              fontSize: "13px"
            }}
          >{value ?? defaultValue}</pre>
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createConditionInputArea = (
    inputName: string,
    inputLabel: string,
    options?: { fill?: boolean, type?: string, slow?: boolean, print?: boolean },
    defaultValue?: String = ""
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let printStyle = "";
    let printPreview = "";
    if (options && options.print == true) {
      printStyle = styles.hideInPrint;
      printPreview = styles.showInPrint;
    }

    let field = (
      <>
        <FastField
          className={`bp3-large ${styles.input} ${printStyle}`}
          as={TextArea}
          rows="10"
          cols="30"
          name={inputName}
          type={type}
          fill={fill}
          //   maxLength={maxLength}
          disabled={state !== formStates.INPUT || disableEdit}
          onInput={(e) => setConditionValue(e.target.value)}
        />
      </>
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox flex={1} className={printPreview}>
          <pre
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              width: "1000px",
              fontSize: "13px"
            }}
          >{conditionValue ?? defaultValue}</pre>
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSignatureInput = (
    inputName: string,
    inputLabel: string,
    options?: { fill?: boolean, type?: string, slow?: boolean }
  ) => {
    let type = "text";
    if (options && options.type) type = options.type;

    let fill = true;
    if (options && (options.fill === true || options.fill === false))
      fill = options.fill;

    let field = (
      <FastField
        className={`bp3-large`}
        as={InputGroup}
        name={inputName}
        type={type}
        fill={fill}
        // disabled={state !== formStates.INPUT || !signatureReady}
        disabled={state !== formStates.INPUT || disableEdit}
      />
    );

    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{field}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSelectInput = (inputName: string, inputLabel: string, select) => {
    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{select}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const logo = (
    <Flexbox flex={1} alignItems={"left"} width={"10%"}>
      <img
        // src="https://files.slack.com/files-pri/TK24GQVUN-F01CHK0G1L1/fort-worth-logo.png"
        //  src={require("../../fort-worth-logo.png")}
        src={application.newBrand?.logoUrl}
      />
    </Flexbox>
  );

  const generateLayout = (buttons: Array<any>) => {
    return {
      lg: buttons.map((button, index) => {
        return {
          x: index % 5,
          y: Math.floor(index / 5),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      md: buttons.map((button, index) => {
        return {
          x: index % 4,
          y: Math.floor(index / 4),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      sm: buttons.map((button, index) => {
        return {
          x: index % 2,
          y: Math.floor(index / 2),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      xs: buttons.map((button, index) => {
        return {
          x: index % 1,
          y: Math.floor(index / 1),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      xss: buttons.map((button, index) => {
        return {
          x: 0,
          y: index,
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
    };
  };

  let activityAreaTiles = [];
  let displayActivityAreaPic = <Spinner />;

  if (organisedPhotos.ActivityAreaPictures.length) {
    activityAreaTiles = organisedPhotos.ActivityAreaPictures.map(
      (activityAreaPicture, index) => {
        return (
          <div className={`${styles.column} ${styles.activityAreaPics}`}>
            <ImageTile
              key={index}
              index={index}
              src={activityAreaPicture.getUrl}
              tag={activityAreaPicture.title}
            />
            {/* <div className="activityAreaPics" key={index} height={"100%"} width={"100%"}>
              
            </div> */}
          </div>
        );
      }
    );

    const layouts = generateLayout(activityAreaTiles);
    displayActivityAreaPic = (
      // <ResponsiveGridLayout
      //   layouts={layouts}
      //   rowHeight={300}
      //   margin={[30, 40]}
      //   breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
      //   cols={{ lg: 5, md: 4, sm: 2, xs: 1, xxs: 1 }}
      // >
      // <Container>
      <div className={styles.row}>
        {activityAreaTiles}
      </div>
      // </Container>
      // </ResponsiveGridLayout>
    );
  } else {
    displayActivityAreaPic = (
      <Flexbox
        flexGrow={1}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Tag
          minimal
          large
          intent={Intent.PRIMARY}
        // text={""}
        // icon={"endorsed"}
        >
          No Activity Area Pictures
        </Tag>
      </Flexbox>
    );
  }

  let areaPictureTiles = [];
  let areaPictureList = <Spinner />;

  if (organisedPhotos.AreaPictures.length) {
    areaPictureTiles = organisedPhotos.AreaPictures.map(
      (areaPicture, index) => {
        return (
          <div className={`${styles.column} ${styles.activityAreaPics}`}>
            <ImageTile
              index={index}
              src={areaPicture.getUrl}
              tag={areaPicture.title}
            />
          </div>
        );
      }
    );

    const layouts = generateLayout(areaPictureTiles);
    areaPictureList = (
      // <ResponsiveGridLayout
      //   layouts={layouts}
      //   rowHeight={300}
      //   margin={[10, 40]}
      //   breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
      //   cols={{ lg: 5, md: 4, sm: 2, xs: 1, xxs: 1 }}
      // >
      //   {areaPictureTiles}
      // </ResponsiveGridLayout>
      // <Container>
      <div className={styles.row}>
        {areaPictureTiles}
      </div>
      // </Container>
    );
  } else {
    areaPictureList = (
      <Flexbox
        flexGrow={1}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Tag
          minimal
          large
          intent={Intent.PRIMARY}
        // text={""}
        // icon={"endorsed"}
        >
          No Area Pictures
        </Tag>
      </Flexbox>
    );
  }

  let leadProjectPicture = <Spinner />;

  if (organisedPhotos.LeadProjectPictures.length) {
    leadProjectPicture = organisedPhotos.LeadProjectPictures.map(
      (areaPicture, index) => {
        return (
          <Flexbox key={index} height={"100%"} width={"100%"}>
            <ImageTile
              index={index}
              src={areaPicture.getUrl}
              tag={areaPicture.title}
            />
          </Flexbox>
        );
      }
    );
  } else {
    leadProjectPicture = (
      <Flexbox
        flexGrow={1}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Tag
          minimal
          large
          intent={Intent.PRIMARY}
        // text={""}
        // icon={"endorsed"}
        >
          No Front of House
        </Tag>
      </Flexbox>
    );
  }

  let showTAD = <Spinner />;

  if (organisedPhotos.TAD.length) {
    showTAD = organisedPhotos.TAD.map((tad, index) => {
      return (
        <Flexbox key={index} height={"100%"} width={"100%"}>
          <ImageTile index={index} src={tad.getUrl} tag={tad.title} />
        </Flexbox>
      );
    });
  } else {
    showTAD = (
      <Flexbox
        flexGrow={1}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Tag
          minimal
          large
          intent={Intent.PRIMARY}
        // text={""}
        // icon={"endorsed"}
        >
          No TAD image
        </Tag>
      </Flexbox>
    );
  }

  const address = project.home.address;

  let addressText = `${address.streetAddress}`;
  if (address.streetAddressTwo)
    addressText = `${addressText}, ${address.streetAddressTwo}`;

  let addressTextLineTwo;
  addressTextLineTwo = `${address.city}, ${address.state} ${address.zip}`;

  const projectManagerName = `${application.projectManager.firstName || ""} ${application.projectManager.lastName || ""
    }`;
  const projectManagerEmail = `${application.projectManager.email || ""}`;
  const currentAddress = `${addressText && addressText} ${addressTextLineTwo && addressTextLineTwo
    }`;

  let tadSqftInitialValue = "";

  if (others.tadSqft) {
    tadSqftInitialValue = others.tadSqft;
  }

  const initialAppData = {
    project_address: currentAddress,
    project_manager_name: projectManagerName,
    project_manager_email: projectManagerEmail,
    address: currentAddress,
    agency: `${application.newBrand?.organization_name}`,
    agency_address: `${application.newBrand?.address1}, ${application.newBrand?.address2}, ${application.newBrand?.city}, ${application.newBrand?.region} - ${application.newBrand?.zipcode}`,
    tadSqft: tadSqftInitialValue,
  };

  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  if (!API_KEY) throw new Error("Missing google maps api key");

  let mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${addressText + addressTextLineTwo
    }&zoom=15&size=1136x674&maptype=roadmap
  &markers=color:red%7Clabel:%7C${addressText}
  &key=${API_KEY}`;



  let showMap = <Spinner />;

  if (mapURL) {
    showMap = (
      <Flexbox key={Math.random()} height={"100%"} width={"100%"}>
        <ImageTile index={Math.random()} src={mapURL} tag={null} />
      </Flexbox>
    );
  } else {
    showMap = (
      <Flexbox
        flexGrow={1}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Tag
          minimal
          large
          intent={Intent.PRIMARY}
        // text={""}
        // icon={"endorsed"}
        >
          Google Map Not Loaded
        </Tag>
      </Flexbox>
    );
  }

  const createButton = (
    index: number,
    icons: Array<FontAwesomeIcon>,
    label: string,
    onClick?: () => void,
    disabled?: boolean
  ) => {
    let isInteractive = true;
    if (onClick === undefined || disabled) isInteractive = false;

    return (
      <div key={index} >
        {icons}
      </div>
    );
  };

  const erbuttons = [
    createButton(
      0,
      [
        <Flexbox padding={"10px"}>
          <Tag
            minimal={project && project.milestoneErSubmitted ? false : true}
            large
            interactive
            intent={Intent.PRIMARY}
            // text={"ER Submitted"}
            icon={project && project.milestoneErSubmitted ? "endorsed" : "confirm"}
            onClick={() => onErSubmitted()}
          >
            ER Submitted
          </Tag>
        </Flexbox>,
      ],
      "",
      () => {

      }
    ),

    createButton(
      1,
      [
        <Flexbox padding={"10px"}>
          <Tag
            minimal={project && project.milestoneErReleased ? false : true}
            large
            interactive
            intent={Intent.PRIMARY}
            // text={""}
            icon={project && project.milestoneErReleased ? "endorsed" : "confirm"}
            onClick={() => onErReleased()}
          >
            ER Released
          </Tag>
        </Flexbox>,
      ],
      "",
      () => {

      }
    ),

    createButton(
      2,
      [
        <Flexbox padding={"10px"}>
          <Tag
            // minimal
            large
            intent={Intent.PRIMARY}
            interactive
            icon={"document"}
            onClick={() => navigateToFiles(project)}
          >
            Lead Risk Assessment
          </Tag>
        </Flexbox>,
      ],
      "",
      () => {

      }
    ),




  ];
  let city = application.newBrand?.city?.toUpperCase()
  let address_type = application.newBrand?.address_type?.toUpperCase()
  let county = application.newBrand?.county
  let erButtonlist = <Spinner />;
  const layouts = generateLayout(erbuttons);

  erButtonlist = (
    <ResponsiveGridLayout layouts={layouts}
      rowHeight={50}
      margin={[10, 10]}
      breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
      cols={{ lg: 5, md: 4, sm: 4, xs: 1, xxs: 1 }}>
      {erbuttons}
    </ResponsiveGridLayout>);

  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      {true && (
        <ReactToPrint
          trigger={() => (
            <Flexbox marginTop={"20px"} justifyContent={"center"}>
              <Button rightIcon={"print"}>Save/Print PDF</Button>
            </Flexbox>
          )}
          content={() => ref.current}
        />
      )}
      <Formik
        initialValues={errData ? errData : initialAppData}
        onSubmit={async (values) => {
          if (!application) return;
          setState(formStates.SUBMITTING);
          if (others.tadSqft) {
            values.tadSqft = others.tadSqft;
          }
          const update = {
            projectId: project.id,
            er: values,
          };
          await onErUpdate(values);

          setState(formStates.SUCCESS);

          setState(formStates.INPUT);
        }}
      >
        {(Formik) => {
          return (
            <Form ref={ref}>
              <Flexbox
                alignItems={"center"}
                flexDirection={"column"}
                className={styles.inputSection}
              >
                <Card
                  elevation={1}
                  className={`${styles.card} ${styles.hideInPrint}`}
                >
                  {erButtonlist}
                </Card>
                <Card
                  elevation={1}
                  className={`${styles.pageBreak} ${styles.card}`}
                >
                  {logo}
                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>{address_type} OF {city}</b>
                    </p>
                    <p>NEIGHBORHOOD SERVICES DEPARTMENT</p>
                    <p>
                      <i>Environmental Review Request Form</i>
                    </p>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {createInput(
                    "environmental_review_number",
                    "Environmental Review Number",
                    { disable: true }
                  )}
                  {/* {createInput("program_type", "Program Type")} */}

                  {createSelectInput(
                    "program_type",
                    "Program Type",
                    <TextSelect
                      items={[
                        "CTBU",
                        "CDBG - PAH",
                        "PAH Traditional",
                        "Healthy Homes",
                      ]}
                      inputName={"program_type"}
                      disabled={disableEdit}
                    />
                  )}

                  {createInput("project_grant_number", "Project Grant Number", {
                    disable: true,
                  })}
                  {createInput("date_of_revision", "Date of Revision", {
                    disable: true,
                  })}
                  {createInput("name_of_program", "Name of Program/Activity")}
                  {createInput("project_address", "Project Address")}

                  <Flexbox flex={1} alignItems={"left"} width={"100%"}>
                    <h5>Analysis Required</h5>
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "site_visit",
                        "Site Visit?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"site_visit"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "date_of_construction",
                        "Date of Construction?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"date_of_construction"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>

                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "phase_one",
                        "Phase I?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"phase_one"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "demolition",
                        "Demolition?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"demolition"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>

                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "phase_two",
                        "Phase II?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"phase_two"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "new_construction",
                        "New Construction?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"new_construction"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>

                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "noise_analysis",
                        "Noise Analysis?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"noise_analysis"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "major_rehabilitation",
                        "Major Rehabilitation?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"major_rehabilitation"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>

                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "lead",
                        "Lead?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"lead"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "minor_rehabilitation",
                        "Minor Rehabilitation?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"minor_rehabilitation"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>

                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "asbestos",
                        "Asbestos?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"asbestos"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"50%"}>
                      {createSelectInput(
                        "zoning_district",
                        "Zoning District?",
                        <TextSelect
                          items={["Yes", "No"]}
                          inputName={"zoning_district"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Flexbox flex={1} alignItems={"left"} width={"100%"}>
                    <h4 className={`${styles.pageBreak}`}>
                      Project Manager Information
                    </h4>
                  </Flexbox>

                  {createInput("project_manager_name", "Project Manager Name", {
                    fill: false,
                  })}

                  {createInput(
                    "project_manager_email",
                    "Project Manager Email",
                    { fill: false }
                  )}

                  {createInput(
                    "project_manager_phone",
                    "Project Manager Phone",
                    { fill: false }
                  )}

                  {createInput("funding_type", "Funding type")}

                  {createInput("funding_amount", "Funding Amount")}

                  <Flexbox flex={1} alignItems={"left"} width={"100%"}>
                    <h4>Attachments (check all applicable)</h4>
                  </Flexbox>

                  {createSelectInput(
                    "tad_data_sheet",
                    `${county} County Appraisal Data Sheet`,
                    <CheckboxSelect
                      inputName={"tad_data_sheet"}
                      disabled={disableEdit}
                    />
                  )}

                  {createSelectInput(
                    "site_photographs",
                    "Site Photographs - CTBU, Priority I-II, RAMPS, Special Projects, LEAD",
                    <CheckboxSelect
                      inputName={"site_photographs"}
                      disabled={disableEdit}
                    />
                  )}

                  {createSelectInput(
                    "special_projects",
                    "Special Projects: Three alternatives are required. The alternatives must include the alternative of no action.",
                    <CheckboxSelect
                      inputName={"special_projects"}
                      disabled={disableEdit}
                    />
                  )}

                  {createSelectInput(
                    "contamination_checklist",
                    "Contamination Checklist - CTBU, Priority II, RAMPS, Special Projects, LEAD",
                    <CheckboxSelect
                      inputName={"contamination_checklist"}
                      disabled={disableEdit}
                    />
                  )}

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Divider className={styles.fullWidthInput} />

                  {/* </Card> */}

                  {/* <p style={{pageBreakAfter:'always'}}></p> */}

                  {/* <Card elevation={1} className={`${styles.card}`}> */}
                  {/* <p style={{pageBreakAfter:'always'}}></p> */}
                  {logo}
                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>{address_type} OF {city}</b>
                    </p>
                    <p>NEIGHBORHOOD SERVICES DEPARTMENT</p>
                    <p>
                      <i>Environmental Review Request Form</i>
                      <br />
                      <i>
                        Required Description: (If this is a revision, highlight
                        changes from the original request) Capture the maximum
                        anticipated scope of the proposed project or program.
                        Include all contemplated actions and a detailed
                        description. For programs, include the type of services
                        provided and a detailed description. For projects,
                        include type of project (new construction,
                        rehabilitation and/or demolition) number of rooms,
                        amenities, and special features…etc. Also, attach
                        appropriate maps, photographs, site plans and other
                        pertinent information necessary to further explain the
                        development of the site or address(s). What will this
                        project accomplish? Who will benefit from this project?
                      </i>
                    </p>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {createInputArea(
                    "required_description",
                    "",
                    { print: true },
                    Formik.initialValues["required_description"]
                  )}

                  <div>
                    <Divider className={styles.fullWidthInput} />

                    <Flexbox padding={"10px"}>
                      <Flexbox
                        flex={1}
                        flexDirection={"column"}
                        width={"100%"}
                        marginTop={"10px"}
                      >
                        <Flexbox flex={1}>
                          <label htmlFor={"title"}>
                            <b>Project Manager Signature</b>
                          </label>
                        </Flexbox>
                        <Flexbox flex={1}>
                          <SignatureSelect
                            // disabled={reviewReady || completed}
                            disabled={disableEdit}
                            inputName={"project_manager_signature_one"}
                            dateInputName={"project_manager_signature_one_date"}
                          />
                        </Flexbox>
                      </Flexbox>
                    </Flexbox>

                    <Divider className={styles.fullWidthInput} />
                  </div>

                  {/* </Card> */}

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Divider className={styles.fullWidthInput} />

                  {/* <Card elevation={1} className={styles.card}> */}

                  {logo}
                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>{address_type} OF {city}</b>
                    </p>
                    <p>NEIGHBORHOOD SERVICES DEPARTMENT</p>
                    <p>
                      <i>Environmental Review Request Form</i>
                    </p>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <h3>Google Map</h3>
                  </Flexbox>

                  {/* <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <img
                      className={styles.mapContainer}
                      src="https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                    />
                  </Flexbox> */}

                  {showMap}

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <h3>
                      <u>Lead Project Pictures</u>
                    </h3>
                  </Flexbox>

                  {createInput("address", "Address")}

                  {leadProjectPicture}
                  {/* </Card> */}

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {/* <Card elevation={1} className={styles.card}> */}

                  {logo}
                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>{address_type} OF {city}</b>
                    </p>
                    <p>NEIGHBORHOOD SERVICES DEPARTMENT</p>
                    <p>
                      <i>Environmental Review Request Form</i>
                    </p>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <h3>
                      <u>Area Pictures (the neighborhood)</u>
                    </h3>
                  </Flexbox>

                  {areaPictureList}

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {/* </Card>

                <Card elevation={1} className={styles.card}> */}
                  {logo}
                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>{address_type} OF {city}</b>
                    </p>
                    <p>NEIGHBORHOOD SERVICES DEPARTMENT</p>
                    <p>
                      <i>Environmental Review Request Form</i>
                    </p>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <h3>
                      <u>Activity Area Pictures</u>
                    </h3>
                  </Flexbox>
                  {displayActivityAreaPic}

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {/* </Card>

                <Card elevation={1} className={styles.card}> */}
                  {logo}
                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>{address_type} OF {city}</b>
                    </p>
                    <p>NEIGHBORHOOD SERVICES DEPARTMENT</p>
                    <p>
                      <i>Environmental Review Request Form</i>
                    </p>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <h3>
                      <u>TAD.org</u>
                    </h3>
                  </Flexbox>

                  {createInput("tadSqft", "TAD Sqft", {
                    disable: true,
                  })}

                  {showTAD}

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {/* </Card>

                <Card elevation={1} className={styles.card}> */}
                  {logo}
                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>{address_type} OF {city}</b>
                    </p>
                    <p>NEIGHBORHOOD SERVICES DEPARTMENT</p>
                    <p>
                      <i>Environmental Review Request Form</i>
                    </p>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <h3>
                      <u>SITE-SPECIFIC FIELD CONTAMINATION CHECKLIST</u>
                    </h3>
                    <p>
                      <b>
                        Completing the form requires a site visit by the
                        preparer. The preparer should be sure to observe the
                        property by walking through the property and the
                        building(s) and other structures on the property to the
                        extent possible and observing all adjoining* properties.
                      </b>
                    </p>
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      <h5>
                        QUESTION Is there evidence of any of the following?
                      </h5>
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      <h5>SUBJECT PROPERTY</h5>
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      <h5>ADJOINING PROPERTIES</h5>
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Is the property or any adjoining property currently used,
                      or has evidence of prior use, as a gasoline station, motor
                      vehicle repair facility, printing facility, dry cleaners,
                      photo developing laboratory, junkyard, or as a waste
                      treatment, storage, disposal, processing or recycling
                      facility ?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_gasoline_station",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_gasoline_station"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_gasoline_station",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_gasoline_station"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Are there any damaged or discarded automobile(s),
                      automotive or industrial batteries, pesticides, paints, or
                      other chemicals in individual containers greater than 5
                      gal in volume or 50 gal in the aggregate, stored on or
                      used at the property or adjoining properties?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_damaged_automotive",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_damaged_automotive"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_damaged_automotive",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_damaged_automotive"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Has fill dirt been brought onto the property or adjoining
                      properties that originated from a suspicious site or that
                      is of an unknown origin?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_fill_dirt",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_fill_dirt"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_fill_dirt",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_fill_dirt"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Are there any pits, ponds, or lagoons located on the
                      property or adjoining properties in connection with waste
                      treatment or waste disposal?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_pits_ponds",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_pits_ponds"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_pits_ponds",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_pits_ponds"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Is there any stained soil, distressed vegetation and/or
                      discolored water on the property or adjoining properties?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_stained_soil",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_stained_soil"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_stained_soil",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_stained_soil"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Are there any storage tanks, aboveground or underground
                      (other than residential), located on the property or
                      adjoining properties?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_storage_tanks",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_storage_tanks"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_storage_tanks",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_storage_tanks"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Are there any vent pipes, fill pipes, or underground tank
                      access ways visible on the property or adjoining
                      properties?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_vent_pipes",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_vent_pipes"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_vent_pipes",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_vent_pipes"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Are any flooring, drains, walls, ceilings, or grounds on
                      the property or adjoining properties stained by substances
                      (other than water) or emitting noxious or foul odors or
                      odors of a chemical nature?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_flooring_drains",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_flooring_drains"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_flooring_drains",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_flooring_drains"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Is the property served by a private well or non-public
                      water system? (If yes, a follow-up investigation is
                      required to determine if contaminants have been identified
                      in the well or system that exceed guidelines applicable to
                      the water system, or if the well has been designated
                      contaminated by any government environmental/health
                      agency.)
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_property_served",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_property_served"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_property_served",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_property_served"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Has the owner or occupant of the property been informed of
                      the existence of past or current hazardous substances or
                      petroleum products or environmental violations with
                      respect to the property or adjoining properties?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_owner",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_owner"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_owner",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_owner"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Do the property or adjoining properties discharge
                      wastewater (not including sanitary waste or storm water)
                      onto the property or adjoining properties and/or into a
                      storm water system?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_property",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_property"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "adj_property",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_property"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                    flexWrap={"wrap"}
                    className={styles.inputSection}
                  >
                    <Flexbox width={"50%"} padding={"10px"}>
                      Is there a transformer, capacitor, or any hydraulic
                      equipment on the property or adjoining properties that are
                      not marked as “non-PCB”?
                    </Flexbox>

                    <Flexbox width={"25%"} padding={"10px"}>
                      {createSelectInput(
                        "sub_transformer",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"sub_transformer"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                    <Flexbox width={"25%"} padding={"10px"}>


                      {createSelectInput(
                        "adj_transformer",
                        "",
                        <TextSelect
                          items={["YES", "NO", "UNKNOWN"]}
                          inputName={"adj_transformer"}
                          disabled={disableEdit}
                        />
                      )}
                    </Flexbox>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {createConditionInputArea(
                    "condition",
                    "If answering “YES” or UNKNOWN” to any above items, describe the conditions",
                    //{ fill: false }
                    { print: true },
                    Formik.initialValues["condition"]

                  )}



                  {createSelectInput(
                    "warranted",
                    "Is further evaluation warranted?",
                    <TextSelect
                      items={["YES", "NO", "UNKNOWN"]}
                      inputName={"warranted"}
                      disabled={disableEdit}
                    />
                  )}

                  <p style={{ pageBreakAfter: "always" }}></p>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>
                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>
                        Preparer of this form must complete the following
                        required information
                      </b>
                    </p>
                  </Flexbox>

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox flex={1} alignItems={"left"} width={"100%"}>
                    <h5>This inspection was completed by</h5>
                  </Flexbox>

                  {createInput("name", "Name", {
                    disable: false,
                  })}

                  {createInput("phone_number", "Phone Number", {
                    disable: false,
                  })}

                  {createInput("email", "Email", {
                    disable: false,
                  })}

                  {createInput("title", "Title", {
                    disable: false,
                  })}

                  {createInput("agency", "Agency", {
                    disable: true,
                  })}

                  {createInput("agency_address", "Address", {
                    disable: true,
                  })}

                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  <Flexbox
                    flex={1}
                    alignItems={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <p>
                      <b>
                        Preparer represents that to the best of his/her
                        knowledge the above statements and facts are true and
                        correct and to the best of his/her actual knowledge no
                        material facts have been suppressed, omitted or
                        misstated
                      </b>
                    </p>
                  </Flexbox>

                  <div>
                    <Divider className={styles.fullWidthInput} />

                    <Flexbox padding={"10px"}>
                      <Flexbox
                        flex={1}
                        flexDirection={"column"}
                        width={"100%"}
                        marginTop={"10px"}
                      >
                        <Flexbox flex={1}>
                          <label htmlFor={"title"}>
                            <b>Signature</b>
                          </label>
                        </Flexbox>
                        <Flexbox flex={1}>
                          <SignatureSelect
                            // disabled={reviewReady || completed}
                            disabled={disableEdit}
                            inputName={"signed_by"}
                            dateInputName={"signed_by_signature_date"}
                          />
                        </Flexbox>
                      </Flexbox>
                    </Flexbox>

                    <Divider className={styles.fullWidthInput} />
                  </div>
                </Card>

                <Flexbox flex={1} marginTop={"15px"}>
                  {true && (
                    <Button
                      onClick={() => {
                        Formik.handleSubmit();
                        // setIsGoBack(false);

                        // AppToaster.show({
                        //   message: "Application Updated",
                        //   intent: Intent.PRIMARY,
                        // });
                      }}
                      intent={Intent.PRIMARY}
                      className={styles.saveChangesButton}
                      large
                      rightIcon={state === formStates.INPUT ? "upload" : null}
                      disabled={state !== formStates.INPUT || disableEdit}
                      loading={state === formStates.SUBMITTING}
                      icon={state === formStates.SUCCESS ? "saved" : null}
                    >
                      {state === formStates.INPUT ? "Save Changes" : null}
                    </Button>
                  )}
                </Flexbox>
              </Flexbox>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

type ImageTileProps = {
  src: string,
  tag: ?string,
  index: number,
};

function ImageTile(props: ImageTileProps) {
  const { src, tag, index } = props;
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (src) {
      const newImage = new Image();

      newImage.onload = () => {
        setImage(newImage);
      };

      newImage.onerror = () => {
        setError(true);
      };

      newImage.src = src;
    }
  }, [src]);

  return (
    <Flexbox
      key={index}
      flex={1}
      alignItems={"center"}
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
    >
      {image ? (
        <>
          <img className={styles.mapContainer} src={image.src} />
          <Flexbox
            flex={1}
            alignItems={"center"}
            flexDirection={"column"}
            width={"100%"}
            height={"10%"}
            marginTop={"1%"}
            marginBottom={"1%"}
          >
            {tag ? <Tag>{tag.replace("C_", " ").trim()}</Tag> : null}
          </Flexbox>
        </>
      ) : (
        <Spinner />
      )}
    </Flexbox>
  );
}
