//@flow

import styles from "./CreateProjectRequestBuilder.module.css";
import React, {useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, Field, Form, useField } from "formik";
import { Button, MenuItem, Spinner, Intent } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { InputGroup, Divider, Card } from "@blueprintjs/core";
import {
  CreateProjectRequest,
  Address,
  Home,
  Homeowner,
  UpdateProjectRequests
} from "../../models/Project/Project";
import User from "../../models/User/User";
import UserSuggest from "../UserSuggest/UserSuggest";
import ProjectGroupSuggest from "../ProjectGroupSuggest/ProjectGroupSuggest";
import ProjectGroup from "../../models/ProjectGroup/ProjectGroup";
import DeleteConfirmationPopup from "../ConfirmationAlert/ConfirmationAlert";
import FocusError from "../FocusError/FocusError";
import { AppToaster } from "../../components/Toaster/Toaster";

type Props = {
  onNewProjectCreate: (projectToCreate: CreateProjectRequest) => Promise<any>,
  onNewProjectCreated: () => void,
  projectManagers: ?Array<User>,
  projectAdmins:?Array<User>,
  projectGroups: ?Array<ProjectGroup>,

  project: ?Project,
  buttonTitle: ?string
};
type CountryProps = {
  countryInputName: string,
  stateInputName: string,
  disabled?: boolean
};


const zipRegex = /^(?!0{3})[0-9]{3,5}$/;
const phoneRegExp = /^[1-9]\d{2}-\d{3}-\d{4}$/;

function CountryStateDropdownInput(props: CountryProps) {
  // this will return field props for an <input />
  const [countryField, countryMeta] = useField(props.countryInputName);
  const [stateField, stateMeta] = useField(props.stateInputName);

  const countryError =
    countryMeta.error && countryMeta.touched ? (
      <div className={styles.errorText}>{countryMeta.error}</div>
    ) : (
      <div />
    );
  const stateError =
    stateMeta.error && stateMeta.touched ? (
      <div className={styles.errorText}>{stateMeta.error}</div>
    ) : (
      <div />
    );

  return (
    <Flexbox flex={1} flexDirection={"column"}>
      <Flexbox flex={1} flexDirection={"column"}>
        <CountryDropdown
          disabled={true} //no other country supported as of now
          classes={styles.fullWidthInput}
          value={countryField.value}
          onChange={(newValue: string) => {
            const event = {
              target: {
                name: countryField.name,
                value: newValue
              }
            };
            countryField.onChange(event);
          }}
        />
        {countryError}
      </Flexbox>
      <Flexbox flex={1} flexDirection={"column"} marginTop={"10px"}>
        <RegionDropdown
          disabled={props.disabled}
          classes={styles.fullWidthInput}
          country={countryField.value}
          value={stateField.value}
          onChange={(newValue: string) => {
            const event = {
              target: {
                name: stateField.name,
                value: newValue
              }
            };
            stateField.onChange(event);
          }}
        />
        {stateError}
      </Flexbox>
    </Flexbox>
  );
}

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

export default function CreateProjectRequestBuilder(props: Props) {
  const [state, setState] = useState(formStates.INPUT);
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [updateProject, setUpdateProject] = useState(null);
  const { project, buttonTitle } = props;

  const currentProjectGroups = project && project.projectGroups.map(data => {
    return data.id;
  })
  const task_list_name_id = project && project.task_list_name_id;
  const homeowner = project && project.home.homeowner;
  const address = project && project.home.address;
  const userId = project && project.assignedUserId;
  const assignedProjectAdmin = project && project.assignedProjectAdmin;
  const projectGroups =
    project && project.projectGroups ? project.projectGroups : [];
  const { firstName, lastName, phoneNumber, email } = homeowner
    ? homeowner
    : "";
  const { streetAddress, streetAddressTwo, city, zip, country } = address
    ? address
    : "";
  const checkForTaskList = () =>{
    console.log("Task list on create project",props.latestTaskList)
    if(!(props.latestTaskList && props.latestTaskList.length > 1)){
      AppToaster.show({
        message: "Please create duplicate task list from task and pricing.",
        intent: Intent.DANGER,
      });
    }
    else{
    }
  }
  useEffect(() => {
    checkForTaskList()
 }, []);
  const createInput = (
    inputName: string,
    inputLabel: string,
    type?: string,
    placeHolderName?:string
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>
          <Field
            className={`bp3-large`}
            as={InputGroup}
            name={inputName}
            placeholder = {placeHolderName}
            type="text"
            fill
            disabled={state !== formStates.INPUT}
          />
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Ok"
      icon="confirm"
      type={Intent.WARNING}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={ () => {
         props.onNewProjectCreate(updateProject);
        setConfirmationPopup(false);
      }}
      message="This Project is in APP DENIED status.
      On change of Project Group, 
      The project status, it's milestones and Homeowner application status will reset.
      Do you want to confirm the action?"
    />
  );
  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
          phoneNumber,
          streetAddress,
          streetAddressTwo,
          city,
          zip,
          state: address ? address.state : "",
          country: "United States",
          // userId: "",
          projectGroups,
          //   currentProjectGroup,
          userId,
          assignedProjectAdmin,
        }}
        enableReinitialize
        // initialValues={application.applicationData}
        validationSchema={Yup.object({
          firstName: Yup.string().required("Required"),
          lastName: Yup.string().required("Required"),
          email: Yup.string().nullable().email("Invalid email address"),
          phoneNumber: Yup.string()
            .matches(phoneRegExp, "Invalid phone number")
            .required("Required"),
          streetAddress: Yup.string().required("Required"),
          streetAddressTwo: Yup.string().nullable(),
          city: Yup.string().required("Required"),
          state: Yup.string().required("Required"),
          zip: Yup.string()
            .max(10)
            .matches(zipRegex, { message: "Invalid zip code" })
            .required("Required"),
          country: Yup.string().required("Required"),
          userId: Yup.string().required("Required"),
          assignedProjectAdmin: Yup.string().required("Required"),
        })}
        onSubmit={async values => {
          const address = new Address(
            values.streetAddress,
            values.streetAddressTwo ? values.streetAddressTwo : "",
            values.city,
            values.state,
            values.country,
            values.zip
          );
          const homeowner = new Homeowner(
            values.firstName,
            values.lastName,
            values.email,
            values.phoneNumber
          );
          const home = new Home(address, homeowner);
          const userId = values.userId.length === 0 ? null : values.userId;
          const assignedProjectAdmin = values.assignedProjectAdmin.length === 0 ? null : values.assignedProjectAdmin;
          const task_list_name_id = props.latestTaskListId;
          const createProjectRequest = new CreateProjectRequest(
            home,
            userId,
            values.projectGroups,
            assignedProjectAdmin,
            task_list_name_id
          );
          if (project && project.id) {
            const updateProjectRequest = new UpdateProjectRequests(
              project.id,
              home,
              userId,
              values.projectGroups,
              true,
              assignedProjectAdmin,
              task_list_name_id
            );
            setState(formStates.INPUT);
            
            const updatedProjectGroups = values.projectGroups.map(data => {
              return data.id;
            })
          
            if(project.status === "APP_DENIED" && JSON.stringify(currentProjectGroups) !== JSON.stringify(updatedProjectGroups)){
                setConfirmationPopup(true)
                setUpdateProject(updateProjectRequest)
            }
            else{
              await props.onNewProjectCreate(updateProjectRequest);
            }
          } else {
            await props.onNewProjectCreate(createProjectRequest);
          }
          setState(formStates.INPUT);
          setTimeout(() => {
            props.onNewProjectCreated();
          }, 500);
        }}
      >
        <Form>
          <Flexbox
            alignItems={"center"}
            flexDirection={"column"}
            className={styles.inputSection}
          >
            <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Owner Information</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>

              {createInput("firstName", "First Name")}
              {createInput("lastName", "Last Name")}
              {createInput("phoneNumber", "Phone Number", "phone","222-222-2222")}
              {createInput("email", "Email", "email")}
            </Card>

            <Card elevation={1} className={styles.card}>
              <Flexbox
                flex={1}
                alignItems={"flex-start"}
                width={"100%"}
                className={styles.inputSection}
              >
                <h3>Home Information</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>

              {createInput("streetAddress", "Street Address")}
              {createInput("streetAddressTwo", "Street Address Line 2")}
              {createInput("city", "City")}
              {createInput("zip", "Zip Code", "number")}
              <Flexbox
                flex={1}
                width={"100%"}
                paddingTop={"10px"}
                paddingBottom={"10px"}
                marginTop={"15px"}
              >
                <CountryStateDropdownInput
                  countryInputName={"country"}
                  stateInputName={"state"}
                  disabled={state !== formStates.INPUT}
                />
              </Flexbox>
            </Card>

            <Card elevation={1} className={styles.card}>
            <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
              <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
                <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                  <h5>Assigned Project Manager</h5>
                </Flexbox>
                <Flexbox
                  flex={1}
                  alignItems={"flex-start"}
                  width={"100%"}
                  className={styles.inputSection}
                >
                  <UserSuggest
                    inputName={"userId"}
                    users={props.projectManagers}
                    selectedItem={userId}
                  />
                </Flexbox>
                <Flexbox className={styles.errorText} flex={1}>
                  <ErrorMessage
                    name={"userId"}
                    render={msg => <div>Required</div>}
                  />
                </Flexbox>
              </Flexbox>
              <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
                <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                  <h5>Assigned Project Admin</h5>
                </Flexbox>
                <Flexbox
                  flex={1}
                  alignItems={"flex-start"}
                  width={"100%"}
                  className={styles.inputSection}
                >
                  <UserSuggest
                    inputName={"assignedProjectAdmin"}
                    users={props.projectAdmins}
                    selectedItem={assignedProjectAdmin}
                  />
                </Flexbox>
                <Flexbox className={styles.errorText} flex={1}>
                  <ErrorMessage
                    name={"assignedProjectAdmin"}
                    render={msg => <div>Required</div>}
                  />
                </Flexbox>
              </Flexbox>
              </Flexbox>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h5>Project Groups</h5>
              </Flexbox>
              <Flexbox
                flex={1}
                alignItems={"flex-start"}
                width={"100%"}
                className={styles.inputSection}
              >
                <ProjectGroupSuggest
                  inputName={"projectGroups"}
                  projectGroups={props.projectGroups}
                  selectedItems={projectGroups}
                />
              </Flexbox>
            </Card>

            <Flexbox flex={1} marginTop={"15px"}>
              <Button
                type="submit"
                className={"bp3-large"}
                rightIcon={state === formStates.INPUT ? "arrow-right" : null}
                disabled={state !== formStates.INPUT || !(props.latestTaskList && props.latestTaskList.length > 1)}
                loading={state === formStates.SUBMITTING}
                icon={state === formStates.SUCCESS ? "saved" : null}
              >
                {state === formStates.INPUT
                  ? buttonTitle
                    ? buttonTitle
                    : "Create"
                  : null}
              </Button>
            </Flexbox>
          </Flexbox>
          <FocusError/>
        </Form>
      </Formik>
      {confirmPopup}
    </div>
  );
}
