// @flow

import AssignedTask, {
  UpdateAssignedTaskRequest,
} from "../AssignedTask/AssignedTask";
import { GetAttachmentResponse } from "../Attachment/Attachment";
import moment from "moment";
import ProjectGroup from "../ProjectGroup/ProjectGroup";
import Vendor from "../Vendor/Vendor";

export class Address {
  streetAddress: string;
  streetAddressTwo: string;
  city: string;
  state: string;
  country: string;
  zip: string;

  constructor(
    streetAddress: string,
    streetAddressTwo: string,
    city: string,
    state: string,
    country: string,
    zip: string
  ) {
    this.streetAddress = streetAddress;
    this.streetAddressTwo = streetAddressTwo;
    this.city = city;
    this.state = state;
    this.country = country;
    this.zip = zip;
  }
}

export class Home {
  address: Address;
  homeowner: Homeowner;

  constructor(address: Address, homeowner: Homeowner) {
    this.address = address;
    this.homeowner = homeowner;
  }
}

export class Homeowner {
  firstName: string;
  lastName: string;
  email: ?string;
  phoneNumber: string;

  constructor(
    firstName: string,
    lastName: string,
    email: ?string,
    phoneNumber: string
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    if (email) this.email = email;
    this.phoneNumber = phoneNumber;
  }
}

export class CreateProjectRequest {
  home: Home;
  assignedUserId: ?string;
  projectGroups: ?Array<ProjectGroup>;
  assignedProjectAdmin: ?string;
  task_list_name_id: ?String;

  constructor(
    home: Home,
    assignedUserId: ?string,
    projectGroups: Array<ProjectGroup>,
    assignedProjectAdmin: ?string,
    task_list_name_id: ?string
  ) {
    this.home = home;
    if (assignedUserId) this.assignedUserId = assignedUserId;
    if (projectGroups) this.projectGroups = projectGroups;
    if (assignedProjectAdmin) this.assignedProjectAdmin = assignedProjectAdmin;
    if (task_list_name_id) this.task_list_name_id = task_list_name_id;
  }
}

export class UpdateProjectRequests {
  id: string;
  home: Home;
  assignedUserId: ?string;
  projectGroups: ?Array<ProjectGroup>;
  fromEditProject: ?boolean;
  assignedProjectAdmin: ?string;
  task_list_name_id: ?String;

  constructor(
    id: string,
    home: Home,
    assignedUserId: ?string,
    projectGroups: Array<ProjectGroup>,
    fromEditProject: boolean,
    assignedProjectAdmin: ?string,
    task_list_name_id: ?string
  ) {
    this.id = id;
    this.home = home;
    if (assignedUserId) this.assignedUserId = assignedUserId;
    if (projectGroups) this.projectGroups = projectGroups;
    if (fromEditProject) this.fromEditProject = fromEditProject;
    if (assignedProjectAdmin) this.assignedProjectAdmin = assignedProjectAdmin;
    if (task_list_name_id) this.task_list_name_id = task_list_name_id;
  }
}

export const ProjectStatuses = Object.freeze({
  CREATED: "CREATED",
  APP_STARTED: "APP_STARTED",
  APP_SUBMITTED: "APP_SUBMITTED",
  APP_APPROVED: "APP_APPROVED",
  APP_DENIED: "APP_DENIED",
  APP_FINISHED: "APP_FINISHED",
  SURVEY_COMPLETED: "SURVEY_COMPLETED",
  ER_SUBMITTED: "ER_SUBMITTED",
  ER_RELEASED: "ER_RELEASED",
  RISK_ASSESSMENT_SUBMITTED: "RISK_ASSESSMENT_SUBMITTED",
  VENDORS_SELECTED: "VENDORS_SELECTED",
  WORK_STARTED: "WORK_STARTED",
  WORK_COMPLETED: "WORK_COMPLETED",
  INVOICES_SUBMITTED: "INVOICES_SUBMITTED",
  INVOICES_PAID: "INVOICES_PAID",
  REIMBURSEMENT_SUBMITTED: "REIMBURSEMENT_SUBMITTED",
  REIMBURSEMENT_RECEIVED: "REIMBURSEMENT_RECEIVED",
  COMPLETED: "COMPLETED",
  HFHI: "HFHI",
});

export type ProjectStatusesTypes = $Keys<typeof ProjectStatuses>;

export const ProjectStates = Object.freeze({
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  REJECTED: "REJECTED",
});

export type ProjectStatesTypes = $Keys<typeof ProjectStates>;

export default class Project {
  id: string;
  home: Home;
  status: ProjectStatusesTypes;
  projectState: String;
  assignedUserId: ?string;
  coverPhoto: ?GetAttachmentResponse;
  projectGroups: ?Array<ProjectGroup>;
  vendors: ?Array<Vendor>;

  milestoneHomeownerAppStarted: ?string;
  milestoneHomeownerAppSubmitted: ?string;
  milestoneHomeownerAppApproved: ?string;
  milestoneHomeownerAppDenied: ?string;
  milestoneHomeownerAppFinished: ?string;
  milestoneSurveyCompleted: ?string;
  milestoneErSubmitted: ?string;
  milestoneErReleased: ?string;
  milestoneRiskAssessmentSubmitted: ?string;
  milestoneVendorsSelected: ?string;
  milestoneWorkStarted: ?string;
  milestoneWorkCompleted: ?string;
  milestoneProjectCompleted: ?string;
  milestoneHFHI: ?String;
  estimation: ?string;

  constructor(
    id: string,
    home: Home,
    status: projectStateesTypes,
    projectState: String,
    assignedUserId: ?string,
    coverPhoto: ?GetAttachmentResponse,
    milestoneHomeownerAppStarted: ?string,
    milestoneHomeownerAppSubmitted: ?string,
    milestoneHomeownerAppApproved: ?string,
    milestoneHomeownerAppDenied: ?string,
    milestoneHomeownerAppFinished: ?string,
    milestoneSurveyCompleted: ?string,
    milestoneErSubmitted: ?string,
    milestoneErReleased: ?string,
    milestoneRiskAssessmentSubmitted: ?string,
    milestoneVendorsSelected: ?string,
    milestoneWorkStarted: ?string,
    milestoneWorkCompleted: ?string,
    milestoneProjectCompleted: ?string,
    milestoneHFHI: ?String,
    projectGroups: ?Array<ProjectGroup>,
    vendors: ?Array<Vendor>,
    estimation: string
  ) {
    this.id = id;
    this.home = home;
    this.status = status;
    this.projectState = projectState;
    this.assignedUserId = assignedUserId;
    if (coverPhoto) this.coverPhoto = coverPhoto;
    this.milestoneHomeownerAppStarted = milestoneHomeownerAppStarted;
    this.milestoneHomeownerAppSubmitted = milestoneHomeownerAppSubmitted;
    this.milestoneHomeownerAppApproved = milestoneHomeownerAppApproved;
    this.milestoneHomeownerAppDenied = milestoneHomeownerAppDenied;
    this.milestoneHomeownerAppFinished = milestoneHomeownerAppFinished;
    this.milestoneSurveyCompleted = milestoneSurveyCompleted;
    this.milestoneErSubmitted = milestoneErSubmitted;
    this.milestoneErReleased = milestoneErReleased;
    this.milestoneRiskAssessmentSubmitted = milestoneRiskAssessmentSubmitted;
    this.milestoneVendorsSelected = milestoneVendorsSelected;
    this.milestoneWorkStarted = milestoneWorkStarted;
    this.milestoneWorkCompleted = milestoneWorkCompleted;
    this.milestoneProjectCompleted = milestoneProjectCompleted;
    this.milestoneHFHI = milestoneHFHI;
    this.projectGroups = projectGroups;
    this.vendors = vendors;
    this.estimation = estimation;
  }
}

export function applyProjectUpdate(
  project: Project,
  update: UpdateProjectRequest
) {
  const copy = new Project(
    project.id,
    project.home,
    project.status,
    project.assignedUserId,
    project.task_list_name_id
  );

  if (update.status) copy.status = update.status;
  if (update.assignedUserId) copy.assignedUserId = update.assignedUserId;
  if (update.task_list_name_id)
    copy.task_list_name_id = update.task_list_name_id;
  if (update.home) {
    if (update.home.homeowner) {
      if (update.home.homeowner.firstName)
        copy.home.homeowner.firstName = update.home.homeowner.firstName;
      if (update.home.homeowner.lastName)
        copy.home.homeowner.lastName = update.home.homeowner.lastName;
      if (update.home.homeowner.email)
        copy.home.homeowner.email = update.home.homeowner.email;
      if (update.home.homeowner.phoneNumber)
        copy.home.homeowner.phoneNumber = update.home.homeowner.phoneNumber;
    }
    if (update.home.address) {
      if (update.home.address.streetAddress)
        copy.home.address.streetAddress = update.home.address.streetAddress;
      if (update.home.address.streetAddressTwo)
        copy.home.address.streetAddressTwo =
          update.home.address.streetAddressTwo;
      if (update.home.address.city)
        copy.home.address.city = update.home.address.city;
      if (update.home.address.state)
        copy.home.address.state = update.home.address.state;
      if (update.home.address.zip)
        copy.home.address.zip = update.home.address.zip;
      if (update.home.address.country)
        copy.home.address.country = update.home.address.country;
    }
  }

  copy.created_at = project.created_at;
  copy.updated_at = moment();

  return copy;
}

export type UpdateHomeownerRequest = {
  firstName?: string,
  lastName?: string,
  email: ?string,
  phoneNumber?: string,
};

export type UpdateAddressRequest = {
  streetAddress?: string,
  streetAddressTwo: ?string,
  city?: string,
  state?: string,
  country?: string,
  zip?: string,
};

export type UpdateHomeRequest = {
  id: string,
  address?: UpdateAddressRequest,
  addressId?: string,
  homeowner?: UpdateHomeownerRequest,
  homeownerId?: string,
};

export type UpdateProjectRequest = {
  home?: UpdateHomeRequest,
  homeId?: string,
  assignedUserId: ?string,
  status?: ProjectStatusesTypes,
  coverPhoto?: string,
  fromEditProject: boolean,
  projectState: string,
  task_list_name_id: ?string,
};
