import NotesApi from "../../api/NotesApi/NotesApi";
import ProjectGroupService from "../ProjectGroupService/ProjectGroupService";

class NotesService {
  notesApi: NotesApi;
  projectGroupService: ProjectGroupService;

  constructor(
    notesApi: NotesApi,
    projectGroupService: ProjectGroupService
  ) {
    this.notesApi = notesApi;
    this.projectGroupService = projectGroupService;
  }

  async getAllNotes(metaData: any): Promise<Array> {
    return await this.notesApi.getAll(metaData);
  }

  async createNote(createNoteRequest): Promise<any> {
    const note = await this.notesApi.createNote(createNoteRequest);
    return note;
  }

  async addAttachments(createNoteRequest): Promise<any> {
    const note = await this.notesApi.addAttachments(createNoteRequest);
    return note;
  }

  async updateNote(updateNoteRequest, noteId): Promise<any> {
    const note = await this.notesApi.updateNote(updateNoteRequest, noteId);
    return note;
  }

  async deleteNote(noteId): Promise<any> {
    const note = await this.notesApi.deleteNote(noteId);
    return note;
  }
}

export default NotesService;
