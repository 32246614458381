//@flow

import styles from "./CreateVendorRequestBuilder.module.css";
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, Field, Form, useField } from "formik";
import { Button } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { InputGroup, Divider, Card } from "@blueprintjs/core";
import {
  CreateVendorRequest,
  UpdateVendorRequest
} from "../../models/Vendor/Vendor";
import { Address } from "../../models/Project/Project";

type Props = {
  onNewVendorCreate: (vendorToCreate: CreateVendorRequest) => Promise<any>,
  onUpdateVendor: (vendorToUpdate: CreateVendorRequest) => Promise<any>,
  onNewVendorCreated: () => void,
  submitButtonName: string,
  vendorData: Array<any>
};
type CountryProps = {
  countryInputName: string,
  stateInputName: string,
  disabled?: boolean
};

// const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const zipRegex = /^(?!0{3})[0-9]{3,5}$/;
const phoneRegExp = /^(?!0{10})[0-9]{2}[0-9]{8}$/;

function CountryStateDropdownInput(props: CountryProps) {
  // this will return field props for an <input />
  const [countryField, countryMeta] = useField(props.countryInputName);
  const [stateField, stateMeta] = useField(props.stateInputName);

  const countryError =
    countryMeta.error && countryMeta.touched ? (
      <div className={styles.errorText}>{countryMeta.error}</div>
    ) : (
      <div />
    );
  const stateError =
    stateMeta.error && stateMeta.touched ? (
      <div className={styles.errorText}>{stateMeta.error}</div>
    ) : (
      <div />
    );

  return (
    <Flexbox flex={1} flexDirection={"column"}>
      <Flexbox flex={1} flexDirection={"column"}>
        <CountryDropdown
          disabled={true} //no other country supported as of now
          classes={styles.fullWidthInput}
          value={countryField.value}
          onChange={(newValue: string) => {
            const event = {
              target: {
                name: countryField.name,
                value: newValue
              }
            };
            countryField.onChange(event);
          }}
        />
        {countryError}
      </Flexbox>
      <Flexbox flex={1} flexDirection={"column"} marginTop={"10px"}>
        <RegionDropdown
          disabled={props.disabled}
          classes={styles.fullWidthInput}
          country={countryField.value}
          value={stateField.value}
          onChange={(newValue: string) => {
            const event = {
              target: {
                name: stateField.name,
                value: newValue
              }
            };
            stateField.onChange(event);
          }}
        />
        {stateError}
      </Flexbox>
    </Flexbox>
  );
}

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

export default function CreateVendorRequestBuilder(props: Props) {
  const [state, setState] = useState(formStates.INPUT);
  const { vendorData, submitButtonName } = props;
  const [contact_type, setContact_type] = useState(vendorData && vendorData.contactType ? vendorData.contactType : "Select Contact Type");
  const [selectContactTypeError, setSelectContactTypeError] = useState(false);
  const createInput = (
    inputName: string,
    inputLabel: string,
    type?: string
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>
          <Field
            className={`bp3-large`}
            as={InputGroup}
            name={inputName}
            type="text"
            fill
            disabled={state !== formStates.INPUT}
          />
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSelectInput = (
    inputName: string,
    inputLabel: string
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>
          <select onChange={(event)=>{
            if(selectContactTypeError) setSelectContactTypeError(false);
               setContact_type(event.target.value)
            }}
            name={inputName} style={{width: '100%', height: 35}} className={'bp3-fill'}>
            <option selected={contact_type == "Select Contact Type"} value="Select Contact Type">Select Contact Type</option>
            <option selected={contact_type == "Vendor"} value="Vendor">Vendor</option>
            <option selected={contact_type == "Donor" ? true : false} value="Donor">Donor</option>
            <option selected={contact_type == "Other"} value="Other">Other</option>
          </select>
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          {selectContactTypeError ? "Please select Contact Type" : null}
        </Flexbox>
      </Flexbox>
    );
  };

  let initialValues;

  if (vendorData) {
    initialValues = {
      name: vendorData.name,
      email: vendorData.email,
      phoneNumber: vendorData.phoneNumber,
      streetAddress: vendorData.address.streetAddress,
      streetAddressTwo: vendorData.address.streetAddressTwo,
      city: vendorData.address.city,
      state: vendorData.address.state,
      country: vendorData.address.country,
      zip: vendorData.address.zip,
    };
  } else {
    initialValues = {
      name: "",
      email: "",
      phoneNumber: "",
      streetAddress: "",
      streetAddressTwo: "",
      city: "",
      state: "",
      country: "United States"
    };
  }

  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          phoneNumber: Yup.string()
            .matches(phoneRegExp, "Invalid phone number")
            .required("Required"),
          streetAddress: Yup.string().required("Required"),
          streetAddressTwo: Yup.string(),
          city: Yup.string().required("Required"),
          state: Yup.string().required("Required"),
          zip: Yup.string()
            .max(10)
            .matches(zipRegex, { message: "Invalid zip code" })
            .required("Required"),
          country: Yup.string().required("Required")
        })}
        onSubmit={async values => {
          if(contact_type == "Select Contact Type"){
            return setSelectContactTypeError(true);
          }
          const address = new Address(
            values.streetAddress,
            values.streetAddressTwo,
            values.city,
            values.state,
            values.country,
            values.zip
          );
          const createVendorRequest = new CreateVendorRequest(
            values.name,
            values.email,
            values.phoneNumber,
            address,
            contact_type
          );

          setState(formStates.INPUT);

          if (vendorData) {
            const updateVendorData = new UpdateVendorRequest(
              vendorData.id,
              values.name,
              values.email,
              values.phoneNumber,
              address,
              contact_type
            );

            await props.onUpdateVendor(updateVendorData);
            setState(formStates.INPUT);
            setTimeout(() => {
              props.onNewVendorCreated();
            }, 500);
          } else {
            await props.onNewVendorCreate(createVendorRequest);

            setState(formStates.INPUT);
            setTimeout(() => {
              props.onNewVendorCreated();
            }, 500);
          }
        }}
      >
        <Form>
          <Flexbox
            alignItems={"center"}
            flexDirection={"column"}
            className={styles.inputSection}
          >
            <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Contact Information</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>

              {createInput("name", "Contact Name")}
              {createInput("phoneNumber", "Phone Number", "phone")}
              {createInput("email", "Email", "email")}
              {createSelectInput("contact_type","Contact Type")}
            </Card>

            <Card elevation={1} className={styles.card}>
              <Flexbox
                flex={1}
                alignItems={"flex-start"}
                width={"100%"}
                className={styles.inputSection}
              >
                <h3>Contact Information</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>

              {createInput("streetAddress", "Street Address")}
              {createInput("streetAddressTwo", "Street Address Line 2")}
              {createInput("city", "City")}
              {createInput("zip", "Zip Code", "number")}
              <Flexbox
                flex={1}
                width={"100%"}
                paddingTop={"10px"}
                paddingBottom={"10px"}
                marginTop={"15px"}
              >
                <CountryStateDropdownInput
                  countryInputName={"country"}
                  stateInputName={"state"}
                  disabled={state !== formStates.INPUT}
                />
              </Flexbox>
            </Card>

            <Flexbox flex={1} marginTop={"15px"}>
              <Button
                type="submit"
                className={"bp3-large"}
                rightIcon={state === formStates.INPUT ? "arrow-right" : null}
                disabled={state !== formStates.INPUT}
                loading={state === formStates.SUBMITTING}
                icon={state === formStates.SUCCESS ? "saved" : null}
              >
                {state === formStates.INPUT ? submitButtonName : null}
              </Button>
            </Flexbox>
          </Flexbox>
        </Form>
      </Formik>
    </div>
  );
}
