//@Flow
import { BehaviorSubject, PartialObserver, Subject } from 'rxjs'
import AuthService from "../service/AuthService/AuthService";
import history from "../history";

export const AuthStates = Object.freeze({
    UNAUTHENTICATED: Symbol("UNAUTHENTICATED"),
    FORBIDDEN: Symbol("FORBIDDEN"),
    AUTHENTICATED: Symbol("AUTHENTICATED"),
    AUTHENTICATING: Symbol("AUTHENTICATING"),
    UNINITIALIZED: Symbol("UNINITIALIZED")
});

export type AuthStatesTypes = $Keys<typeof AuthStates>;

export const AuthEvents = Object.freeze({
    LOGIN: Symbol("LOGIN"),
    LOGOUT: Symbol("LOGOUT"),
});

export type AuthEventsTypes = $Keys<typeof AuthEvents>;

export const AuthRoles = Object.freeze({
    ADMIN: Symbol("Admin"),
    PROJECT_MANAGER: Symbol("Project Manager"),
    ACCOUNT_MANAGER: Symbol("Account Manager"),
    COMPLIANCE_AUDITOR: Symbol("Compliance Auditor"),
    PROJECT_ADMIN: Symbol("Project Admin"),
    MARKETING_MANAGER: Symbol("marketing"),
});

export type AuthRolesTypes = $Keys<typeof AuthRoles>;

export class AuthContext {
    OAuthAccount;
    State: AuthStatesTypes;
    role: ?AuthRolesTypes;

    constructor(OAuthAccount, State: AuthStatesTypes, role: ?AuthRolesTypes) {
        this.role = role;
        this.OAuthAccount = OAuthAccount;
        this.State = State;
    }
}

export class AuthBloc {
    _outAuthContext: BehaviorSubject = new BehaviorSubject();

    subscribeToAuthContext(observer?: PartialObserver<AuthContext>) {
        return this._outAuthContext.asObservable().subscribe(observer);
    }

    _eventController: Subject = new Subject();

    sendEvent(event: AuthEventsTypes) {
        return this._eventController.next(event)
    }

    _authService: AuthService;

    constructor() {
        this._outAuthContext.next(new AuthContext(null, AuthStates.UNINITIALIZED));
        this._authService = AuthService;
        this.initialize();
    }

    async initialize() {
        try {
            let authenticated;
            try {
                authenticated = await this._authService.initialize();

            } catch (err) {
                this._outAuthContext.next(new AuthContext(null, AuthStates.UNAUTHENTICATED));
            }
            if (!authenticated) return this._outAuthContext.next(new AuthContext(null, AuthStates.UNAUTHENTICATED));


            let permissionsAcquired;
            try {
                permissionsAcquired = await this._authService.fetchPermissions();
            } catch (err) {
                this._outAuthContext.next(new AuthContext(null, AuthStates.FORBIDDEN));
            }

            if (!permissionsAcquired) return this._outAuthContext.next(new AuthContext(null, AuthStates.FORBIDDEN));

            const role = findRole(this._authService.keycloak.tokenParsed);
            this._outAuthContext.next(new AuthContext(this._authService.keycloak.tokenParsed, AuthStates.AUTHENTICATED, role));

        } finally {
            this._eventController.subscribe(this.buildEventHandler());
        }

    }

    buildEventHandler = () => {
        return {
            next: async (event: AuthEventsTypes) => {
                switch (event) {
                    case AuthEvents.LOGIN: {
                        this._outAuthContext.next(new AuthContext(null, AuthStates.AUTHENTICATING));
                        await this._authService.login()
                            .then((err, authResult) => {
                                if (err) {
                                    this._outAuthContext.next(new AuthContext(null, AuthStates.UNAUTHENTICATED));
                                } else if (authResult && authResult.accessToken && authResult.idToken) {
                                    this._outAuthContext.next(new AuthContext(this._authService.keycloak.tokenParsed,
                                        AuthStates.AUTHENTICATED,
                                        findRole(this._authService.keycloak.tokenParsed)));
                                } else {
                                    this._outAuthContext.next(new AuthContext(null, AuthStates.UNAUTHENTICATED));
                                }
                            });
                        break;
                    }
                    case AuthEvents.LOGOUT: {
                        await this._authService.logout().then(() => {
                            history.replace('/login')
                        });
                        break;
                    }
                    default: {
                        throw new Error("Unknown auth event: " + event);
                    }
                }
            },
            error(err) {
                throw err
            },
        };
    };


    getCurrentToken(): ?string {
        return this._authService.keycloak.token;
    }

    getCurrentTenant(): ?string {
        const groupString = this._outAuthContext.getValue().OAuthAccount.groups[0];
        const tenantSubstring = groupString.split(':')[2];
        return tenantSubstring.substring(0, tenantSubstring.indexOf('/'));
    }

    dispose() {
        this._outAuthContext.complete();
        this._eventController.complete();
    }
}

function findRole(token): ?AuthRolesTypes {
    for (const role of token.realm_access.roles) {
        if (role === 'tenant_admin') return AuthRoles.ADMIN;
        if (role === 'project_admin') return AuthRoles.PROJECT_ADMIN;
        if (role === 'project_manager') return AuthRoles.PROJECT_MANAGER;
        if (role === 'account_manager') return AuthRoles.ACCOUNT_MANAGER;
        if (role === 'compliance_audiator') return AuthRoles.COMPLIANCE_AUDITOR;
        if (role === 'marketing') return AuthRoles.MARKETING_MANAGER;
    }
    /*
    const roles = userInfo.realm_access.roles.reduce((roles, role)=>{
        if(role === 'project_manager') roles.push('Project Manager');
        if(role === 'tenant_admin') roles.push('Admin');
        return roles;
    }, []);*/
}
