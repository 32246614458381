// @flow

import AssignedTask, {UpdateAssignedTaskRequest} from "../AssignedTask/AssignedTask";
import {GetAttachmentResponse} from "../Attachment/Attachment";
import moment from "moment";
import ProjectGroup from "../ProjectGroup/ProjectGroup";
import type {UpdateAddressRequest} from "../Project/Project";
import {Address} from "../Project/Project";


export default class Vendor {
    id: string;
    name:string;
    email:?string;
    tenantId: string;
    phoneNumber: string;
    address: Address;

    constructor(id: string,
                name: string,
                email: string,
                tenantId: string,
                phoneNumber: string,
                address: Address) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.tenantId = tenantId;
        this.phoneNumber = phoneNumber;
        this.address = address;
    }
}

export class CreateVendorRequest {
    name:string;
    lastName:string;
    email:?string;
    phoneNumber: string;
    address: Address;
    contactType: string;

    constructor(name: string,
                email: string,
                phoneNumber: string,
                address: Address, contactType: string) {
        this.name = name;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.address = address;
        this.contactType = contactType;
    }
}



export class UpdateVendorRequest{
    id:string
    name:?string;
    email:?string;
    phoneNumber: ?string;
    address: ?UpdateAddressRequest;
    contactType: ?string;


    constructor(id:string,name: string, email: string, phoneNumber: string, address: UpdateAddressRequest, contactType: string
        ) {
        this.id = id;
        if(name) this.name = name;
        if(email) this.email = email;
        if(phoneNumber) this.phoneNumber = phoneNumber;
        if(address) this.address = address;
        if(contactType) this.contactType = contactType;
    }
}



