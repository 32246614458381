// @flow

import Project, {
  applyProjectUpdate,
  CreateProjectRequest
} from "../../models/Project/Project";
import ProjectApi from "../../api/ProjectApi/ProjectApi";
import type { UpdateProjectRequest } from "../../models/Project/Project";
import ProjectGroupService from "../ProjectGroupService/ProjectGroupService";

class ProjectService {
  projectApi: ProjectApi;
  projectGroupService: ProjectGroupService;

  constructor(
    projectApi: ProjectApi,
    projectGroupService: ProjectGroupService
  ) {
    this.projectApi = projectApi;
    this.projectGroupService = projectGroupService;
  }

  async getAllProjects(projectStates:boolean): Promise<Array<Project>> {
    return await this.projectApi.getAll(projectStates);
  }

  async createProject(
    createProjectRequest: CreateProjectRequest
  ): Promise<Project> {
    const newProject = await this.projectApi.createProject(
      createProjectRequest
    );
    if (
      createProjectRequest.projectGroups &&
      createProjectRequest.projectGroups.length > 0
    ) {
      for (let projectGroup of createProjectRequest.projectGroups) {
        await this.projectGroupService.addProjectToGroup(
          projectGroup,
          newProject
        );
      }
    }
    return newProject;
  }

  async updateProject(
    projectToUpdate: Project,
    projectUpdateRequest: UpdateProjectRequest
  ): Promise<Project> {
    await this.projectApi.updateProject(
      projectToUpdate.id,
      projectUpdateRequest
    );

    return applyProjectUpdate(projectToUpdate, projectUpdateRequest);
  }

  async addVendorToProject(
    projectId: string,
    vendorIdToAdd: string
  ): Promise<Project> {
    return await this.projectApi.addVendorToProject(projectId, vendorIdToAdd);
  }

  async removeVendorFromProject(
    projectId: string,
    vendorToRemove: string
  ): Promise<Project> {
    return await this.projectApi.removeVendorFromProject(
      projectId,
      vendorToRemove
    );
  }

  async removeProject(projectId: string): Promise<Project> {
    return await this.projectApi.removeProject(projectId);
  }
}

export default ProjectService;
