//@Flow
import { BehaviorSubject, PartialObserver, Subject, Subscription } from "rxjs";
import Vendor, {
  CreateVendorRequest,
  UpdateVendorRequest
} from "../models/Vendor/Vendor";
import VendorService from "../service/VendorService/VendorService";
import { AuthBloc, AuthContext, AuthStates } from "./authBloc";
import React from "react";

export const VendorStates = Object.freeze({
  UNINITIALIZED: Symbol("UNINITIALIZED"),
  INITIALIZING: Symbol("INITIALIZING"),
  INITIALIZED: Symbol("INITIALIZED")
});

export type VendorStatesTypes = $Keys<typeof VendorStates>;

export const VendorEventTypesEnum = Object.freeze({
  CREATE_VENDOR: Symbol("CREATE VENDOR"),
  UPDATE_VENDOR: Symbol("UPDATE VENDOR"),
  REMOVE_VENDOR_FROM_LIST: Symbol("REMOVE_VENDOR_FROM_LIST"),
  REFRESH: Symbol("REFRESH")
});

export type VendorEventTypes = $Keys<typeof VendorEventTypesEnum>;

export class VendorEvent {
  type: VendorEventTypes;

  constructor(type: VendorEventTypes) {
    this.type = type;
  }
}

export class CreateVendorEvent extends VendorEvent {
  request: CreateVendorRequest;

  constructor(request: CreateVendorRequest) {
    super(VendorEventTypesEnum.CREATE_VENDOR);
    this.request = request;
  }
}

export class UpdateVendorEvent extends VendorEvent {
  request: UpdateVendorRequest;

  constructor(request: UpdateVendorRequest) {
    super(VendorEventTypesEnum.UPDATE_VENDOR);
    this.request = request;
  }
}

export class RemoveVendorFromListEvent extends VendorEvent {
  vendorId: string;

  constructor(vendorId: string) {
    super(VendorEventTypesEnum.REMOVE_VENDOR_FROM_LIST);
    this.vendorId = vendorId;
  }
}

export class RefreshVendorsEvent extends VendorEvent {
  constructor() {
    super(VendorEventTypesEnum.REFRESH);
  }
}

export class VendorBloc {
  _outVendorContext: BehaviorSubject = new BehaviorSubject();

  subscribeToVendorContext(observer?: PartialObserver<VendorContext>) {
    return this._outVendorContext.asObservable().subscribe(observer);
  }

  _eventController: Subject = new Subject();

  sendEvent(event: VendorEvent) {
    return this._eventController.next(event);
  }

  _vendorService: VendorService;

  _authBloc: AuthBloc;
  _authBlocSubscription: ?Subscription;

  constructor(vendorService: VendorService, authBloc: AuthBloc) {
    this._outVendorContext.next(
      new VendorContext(null, VendorStates.UNINITIALIZED)
    );
    this._vendorService = vendorService;
    this._authBloc = authBloc;
    this._authBlocSubscription = this._authBloc.subscribeToAuthContext(
      this.buildAuthContextChangeHandler()
    );
  }

  buildAuthContextChangeHandler = () => {
    return {
      next: async (authContext: AuthContext) => {
        switch (authContext.State) {
          case AuthStates.AUTHENTICATED: {
            this.initialize();
            break;
          }
          default: {
            break;
          }
        }
      },
      error(err) {
        throw err;
      }
    };
  };

  async initialize() {
    this._outVendorContext.next(
      new VendorContext(null, VendorStates.INITIALIZING)
    );
    const vendors = await this._vendorService.getAllVendors(sortVendors);
    this._outVendorContext.next(
      new VendorContext(vendors.sort(), VendorStates.INITIALIZED)
    );
    this._eventController.subscribe(this.buildEventHandler());
  }

  buildEventHandler = () => {
    return {
      next: async (event: VendorEvent) => {
        switch (event.type) {
          case VendorEventTypesEnum.REFRESH: {
            const vendors = await this._vendorService.getAllVendors();

            this._outVendorContext.next(
              vendors.sort(sortVendors),
              VendorStates.INITIALIZED
            );

            break;
          }
          case VendorEventTypesEnum.CREATE_VENDOR: {
            const newVendor = await this._vendorService.createVendor(
              (event: CreateVendorEvent).request
            );
            // const currentVendorContext = this._outVendorContext.getValue();
            // const newVendors = currentVendorContext.vendors;
            // newVendors.push(newVendor);
            // this._outVendorContext.next(
            //   new VendorContext(
            //     newVendors.sort(sortVendors),
            //     VendorStates.INITIALIZED
            //   )
            // );

            const vendors = await this._vendorService.getAllVendors(
              sortVendors
            );
            this._outVendorContext.next(
              new VendorContext(vendors.sort(), VendorStates.INITIALIZED)
            );

            break;
          }

          case VendorEventTypesEnum.UPDATE_VENDOR: {
            const newVendor = await this._vendorService.updateVendor(
              (event: UpdateVendorEvent).request
            );

            const vendors = await this._vendorService.getAllVendors(
              sortVendors
            );
            this._outVendorContext.next(
              new VendorContext(vendors.sort(), VendorStates.INITIALIZED)
            );

            break;
          }

          case VendorEventTypesEnum.REMOVE_VENDOR_FROM_LIST: {
            await this._vendorService.removeVendorFromVendorList(
              (event: RemoveVendorFromListEvent).vendorId
            );
            const vendors = await this._vendorService.getAllVendors(
              sortVendors
            );
            this._outVendorContext.next(
              new VendorContext(vendors.sort(), VendorStates.INITIALIZED)
            );
            break;
          }
        }
      },
      error(err) {
        throw err;
      }
    };
  };

  dispose() {
    this._outVendorContext.complete();
    this._eventController.complete();
    this._authBlocSubscription.unsubscribe();
  }
}

export class VendorContext {
  vendors: Array<Vendor>;
  state: VendorStatesTypes;

  constructor(vendors: Array<Vendor>, state: VendorStatesTypes) {
    this.state = state;
    this.vendors = vendors;
  }
}

const sortVendors = (vendorA, vendorB) => {
  return vendorA.name.toLowerCase().localeCompare(vendorB.name.toLowerCase());
};
