// @flow

import type {AuthBloc} from "../../blocs/authBloc";
import {apiAxios} from '../../config/AxiosConfig'
import type Attachment, {AttachmentTypes} from "../../models/Attachment/Attachment";
import {
    CreateAttachmentRequest,
    GetAttachmentResponse,
    PostAttachmentResponse
} from "../../models/Attachment/Attachment";


class AttachmentApi {

    authBloc : AuthBloc;

    constructor(authBloc: AuthBloc) {
        this.authBloc = authBloc;
    }

    async getAttachmentsForProject(projectId: string, attachmentType: AttachmentTypes,) : Promise<Array<GetAttachmentResponse>>{
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.get(`/tenants/${tenantId}/projects/${projectId}/${attachmentType}`, this.buildHeaders());

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    }

    async createAttachment (attachmentType: AttachmentTypes, createAttachmentRequest: CreateAttachmentRequest) : Promise<PostAttachmentResponse> {
        const tenantId = this.authBloc.getCurrentTenant();

        const payload = createAttachmentRequest.payload;
        delete createAttachmentRequest.payload;

        const response = await apiAxios.post(`/tenants/${tenantId}/projects/${createAttachmentRequest.projectId}/${attachmentType}`, createAttachmentRequest, this.buildHeaders());

        createAttachmentRequest.payload=payload;
        
        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    async deleteAttachment (projectId: string, attachmentType: AttachmentTypes, attachmentId: string) : Promise<void> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.delete(`/tenants/${tenantId}/projects/${projectId}/${attachmentType}/${attachmentId}`, this.buildHeaders());

        if (response.status === 204) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    buildHeaders = ()=> {
        const accessToken = this.authBloc.getCurrentToken();
        const auth = "Bearer " + accessToken;
        return {headers:{Authorization:auth}};
    }
}

export default AttachmentApi;
