// @flow

import React, { useState, useEffect } from "react";
import Flexbox from "flexbox-react";
import {
  Button,
  Intent,
  Spinner,
  Tag,
  Popover,
  Position,
  Classes,
  Card,
} from "@blueprintjs/core";
import styles from "./ProjectMilestoneManager.module.css";
import Project, { ProjectStatusesTypes } from "../../models/Project/Project";
import moment from "moment";
import { ProjectStatuses } from "../../models/Project/Project";
import { AuthRoles } from "../../blocs/authBloc";
import { DatePicker3 } from "@blueprintjs/datetime2";
import { DatePicker } from "@blueprintjs/datetime";

type Props = {
  project: ?Project,
  onProjectMilestoneUpdate: (
    project: Project,
    newProjectStatus: ProjectStatusesTypes
  ) => any,
};

const milestones = {
  milestoneProjectCreated: {
    text: "Created",
    status: ProjectStatuses.CREATED,
    button: false,
  },
  milestoneHomeownerAppStarted: {
    text: "Homeowner App Started",
    status: ProjectStatuses.APP_STARTED,
    button: false,
  },
  milestoneHomeownerAppSubmitted: {
    text: "Homeowner App Submitted",
    status: ProjectStatuses.APP_SUBMITTED,
    button: false,
  },

  milestoneHomeownerAppApproved: {
    text: "Homeowner App Approved",
    status: ProjectStatuses.APP_APPROVED,
    button: false,
  },
  milestoneHomeownerAppDenied: {
    text: "Homeowner App Denied",
    status: ProjectStatuses.APP_DENIED,
    button: false,
  },

  milestoneSurveyCompleted: {
    text: "Survey Completed",
    status: ProjectStatuses.SURVEY_COMPLETED,
  },
  milestoneErSubmitted: {
    text: "ER Submitted",
    status: ProjectStatuses.ER_SUBMITTED,
  },
  milestoneErReleased: {
    text: "ER Released",
    status: ProjectStatuses.ER_RELEASED,
  },
  milestoneRiskAssessmentSubmitted: {
    text: "Lead Risk Assessment Submitted",
    status: ProjectStatuses.RISK_ASSESSMENT_SUBMITTED,
  },
  milestoneVendorsSelected: {
    text: "Vendor(s) Selected",
    status: ProjectStatuses.VENDORS_SELECTED,
  },
  milestoneWorkStarted: {
    text: "Work Started",
    status: ProjectStatuses.WORK_STARTED,
  },
  milestoneWorkCompleted: {
    text: "Work Completed",
    status: ProjectStatuses.WORK_COMPLETED,
  },
  milestoneInvoicesSubmitted: {
    text: "Invoice(s) Submitted",
    status: ProjectStatuses.INVOICES_SUBMITTED,
  },
  milestoneInvoicesPaid: {
    text: "Invoice(s) Paid",
    status: ProjectStatuses.INVOICES_PAID,
  },
  milestoneReimbursementSubmitted: {
    text: "Reimbursement Submitted",
    status: ProjectStatuses.REIMBURSEMENT_SUBMITTED,
  },
  milestoneReimbursementReceived: {
    text: "Reimbursement Recieved",
    status: ProjectStatuses.REIMBURSEMENT_RECEIVED,
  },
  milestoneInvoicesSubmitted: {
    text: "Invoice(s) Submitted",
    status: ProjectStatuses.INVOICES_SUBMITTED,
  },
  milestoneInvoicesPaid: {
    text: "Invoice(s) Paid",
    status: ProjectStatuses.INVOICES_PAID,
  },
  milestoneReimbursementSubmitted: {
    text: "Reimbursement Submitted",
    status: ProjectStatuses.REIMBURSEMENT_SUBMITTED,
  },
  milestoneReimbursementReceived: {
    text: "Reimbursement Received",
    status: ProjectStatuses.REIMBURSEMENT_RECEIVED,
  },
  milestoneProjectCompleted: {
    text: "Project Completed",
    status: ProjectStatuses.COMPLETED,
  },
  milestoneHFHI: {
    text: "Report to Habitat for Humanity International",
    status: ProjectStatuses.HFHI,
    input: false,
  },
};

export default function ProjectMilestoneManager(props: Props) {
  const { project, onProjectMilestoneUpdate, onCloseProjectButtonClick } =
    props;

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const [currentMileStone, setCurrentMilestone] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const [value, onChange] = useState<Value>(new Date());
  const [selectedDate, setSelectedDate] = useState(() => {
    let initialDates = {};

    for (let milestone of Object.keys(milestones)) {
      let initialDate = null;

      if (milestone === "milestoneProjectCreated") {
        if (project?.created_at) {
          initialDate = moment(project.created_at).toDate();
        }
      } else {
        let timestamp = project?.[milestone];
        if (timestamp) {
          initialDate = moment(timestamp).toDate();
        }
      }

      initialDates[milestone] = initialDate;
    }

    return initialDates;
  });

  let milestoneList = [<Spinner />];

  if (project) {
    milestoneList = [];
    for (let milestone of Object.keys(milestones)) {
      // let m = moment();
      // if (milestone === "milestoneProjectCreated")
      //   m = new moment(project.created_at);
      // else {
      //   let timestamp = project[milestone];
      //   if (timestamp) {
      //     m = new moment(timestamp);
      //   }
      // }

      milestoneList.push(
        <Flexbox padding={"10px"} width={"100%"}>
          <Flexbox flex={2}>
            <h6>{milestones[milestone].text}:</h6>
          </Flexbox>
          <Flexbox flex={3}>
            {selectedDate[milestone] ? (
              <Tag multiline intent={Intent.PRIMARY}>
                {/* {m.format("LLLL")} */}
                {moment(selectedDate[milestone]).format("LLLL")}
              </Tag>
            ) : (
              <Tag minimal={true}>-</Tag>
            )}
          </Flexbox>
          <Flexbox alignItems={"center"} justifyContent={"center"} flex={1}>
            {/* {milestones[milestone].button !== false && ( */}
            {milestones[milestone].status !== "CREATED" && (
              <Flexbox>
                <Button
                  large
                  icon={!selectedDate[milestone] ? "endorsed" : "edit"}
                  onClick={() => {
                    setCurrentMilestone(milestone);
                    setConfirmOpen(true);
                  }}
                  disabled={
                    AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole
                  }
                />
              </Flexbox>
            )}
            {/* )} */}
            <Popover
              position={Position.RIGHT}
              isOpen={isConfirmOpen && currentMileStone === milestone}
              onClose={() => {
                setConfirmOpen(false);
              }}
              content={
                <Flexbox>
                  <Card className="example-card">
                    <DatePicker
                      timePrecision="minute"
                      timePickerProps={{ useAmPm: true }}
                      // footerElement={this.state.showFooterElement ? exampleFooterElement : undefined}
                      onChange={(date) => {
                        // onProjectMilestoneUpdate(
                        //   project,
                        //   milestones[currentMileStone].status,
                        //   props.assignedTasks[props.assignedTasks?.length - 1]
                        //     ?.taskList?.id
                        // );
                        // setConfirmOpen(false);
                        setSelectedDate((prevDates) => ({
                          ...prevDates,
                          [milestone]: date,
                        }));
                      }}
                      value={selectedDate[milestone]}
                    />
                    <Button
                      onClick={() => {
                        console.log("Project Object", project);
                        onProjectMilestoneUpdate(
                          project,
                          milestones[currentMileStone].status,
                          selectedDate[milestone],
                          props.assignedTasks[props.assignedTasks?.length - 1]
                            ?.taskList?.id
                        );
                        setConfirmOpen(false);
                      }}
                      className={styles.save_btn}
                      text="Save"
                    />
                  </Card>
                  {/* <Button
                    icon={"cross"}
                    text={"Cancel"}
                    minimal
                    icon={"cross"}
                    text={"Cancel"}
                    minimal
                    onClick={() => {
                      setConfirmOpen(false);
                    }}
                  />
                  <Button
                    icon={"confirm"}
                    text={"Confirm"}
                    intent={Intent.WARNING}
                    onClick={() => {
                      onProjectMilestoneUpdate(
                        project,
                        milestones[currentMileStone].status,
                        props.assignedTasks[props.assignedTasks?.length - 1]
                          ?.taskList?.id
                      );
                      setConfirmOpen(false);
                    }}
                  /> */}
                </Flexbox>
              }
            >
              <></>
            </Popover>
          </Flexbox>
        </Flexbox>
      );
    }
  }

  return (
    <Flexbox
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      padding={"20px"}
      flexDirection={"column"}
      className={styles.teamViewContainer}
    >
      <h5>Milestones</h5>
      <Flexbox
        maxWidth={"1000px"}
        justifyContent={"flex-start"}
        flexDirection={"column"}
        width={"100%"}
        paddingBottom={"25px"}
      >
        {milestoneList}
        <Flexbox>
          <Flexbox padding={"2px"}></Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}
