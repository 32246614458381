// @flow

import ProjectGroup, {CreateProjectGroupRequest, UpdateProjectGroupRequest} from "../../models/ProjectGroup/ProjectGroup";
import ProjectGroupApi from "../../api/ProjectGroupApi/ProjectGroupApi";
import Project from "../../models/Project/Project";

class ProjectGroupService {

    projectGroupApi: ProjectGroupApi;

    constructor(projectGroupApi: ProjectGroupApi) {
        this.projectGroupApi = projectGroupApi;
    }

    async getAllProjectGroups() : Promise<Array<ProjectGroup>>{
        return await this.projectGroupApi.getAll();
    }

    async createProjectGroup(createProjectGroupRequest: CreateProjectGroupRequest) : Promise<ProjectGroup>{
        return await this.projectGroupApi.createProjectGroup(createProjectGroupRequest);
    }

    async addProjectToGroup(projectGroupToAddTo: ProjectGroup, projectToAdd: Project) : Promise<ProjectGroup>{
        return await this.projectGroupApi.addProjectToGroup(projectGroupToAddTo, projectToAdd);
    }

    async updateProjectGroup(updateProjectGroupRequest: UpdateProjectGroupRequest) : Promise<ProjectGroup>{
        return await this.projectGroupApi.updateProjectGroup(updateProjectGroupRequest);
    }

    async removeProjectGroup(projectGroupId: string) : Promise<ProjectGroup>{
        return await this.projectGroupApi.removeProjectGroup(projectGroupId);
    }
    async exportProjectGroup(projectGroupId: string) : Promise<ProjectGroup>{
        return await this.projectGroupApi.exportProjectGroup(projectGroupId);
    }
    async editProjectGroup(projectGroupId: string) : Promise<ProjectGroup>{
        return await this.projectGroupApi.editProjectGroup(projectGroupId);
    }

}

export default ProjectGroupService;
