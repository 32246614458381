// @flow

import Vendor, {
  CreateVendorRequest,
  UpdateVendorRequest
} from "../../models/Vendor/Vendor";
import VendorApi from "../../api/VendorApi/VendorApi";

class VendorService {
  vendorApi: VendorApi;

  constructor(vendorApi: VendorApi) {
    this.vendorApi = vendorApi;
  }

  async getAllVendors(): Promise<Array<Vendor>> {
    return await this.vendorApi.getAll();
  }

  async createVendor(
    createVendorRequest: CreateVendorRequest
  ): Promise<Vendor> {
    return await this.vendorApi.createVendor(createVendorRequest);
  }

  async updateVendor(
    updateVendorRequest: UpdateVendorRequest
  ): Promise<Vendor> {
    return await this.vendorApi.updateVendor(updateVendorRequest);
  }

  async removeVendorFromVendorList(vendorId: string): Promise<Vendor> {
    return await this.vendorApi.removeVendorFromList(vendorId);
  }
}

export default VendorService;
