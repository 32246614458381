// @flow

import React, { useState, useEffect } from "react";
import { Table, SelectionModes, Column } from "@blueprintjs/table";
import Project from "../../models/Project/Project";
import { TextSortableColumn } from "../../components/Table/TextSortableColumn/TextSortableColumn";
import { Utils } from "@blueprintjs/table/lib/esnext/common/utils";
import User from "../../models/User/User";
import {
  Icon,
  Menu,
  MenuItem,
  Intent,
  Classes,
  InputGroup,
  H5,
  Spinner,
} from "@blueprintjs/core";
import Flexbox from "flexbox-react";

import Pagination from "../Pagination/Pagination";

import { GetPaginatedData, handleSearch } from "../../config/Utils";
import TextSelect from "../TextSelect/TextSelect";
import NumberOfRowSlect from "../NumberOfRowSelect/NumberOfRowSelect";
import { GlobalConstanst } from "../../config/Constant";
import ProjectGroup from "../../models/ProjectGroup/ProjectGroup";
import { Responsive, WidthProvider } from "react-grid-layout";
import { AuthContext, AuthRoles, AuthStates } from "../../blocs/authBloc";

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
  projects: Array<Project>,
  projectManagers: Array<User>,
  onProjectDetailsButtonClick: (projectId: string) => any,
  onEditProjectButtonClick: (projectId: string) => any,
  onDeleteProjectButtonClick: (projectId: string) => any,
  projectGroups: ?Array<ProjectGroup>,
  currentUserRole: String
};

export default function ProjectTable(props: Props) {
  const { projectManagers, projectGroups, currentUserRole } = props;

  // let projects = props.projects;
  const originalProjects = props.projects;

  const firstProjectsList = GetPaginatedData(originalProjects, 1);
  const [projects, setProjects] = useState(firstProjectsList);
  const [searchedProj, setSearchedProj] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page_size, setPageSize] = useState(50);
  const [currentPage, setPage] = useState(1);

  // setProjects(firstProjectsList);
  const projectManagersToIdMap = projectManagers.reduce(
    (map, projectManager) => {
      map.set(projectManager.id, projectManager);
      return map;
    },
    new Map()
  );

  const [columns, setColumns] = useState([
    new TextSortableColumn("Controls", 0, "controls", () => {
      return 0;
    }),
    new TextSortableColumn("Homeowner", 1, "home.homeowner.lastName"),
    new TextSortableColumn(
      "Home Address",
      2,
      "home.address.streetAddress",
      undefined,
      { truncate: false, wrapText: true }
    ),
    new TextSortableColumn("Status", 3, "status", undefined, {
      truncate: false,
      wrapText: true,
    }),
    new TextSortableColumn("Assigned Project Manager", 4, "assignedUserId"),
    new TextSortableColumn("Group(s)", 5, "projectGroups", undefined, {
      truncate: false,
      wrapText: true,
    }),
    new TextSortableColumn("Project Status", 6, "projectState", undefined, {
      truncate: false,
      wrapText: false,
    }),
  ]);

  const [sortedIndexMap, setSortedIndexMap] = useState([]);

  const handleProjectManagerFilter = (value) => {
    if (value === "select") {
      return originalProjects;
    }

    let searchResult = originalProjects.filter((project) => {
      let res = project.assignedUserId === value;

      return res;
    });
    return searchResult;
  };

  const handleProjectGroupFilter = (value) => {
    if (value === "select") {
      return originalProjects;
    }

    let searchResult = originalProjects.filter((project) => {
      let groupIds = project.projectGroups.map((group, index) => {
        return group.id;
      });

      let res = groupIds.includes(value);

      return res;
    });
    return searchResult;
  };

  const homeownerNameRenderer = (rowIndex: number) => {
    if (projects[rowIndex]) {
      const homeowner = projects[rowIndex].home.homeowner;
      return `${homeowner.lastName}, ${homeowner.firstName}`;
    } else {
      return "Not Found";
    }
  };

  const addressRenderer = (rowIndex: number) => {
    if (projects[rowIndex]) {
      const address = projects[rowIndex].home.address;
      return `${address.streetAddress}${address.streetAddressTwo ? `, ${address.streetAddressTwo}` : ""
        }\n${address.city}, ${address.state}\n${address.zip}`;
    } else {
      return "Not Found";
    }
  };

  const statusRenderer = (rowIndex: number) => {
    if (projects[rowIndex]) {
      return projects[rowIndex].status;
    } else {
      return "Not Found";
    }
  };

  const assignedUserRenderer = (rowIndex: number) => {
    if (projects[rowIndex]) {
      const user = projectManagersToIdMap.get(
        projects[rowIndex].assignedUserId
      );
      return user ? `${user.firstName} ${user.lastName}` : "Unassigned";
    } else {
      return "Not Found";
    }
  };
  const roleViseMenu = (rowIndex) => {
    if (currentUserRole !== AuthRoles.COMPLIANCE_AUDITOR && currentUserRole !== AuthRoles.MARKETING_MANAGER) {
      return (<>
        <MenuItem
          text="Edit"
          icon={"edit"}
          intent={Intent.PRIMARY}
          onClick={() => {
            props.onEditProjectButtonClick(projects[rowIndex].id, projects[rowIndex].task_list_name_id);
          }}
        />
        <MenuItem
          text="Delete"
          icon={"trash"}
          intent={Intent.DANGER}
          onClick={() => {
            props.onDeleteProjectButtonClick(projects[rowIndex].id);
          }}
        />
      </>
      )
    }
  }
  const controlsRenderer = (rowIndex: number) => {
    return (
      <Menu className={Classes.ELEVATION_2}>
        <MenuItem
          text="Details"
          icon={"grid-view"}
          intent={Intent.PRIMARY}
          onClick={() => {
            props.onProjectDetailsButtonClick(projects[rowIndex].id);
          }}
        />
        {roleViseMenu(rowIndex)}
        {/* <MenuItem
          text="Edit"
          icon={"edit"}
          intent={Intent.PRIMARY}
          onClick={() => {
            props.onEditProjectButtonClick(projects[rowIndex].id);
          }}
        />
        <MenuItem
          text="Delete"
          icon={"trash"}
          intent={Intent.DANGER}
          onClick={() => {
            props.onDeleteProjectButtonClick(projects[rowIndex].id);
          }}
        /> */}
      </Menu>
    );
  };

  const projectGroupRenderer = (rowIndex: number) => {
    if (projects[rowIndex]) {
      if (!projects[rowIndex].projectGroups) return "";
      else
        return (
          <ul>
            {projects[rowIndex].projectGroups.map((group) => {
              return <li>{group.name}</li>;
            })}
          </ul>
        );
    } else {
      return "PG Not found";
    }
  };

  const projectStatusRenderer = (rowIndex: number) => {
    if (projects[rowIndex]) {
      return projects[rowIndex].projectState;
    } else {
      return "Not Found";
    }
  };

  const cellRenderers = {
    "home.homeowner.lastName": homeownerNameRenderer,
    "home.address.streetAddress": addressRenderer,
    status: statusRenderer,
    assignedUserId: assignedUserRenderer,
    controls: controlsRenderer,
    projectGroups: projectGroupRenderer,
    projectState: projectStatusRenderer,
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    const sortedRowIndex = sortedIndexMap[rowIndex];
    if (sortedRowIndex != null) {
      rowIndex = sortedRowIndex;
    }
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };

  const sortColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(projects.length, (i: number) => i);
    sortedIndexMap.sort((a: number, b: number) => {
      let fieldA;
      let fieldB;

      switch (columns[columnIndex].modelField) {
        case "home.address.streetAddress": {
          fieldA = projects[a].home.address.streetAddress;
          fieldB = projects[b].home.address.streetAddress;
          break;
        }
        case "home.homeowner.lastName": {
          fieldA = projects[a].home.homeowner.lastName;
          fieldB = projects[b].home.homeowner.lastName;
          break;
        }
        default: {
          fieldA = projects[a][columns[columnIndex].modelField];
          fieldB = projects[b][columns[columnIndex].modelField];
          break;
        }
      }

      return comparator(fieldA, fieldB, a, b);
    });
    setSortedIndexMap(sortedIndexMap);
  };

  const sortUserColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(projects.length, (i: number) => i);

    sortedIndexMap.sort((a: number, b: number) => {
      const managerA = projectManagersToIdMap.get(
        projects[a][columns[columnIndex].modelField]
      );
      const managerB = projectManagersToIdMap.get(
        projects[b][columns[columnIndex].modelField]
      );

      return comparator(managerA.lastName, managerB.lastName, a, b);
    });
    setSortedIndexMap(sortedIndexMap);
  };

  const renderedColumns = columns.map((column) => {
    const field = column.modelField;
    switch (field) {
      case "assignedUserId": {
        return column.getColumn(getCellData, sortUserColumn);
      }
      default: {
        return column.getColumn(getCellData, sortColumn);
      }
    }
  });
  const setCurrentProjects = (initialPage = false) => {

    const filteredProjects = GetPaginatedData(
      searching ? searchedProj : originalProjects,
      // searching ? 1 : initialPage ? 1 : currentPage,
      initialPage ? 1 : currentPage,
      page_size
    );
    setProjects(filteredProjects);
  };

  useEffect(() => {
    setCurrentProjects(true);
    return () => {
      // subscription.unsubscribe();
    };
  }, [page_size]);

  useEffect(() => {
    setCurrentProjects();
    return () => {
      // subscription.unsubscribe();
    };
  }, [currentPage]);
  useEffect(() => {
    setCurrentProjects(true);
    return () => {
      // subscription.unsubscribe();
    };
  }, [searchedProj]);

  const generateLayout = (buttons: Array<any>) => {
    return {
      lg: buttons.map((button, index) => {
        return {
          x: index % 5,
          y: Math.floor(index / 5),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      md: buttons.map((button, index) => {
        return {
          x: index % 4,
          y: Math.floor(index / 4),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      sm: buttons.map((button, index) => {
        return {
          x: index % 2,
          y: Math.floor(index / 2),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      xs: buttons.map((button, index) => {
        return {
          x: index % 1,
          y: Math.floor(index / 1),
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
      xss: buttons.map((button, index) => {
        return {
          x: 0,
          y: index,
          w: 1,
          h: 1,
          i: index.toString(),
          static: true,
        };
      }),
    };
  };

  const createButton = (
    index: number,
    icons: Array<FontAwesomeIcon>,
    label: string,
    onClick?: () => void,
    disabled?: boolean
  ) => {
    let isInteractive = true;
    if (onClick === undefined || disabled) isInteractive = false;

    return <div key={index}>{icons}</div>;
  };

  const searchFilterButtons = [
    createButton(
      0,
      [
        <Flexbox>
          {projectGroups && (
            <Flexbox
              flex={1}
              flexDirection={"column"}
            >
              <Flexbox flex={1}>
                <b>
                  <label style={{ fontSize: "14px" }} htmlFor={"group"}>
                    {"Groups"}
                  </label>
                </b>
              </Flexbox>
              <Flexbox flex={1}>
                <div class="bp3-select .modifier">
                  <select
                    onChange={(e) => {
                      setSearching(true);
                      const filteredProjects = handleProjectGroupFilter(
                        e.target.value
                      );
                      // setProjects(filteredProjects);
                      setSearchedProj(filteredProjects);
                      // setCurrentProjects(true);
                    }}
                  >
                    <option value="select" selected>
                      -Select-
                    </option>

                    {projectGroups.map((group, index) => {
                      return <option value={group.id}>{group.name}</option>;
                    })}
                  </select>
                </div>
              </Flexbox>
            </Flexbox>
          )}
        </Flexbox>,
      ],
      "",
      () => {

      }
    ),

    createButton(
      1,
      [
        <Flexbox>
          {projectManagers && (
            <Flexbox
              flex={1}
              flexDirection={"column"}
            >
              <Flexbox flex={1}>
                <b>
                  <label style={{ fontSize: "14px" }} htmlFor={"project manager"}>
                    {"Project Manager"}
                  </label>
                </b>
              </Flexbox>
              <Flexbox flex={1}>
                <div class="bp3-select .modifier">
                  <select
                    onChange={(e) => {
                      setSearching(true);
                      const filteredProjects = handleProjectManagerFilter(
                        e.target.value
                      );

                      // setProjects(filteredProjects);
                      setSearchedProj(filteredProjects);
                      // setCurrentProjects(true);
                    }}
                  >
                    <option value="select" selected>
                      -Select-
                    </option>

                    {projectManagers.map((manager, index) => {
                      return (
                        <option value={manager.id}>
                          {manager.firstName} {manager.lastName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Flexbox>
            </Flexbox>
          )}
        </Flexbox>,
      ],
      "",
      () => {

      }
    ),

    createButton(
      2,
      [
        <Flexbox
          flex={1}
          flexDirection={"column"}
        >
          <Flexbox flex={1}>
            <b>
              <label style={{ fontSize: "14px" }} htmlFor={"search"}>
                {"Search"}
              </label>
            </b>
          </Flexbox>
          <Flexbox flex={1}> <InputGroup
            placeholder="Search..."
            leftIcon={<Icon icon="search" intent={Intent.PRIMARY} />}
            onChange={(e) => {
              setSearching(e.target.value === "" ? false : true);
              const serachedProj1 = handleSearch(
                e,
                // firstProjectsList,
                originalProjects
              );
              setSearchedProj(serachedProj1);
              // setCurrentProjects(true);
            }}
          /></Flexbox>

        </Flexbox>
      ],
      "",
      () => {

      }
    ),
  ];

  let searchFilterList = <Spinner />;
  const layouts = generateLayout(searchFilterButtons);

  if (projects && projectManagers && projectGroups) {
    searchFilterList = (
      <ResponsiveGridLayout
        layouts={layouts}
        rowHeight={80}
        margin={[10, 10]}
        breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
        cols={{ lg: 5, md: 4, sm: 4, xs: 1, xxs: 1 }}
      >
        {searchFilterButtons}
      </ResponsiveGridLayout>
    );
  }

  return (
    <>
      {searchFilterList}

      {projects.length != 0 ?
        <Table
          defaultRowHeight={120}
          selectionModes={SelectionModes.COLUMNS_AND_CELLS}
          numRows={projects.length}
        >
          {renderedColumns}
        </Table>
        : <div
          className={`bp3-ui-text bp3-text-disabled`}
        >
          Projects Not Found
        </div>}

      <Flexbox justifyContent={"center"} style={{ marginTop: "20px" }}>
        <Pagination
          initialPage={1}
          size={page_size}
          onSizeChange={(size) => {
            setPageSize(size);
          }}
          isSearching={searching}
          total={searching ? searchedProj.length : originalProjects.length}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </Flexbox>
    </>
  );
}
